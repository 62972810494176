// src/Sidebar/ReportPanel.js
import React, { useState, useEffect } from 'react';
import { useTheme } from '../ThemeContext';
import { usePlan } from '../contexts/PlanContext';
import { useAuth } from '../contexts/AuthContext';
import {
  MdDescription,
  MdExpandMore,
  MdExpandLess,
  MdDelete,
  MdClose,
  MdDownload,
  MdEmojiEvents,
  MdSentimentVerySatisfied,
} from 'react-icons/md';
import useProjection from './ProjectionPanel/useProjection';
import useEntrySummary from './Summary/useEntrySummary';

import {
  getReports,
  generateReport,
  getReportById,
  deleteReport,
} from '../Sidebar/formats/apiService';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { generatePDF } from '../Dashboard/ReportPDF';

const formatChange = (value) => {
  if (value > 0) {
    return `↑ ${value.toFixed(1)}`;
  } else if (value < 0) {
    return `↓ ${Math.abs(value).toFixed(1)}`;
  }
  return `${value.toFixed(1)}`;
};

const formatDateDifference = (dateString) => {
  if (!dateString) return '';

  const now = new Date();
  const createdDate = new Date(dateString);

  const isSameDay =
    now.getFullYear() === createdDate.getFullYear() &&
    now.getMonth() === createdDate.getMonth() &&
    now.getDate() === createdDate.getDate();

  if (isSameDay) {
    return 'Hoy';
  }

  const diffInMs = now - createdDate;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (diffInDays === 1) {
    return 'Hace 1 día';
  } else if (diffInDays > 1) {
    return `Hace ${diffInDays} días`;
  }

  return createdDate.toLocaleDateString('es-ES', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

const ReportPanel = () => {
  const { theme } = useTheme();
  const { planId, healthMetrics, planData, entries } = usePlan();
  const { user } = useAuth();

  const projection = useProjection();
  const entrySummary = useEntrySummary();
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const entriesCount = entries.length;

  const {
    finalWeight,
    totalWeightChange,
    finalBmi,
    finalBodyFatPercentage,
    dailyRecommendedCalories,
  } = projection;
  const { foodStats, macroDistribution, workoutStats } = entrySummary;

  const toggleReports = () => setIsReportsOpen((prev) => !prev);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const allReports = await getReports();
        setReports(allReports);
      } catch (error) {
        console.error('Error cargando reportes:', error);
      }
    };
    fetchReports();
  }, []);

  // Definir inicio y fin del día
  const today = new Date();
  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  const reportsToday = reports.filter((r) => {
    const created = new Date(r.createdAt);
    return created >= startOfDay && created <= endOfDay;
  });
  const reportsTodayCount = reportsToday.length;

  const handleGenerateReport = async () => {
    if (!planId) {
      console.error('No se puede generar el reporte: planId es nulo o indefinido.');
      return;
    }

    if (user?.plan?.toLowerCase() !== 'plus' && entriesCount < 10) {
      alert(
        `Debes tener al menos 10 registros en el plan para generar un reporte. Te faltan ${10 - entriesCount} registros.`
      );
      return;
    }

    if (user?.plan?.toLowerCase() !== 'plus' && reportsTodayCount >= 1) {
      alert('Ya has generado un reporte hoy. Por favor, espera hasta mañana para generar otro.');
      return;
    } else if (user?.plan?.toLowerCase() === 'plus' && reportsTodayCount >= 2) {
      alert('Ya has generado 2 reportes hoy. Por favor, espera hasta mañana para generar otro reporte.');
      return;
    }

    const prompt = `
**Informe de Salud Personalizado**

*Metas al Finalizar el Plan:*
- **Peso Final:** ${finalWeight.toFixed(1)} kg
- **Cambio de Peso Total:** ${formatChange(totalWeightChange)} kg
- **IMC Final:** ${finalBmi.toFixed(1)}
- **% Grasa Corporal:** ${finalBodyFatPercentage ? finalBodyFatPercentage.toFixed(1) + '%' : 'N/A'}
- **Calorías Diarias Recomendadas:** ${dailyRecommendedCalories.toFixed(0)} kcal/día

*Estadísticas de Alimentación:*
- **Calorías Totales Consumidas:** ${foodStats.totals.calorias.toFixed(0)} kcal
- **Promedio Diario:** ${foodStats.daily.calorias} kcal/día
- **Distribución Macros:** P: ${macroDistribution.pPercent.toFixed(1)}% / C: ${macroDistribution.cPercent.toFixed(1)}% / G: ${macroDistribution.gPercent.toFixed(1)}%

*Estadísticas de Ejercicio:*
- **Calorías Totales Quemadas:** ${workoutStats.totalCaloriasQuemadas.toFixed(0)} kcal
- **Promedio Diario:** ${
      workoutStats.uniqueDays > 0
        ? `${workoutStats.daily.caloriasQuemadas} kcal/día`
        : 'N/A'
    }
- **Minutos Totales:** ${workoutStats.totalDuracion.toFixed(1)} min
- **Promedio Diario:** ${
      workoutStats.uniqueDays > 0 ? `${workoutStats.daily.duracion} min/día` : 'N/A'
    }

> **Nota:** Los valores marcados como "Final" son metas proyectadas al concluir el plan.
    `.trim();

    try {
      setLoadingReport(true);
      const response = await generateReport(planId, prompt);
      setReports((prevReports) => [...prevReports, response.report]);
      setSelectedReport(response.report);
      setIsReportsOpen(true);
    } catch (error) {
      console.error('Error al generar el reporte:', error);
      alert(error.message);
    } finally {
      setLoadingReport(false);
    }
  };

  const handleSelectReport = async (reportId) => {
    try {
      const r = await getReportById(reportId);
      setSelectedReport(r);
    } catch (error) {
      console.error('Error al cargar el reporte:', error);
    }
  };

  const handleDeleteReport = async (reportId) => {
    if (!window.confirm('¿Estás seguro de que quieres eliminar este reporte?')) {
      return;
    }
    try {
      await deleteReport(reportId);
      setReports((prev) => prev.filter((r) => r._id !== reportId));
      if (selectedReport && selectedReport._id === reportId) {
        setSelectedReport(null);
      }
    } catch (error) {
      console.error('Error al eliminar el reporte:', error);
    }
  };

  const handleCloseReport = () => {
    setSelectedReport(null);
  };

  const handleDownloadPDF = async (report) => {
    try {
      const doc = await generatePDF(user, healthMetrics, planData, report);
      doc.save(`${report.title || 'Reporte'}_${new Date(report.createdAt).toLocaleDateString()}.pdf`);
    } catch (error) {
      console.error('Error al generar el PDF:', error);
    }
  };

  const isDark = theme === 'dark';

  // Clases dinámicas inspiradas en shadcn UI
  const mainContainerClasses = `
    container mx-auto px-4 py-6 transition-colors duration-300 ease-in-out
    ${isDark ? 'bg-gray-900' : 'bg-gray-50'}
  `;
  const contentWrapperClasses = `w-full ${isDark ? 'text-gray-200' : 'text-gray-800'}`;
  const headingColorClasses = isDark ? 'text-white' : 'text-gray-800';
  const subTextColorClasses = isDark ? 'text-gray-300' : 'text-gray-700';

  const cardClasses = `
    rounded-xl border p-6 mb-6 transition-transform hover:scale-105
    ${isDark ? 'bg-gray-800 border-gray-700 text-gray-100' : 'bg-white border-gray-200 text-gray-800'}
  `;
  const sectionHeaderClasses = "flex items-center justify-between border-b pb-2 mb-4 cursor-pointer";
  const titleClasses = `flex items-center gap-2 font-semibold text-xl ${headingColorClasses}`;
  const primaryButtonClasses = `
    inline-flex items-center gap-2 rounded-md px-4 py-2 text-sm font-medium
    bg-green-600 text-white hover:bg-green-700 focus:ring focus:ring-green-500
    disabled:opacity-50 disabled:cursor-not-allowed transition-colors
  `;
  const toggleIconClasses = "h-6 w-6 text-gray-500 transition-transform";

  const lastReport = reports.length
    ? [...reports].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
    : null;
  const lastReportMessage = lastReport ? formatDateDifference(lastReport.createdAt) : '';
  const motivationalText = lastReportMessage === 'Hoy'
    ? '¡Excelente trabajo! Cada reporte es un paso hacia tus metas.'
    : 'Cada reporte es una oportunidad para ver tu progreso. ¡Sigue adelante!';

  let infoMessage = '';
  if (user) {
    if (user?.plan?.toLowerCase() === 'plus') {
      if (reportsTodayCount === 2) {
        infoMessage = (
          <div className="flex items-center justify-center gap-2">
            <MdSentimentVerySatisfied size={18} className="text-green-400" />
            <span>[Usuarios Plus] ¡Ya generaste 2 reportes hoy! Vuelve mañana para más.</span>
          </div>
        );
      } else if (reportsTodayCount === 1) {
        infoMessage = (
          <div className="flex items-center justify-center gap-2">
            <MdEmojiEvents size={18} className="text-green-400" />
            <span>[Usuarios Plus] Has generado 1 reporte hoy. ¡Puedes generar uno más!</span>
          </div>
        );
      } else {
        infoMessage = (
          <div className="flex items-center justify-center gap-2">
            <MdEmojiEvents size={18} className="text-green-400" />
            <span>[Usuarios Plus] ¡Listo para generar tu reporte de hoy!</span>
          </div>
        );
      }
    } else {
      if (reportsTodayCount >= 1) {
        infoMessage = (
          <div className="flex items-center justify-center gap-2">
            <MdSentimentVerySatisfied size={18} className="text-green-400" />
            <span>[Usuarios Free] ¡Ya generaste un reporte hoy! Disfruta de tus logros y vuelve mañana para más.</span>
          </div>
        );
      } else if (entriesCount < 10) {
        infoMessage = (
          <div className="flex items-center justify-center gap-2">
            <MdEmojiEvents size={18} className="text-yellow-500" />
            <span>[Usuarios Free] Agrega {10 - entriesCount} registros más para desbloquear tu reporte. ¡Tú puedes!</span>
          </div>
        );
      } else {
        infoMessage = (
          <div className="flex items-center justify-center gap-2">
            <MdEmojiEvents size={18} className="text-green-400" />
            <span>[Usuarios Free] ¡Listo para generar tu reporte de hoy!</span>
          </div>
        );
      }
    }
  }

  return (
    <div className={mainContainerClasses}>
      <div className={contentWrapperClasses}>
        {/* Encabezado */}
        <div className={sectionHeaderClasses} onClick={toggleReports}>
          <div className={titleClasses}>
            <MdDescription className={`text-2xl ${isDark ? 'text-green-400' : 'text-green-600'}`} />
            <span>Informe de Salud</span>
          </div>
          {isReportsOpen ? (
            <MdExpandLess className={toggleIconClasses} />
          ) : (
            <MdExpandMore className={toggleIconClasses} />
          )}
        </div>

        {/* Subtítulo y mensaje motivador */}
        <div className="mb-6">
          <p className={`text-sm ${subTextColorClasses}`}>
            Visualiza, genera y gestiona tus informes de salud de forma sencilla y profesional.
          </p>
          {!isReportsOpen && lastReport && (
            <p className="text-xs mt-1 italic">
              Último reporte: <strong>{lastReportMessage}</strong>. {motivationalText}
            </p>
          )}
        </div>

        {/* Mensaje interactivo */}
        {infoMessage && (
          <div className={`mb-4 p-3 rounded-md text-center text-sm shadow-md transition-all duration-300 ${isDark ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}`}>
            {infoMessage}
          </div>
        )}

        {/* Sección expandible */}
        <div
          id="reports-section"
          className={`overflow-hidden transition-all duration-500 ease-in-out ${isReportsOpen ? 'max-h-[5000px] opacity-100' : 'max-h-0 opacity-0'}`}
        >
          <div className={cardClasses}>
            <div className="flex justify-center mb-6">
              <button
                className={primaryButtonClasses}
                onClick={handleGenerateReport}
                aria-label="Generar informe"
                disabled={
                  loadingReport ||
                  (user?.plan?.toLowerCase() !== 'plus'
                    ? reportsTodayCount >= 1 || entriesCount < 10
                    : reportsTodayCount >= 2)
                }
              >
                {loadingReport ? (
                  <span>Cargando...</span>
                ) : (
                  <>
                    <MdDescription className="mr-2" />
                    <span>Generar Informe</span>
                  </>
                )}
              </button>
            </div>
            <div className="mt-2 text-center">
              <p className={`text-xs italic ${subTextColorClasses}`}>Genera y descarga tu informe personalizado.</p>
            </div>
          </div>

          {/* Lista de Reportes */}
          <div className={cardClasses}>
            <h3 className={`flex items-center gap-2 text-xl font-semibold mb-4 ${headingColorClasses}`}>
              <MdDescription />
              Reportes Generados
            </h3>
            {reports.length === 0 ? (
              <p className={`text-sm ${subTextColorClasses}`}>
                No hay reportes generados aún.
              </p>
            ) : (
              <ul className="space-y-3 mt-3">
                {reports.map((rep) => (
                  <li
                    key={rep._id}
                    className={`
                      flex items-center justify-between gap-4 p-4 rounded-md border transition-all
                      ${isDark ? 'border-gray-700 hover:bg-gray-700' : 'border-gray-200 hover:bg-gray-100'}
                    `}
                  >
                    <div className="flex flex-col">
                      <button
                        className={`
                          underline text-base transition-colors
                          ${isDark ? 'text-green-300 hover:text-green-400' : 'text-green-600 hover:text-green-800'}
                        `}
                        onClick={() => handleSelectReport(rep._id)}
                      >
                        {rep.title || 'Reporte'}
                      </button>
                      <span className="text-xs mt-1">
                        {new Date(rep.createdAt).toLocaleString('es-ES', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                    <div className="flex items-center gap-3">
                      <button
                        className="text-green-500 hover:text-green-600 focus:outline-none"
                        onClick={() => handleDownloadPDF(rep)}
                        title="Descargar PDF"
                      >
                        <MdDownload size={20} />
                      </button>
                      <button
                        className="text-red-500 hover:text-red-600 focus:outline-none"
                        onClick={() => handleDeleteReport(rep._id)}
                        title="Eliminar Reporte"
                      >
                        <MdDelete size={20} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Detalle del Reporte Seleccionado */}
          {selectedReport && (
            <div className={cardClasses}>
              <div className="flex items-center justify-between">
                <h3 className={`flex items-center gap-2 text-xl font-semibold ${headingColorClasses}`}>
                  <MdDescription />
                  Detalle del Reporte
                </h3>
                <button
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={handleCloseReport}
                  aria-label="Cerrar reporte"
                  title="Cerrar reporte"
                >
                  <MdClose size={22} />
                </button>
              </div>
              <p className={`text-xs mb-3 ${subTextColorClasses}`}>
                Creado el:{' '}
                {new Date(selectedReport.createdAt).toLocaleString('es-ES', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </p>

              {isDark ? (
                <style>
                  {`
                    .markdown-scrollbar::-webkit-scrollbar {
                      width: 8px;
                    }
                    .markdown-scrollbar::-webkit-scrollbar-track {
                      background: #2d3748;
                    }
                    .markdown-scrollbar::-webkit-scrollbar-thumb {
                      background-color: #4a5568;
                      border-radius: 4px;
                    }
                    .markdown-scrollbar {
                      scrollbar-width: thin;
                      scrollbar-color: #4a5568 #2d3748;
                    }
                  `}
                </style>
              ) : (
                <style>
                  {`
                    .markdown-scrollbar::-webkit-scrollbar {
                      width: 8px;
                    }
                    .markdown-scrollbar::-webkit-scrollbar-track {
                      background: #edf2f7;
                    }
                    .markdown-scrollbar::-webkit-scrollbar-thumb {
                      background-color: #a0aec0;
                      border-radius: 4px;
                    }
                    .markdown-scrollbar {
                      scrollbar-width: thin;
                      scrollbar-color: #a0aec0 #edf2f7;
                    }
                  `}
                </style>
              )}

              <div className={`prose max-h-[40rem] overflow-y-auto markdown-scrollbar mx-auto ${isDark ? 'prose-invert' : ''}`}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {selectedReport.report?.text || 'Sin contenido'}
                </ReactMarkdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportPanel;
