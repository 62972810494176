import React from 'react';
import { useTheme } from '../ThemeContext';


const Footer = () => {
  const { theme } = useTheme();

  return (
    <div
      className={`flex flex-col w-full transition-colors duration-300 ${
        theme === 'light' ? 'bg-gray-100 text-gray-800' : 'bg-gray-900 text-gray-200'
      } h-auto py-6`}
    >
      <div className="App">
        {/* Puedes incluir aquí el componente de subida */}
      </div>
      {/* Aquí podrías incluir otros contenidos del footer */}
    </div>
  );
};

export default Footer;
