// ProjectionPanel/useProjection.js

import { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { usePlan } from '../../contexts/PlanContext';
import { useTheme } from '../../ThemeContext';

// Funciones auxiliares
const getCaloricIntakeLabel = (value) => {
  if (!value) return 'Recomposición';
  const intakeLower = value.toLowerCase();
  if (intakeLower.includes('def')) return 'Definición';
  if (intakeLower.includes('vol')) return 'Volumen';
  return 'Recomposición';
};

const getSexLabel = (sex) => {
  const sexLower = sex.toLowerCase();
  switch (sexLower) {
    case 'm':
    case 'male':
      return 'Masculino';
    case 'f':
    case 'female':
      return 'Femenino';
    default:
      return 'Otro';
  }
};

const classifyBMI = (bmi) => {
  if (bmi < 18.5) return { category: 'Bajo peso', risk: 'Riesgo para la salud' };
  if (bmi >= 18.5 && bmi < 25)
    return { category: 'Peso normal', risk: 'Promedio' };
  if (bmi >= 25 && bmi < 30)
    return { category: 'Sobrepeso', risk: 'Aumentado' };
  if (bmi >= 30 && bmi < 35)
    return { category: 'Obesidad Grado I', risk: 'Moderado' };
  if (bmi >= 35 && bmi < 40)
    return { category: 'Obesidad Grado II', risk: 'Severo' };
  if (bmi >= 40)
    return { category: 'Obesidad Grado III (mórbida)', risk: 'Muy severo' };
  return { category: 'Indeterminado', risk: 'N/A' };
};

const classifyBodyFat = (sex, bodyFat) => {
  if (sex.toLowerCase() === 'f' || sex.toLowerCase() === 'female') {
    if (bodyFat < 21) return 'Atleta';
    if (bodyFat >= 21 && bodyFat < 33) return 'Fitness';
    if (bodyFat >= 33 && bodyFat < 39) return 'Aceptable';
    return 'Obeso';
  } else {
    if (bodyFat < 8) return 'Atleta';
    if (bodyFat >= 8 && bodyFat < 20) return 'Fitness';
    if (bodyFat >= 20 && bodyFat < 25) return 'Aceptable';
    return 'Obeso';
  }
};

// Hook personalizado
const useProjection = () => {
  const {
    planData,
    healthMetrics,
    caloricIntake: contextCaloricIntake,
    useCreatine: contextUseCreatine,
  } = usePlan();

  const { theme } = useTheme();

  // Lectura de datos
  const effectiveCaloricIntake =
    planData?.caloricIntake ?? contextCaloricIntake ?? 'recomp';
  const effectiveUseCreatine =
    planData?.useCreatine ?? contextUseCreatine ?? false;
  const { start, end } = planData || {};

  const {
    weight = 0,
    bodyFat,
    height = 0,
    age = 25,
    sex = 'M',
    activityFactor = 1.2,
  } = healthMetrics || {};

  const isBodyFatAvailable =
    bodyFat !== undefined && bodyFat !== null && bodyFat > 0;

  // Cálculos memoizados para optimizar rendimiento
  const projection = useMemo(() => {
    // Cálculo de días totales
    const totalDays = (() => {
      if (!start || !end) return 0;
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (isNaN(startDate) || isNaN(endDate)) return 0;
      return differenceInDays(endDate, startDate);
    })();

    // Cálculo del BMR (Mifflin-St Jeor)
    const bmr = (() => {
      if (weight <= 0 || height <= 0 || age <= 0) return 0;
      const base = 10 * weight + 6.25 * height - 5 * age;
      return sex.toLowerCase() === 'f' || sex.toLowerCase() === 'female'
        ? base - 161
        : base + 5;
    })();

    // Cálculo del TDEE
    const tdee = bmr * activityFactor;

    // Ajuste calórico según objetivo
    const dailyCalorieDiff = (() => {
      if (!effectiveCaloricIntake) return 0;
      const intakeLower = effectiveCaloricIntake.toLowerCase();
      if (intakeLower.includes('def')) return -500;
      if (intakeLower.includes('vol')) return 500;
      return 0; // Recomposición
    })();

    // Calorías diarias recomendadas
    const dailyRecommendedCalories = (tdee ?? 0) + (dailyCalorieDiff ?? 0);

    // Proyección de cambio de peso
    const totalCalorieDiff = dailyRecommendedCalories - tdee;
    const totalWeightChange = totalDays > 0 ? (totalCalorieDiff * totalDays) / 7700 : 0;

    // Masa grasa y muscular inicial
    let initialFatMass = 0;
    let initialMuscleMass = weight;
    if (isBodyFatAvailable) {
      initialFatMass = (bodyFat / 100) * weight;
      initialMuscleMass = weight - initialFatMass;
    }

    // Proyección final
    const baseActivity = 1.2;
    const activityMultiplier = activityFactor / baseActivity;

    let finalFatMass = 0;
    let finalMuscleMass = weight;
    if (isBodyFatAvailable) {
      let fatChange = 0;
      let muscleChange = 0;
      const intakeLower = (effectiveCaloricIntake || '').toLowerCase();

      if (intakeLower.includes('def')) {
        // Definición
        const weightLoss = Math.min(0, totalWeightChange);
        fatChange = weightLoss * 0.8;
        muscleChange = weightLoss * 0.2;

        if (effectiveUseCreatine) {
          muscleChange *= 0.5;
        }

        muscleChange *= 1 / activityMultiplier;
      } else if (intakeLower.includes('vol')) {
        // Volumen
        const weightGain = Math.max(0, totalWeightChange);
        fatChange = weightGain * 0.3;
        muscleChange = weightGain * 0.7;

        if (effectiveUseCreatine) {
          muscleChange *= 1.1;
        }

        muscleChange *= activityMultiplier;
      } else {
        // Recomp
        const baseDailyFatChangeRate = -0.03 / 100;
        const baseDailyMuscleChangeRate = 0.02 / 100;

        const adjustedDailyFatChangeRate = baseDailyFatChangeRate * activityMultiplier;
        const adjustedDailyMuscleChangeRate = baseDailyMuscleChangeRate * activityMultiplier;

        fatChange = initialFatMass * adjustedDailyFatChangeRate * totalDays;
        muscleChange = initialMuscleMass * adjustedDailyMuscleChangeRate * totalDays;

        if (effectiveUseCreatine) {
          muscleChange *= 1.2;
        }

        fatChange = Math.max(fatChange, -initialFatMass);
        muscleChange = Math.min(muscleChange, initialMuscleMass * 0.1);
      }

      finalFatMass = initialFatMass + fatChange;
      finalMuscleMass = initialMuscleMass + muscleChange;
      finalFatMass = Math.max(finalFatMass, 0);
      finalMuscleMass = Math.max(finalMuscleMass, 0);
    } else {
      // Proyección simplificada sin datos de grasa corporal
      finalMuscleMass = weight + totalWeightChange * 0.2;
    }

    // Peso y % grasa final
    const finalWeight = finalFatMass + finalMuscleMass;
    const finalBodyFatPercentage = isBodyFatAvailable
      ? finalWeight > 0
        ? (finalFatMass / finalWeight) * 100
        : 0
      : null;

    // BMI Inicial y Final
    const heightM = height > 0 ? height / 100 : 1;
    const initialBmi = heightM > 0 ? weight / (heightM * heightM) : 0;
    const finalBmi = heightM > 0 ? finalWeight / (heightM * heightM) : 0;

    // Recomendación de proteína
    const dailyProteinRecommended = weight * 1.8;

    // Calorías a Comer y Gastar Hoy
    const mustEatToday = dailyRecommendedCalories ?? 0;
    const mustBurnToday = Math.max((dailyRecommendedCalories ?? 0) - (bmr ?? 0), 0);

    // Clasificaciones
    const bmiClassification = classifyBMI(initialBmi);
    const finalBmiClassification = classifyBMI(finalBmi);

    const bodyFatClassification = isBodyFatAvailable
      ? classifyBodyFat(sex, bodyFat)
      : null;

    const finalBodyFatClassification = isBodyFatAvailable
      ? classifyBodyFat(sex, finalBodyFatPercentage)
      : null;

    return {
      totalDays,
      bmr,
      tdee,
      dailyCalorieDiff,
      dailyRecommendedCalories,
      totalWeightChange,
      initialFatMass,
      initialMuscleMass,
      finalFatMass,
      finalMuscleMass,
      finalWeight,
      finalBodyFatPercentage,
      initialBmi,
      finalBmi,
      dailyProteinRecommended,
      mustEatToday,
      mustBurnToday,
      bmiClassification,
      finalBmiClassification,
      bodyFatClassification,
      finalBodyFatClassification,
      isBodyFatAvailable,
      getCaloricIntakeLabel,
      getSexLabel,
      theme,
      start,
      end,
      sex,
      age,
      height,
      weight,
      bodyFat,
      activityFactor,
    };
  }, [
    planData,
    healthMetrics,
    contextCaloricIntake,
    contextUseCreatine,
    theme,
    start,
    end,
    weight,
    height,
    age,
    sex,
    activityFactor,
    effectiveCaloricIntake,
    effectiveUseCreatine,
    bodyFat,
  ]);

  return projection;
};

export default useProjection;
