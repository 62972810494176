// src/Components/HomePage.js
import React from 'react';
import { useAuth } from './Components/contexts/AuthContext';
import FeatureTour from './FeatureTour';
import { FcGoogle } from 'react-icons/fc';
import IllustrationSlideshow from './IllustrationSlideshow'; // <-- Importa tu carrusel

const HomePage = () => {
  const { login } = useAuth();

  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gradient-to-br from-purple-600 via-pink-500 to-red-400">
      {/* Sección de Login (col-1) */}
      <div className="flex-1 flex flex-col items-center justify-center p-8">
        
        {/* Carrusel de ilustraciones arriba del "card" */}
        <IllustrationSlideshow />
        
        {/* Contenedor de login estilo "card" */}
        <div className="w-full max-w-md bg-white/10 backdrop-blur-md rounded-xl shadow-2xl p-8 border border-white/10">
          <h1 className="text-4xl md:text-5xl font-black text-white mb-6 text-center tracking-tight">
            Bienvenido a Carendary
          </h1>
          <p className="text-base md:text-lg text-white/90 mb-8 text-center leading-normal">
            Inicia sesión para comenzar a tener control inteligente de tu salud
          </p>
          <button
            onClick={login}
            className="w-full inline-flex items-center justify-center gap-2 px-6 py-3 rounded-md font-semibold text-sm md:text-base text-slate-900 bg-white hover:bg-white/90 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
          >
            <FcGoogle size={24} />
            Iniciar Sesión con Google
          </button>
        </div>
      </div>

      {/* Sección de Recorrido de Funcionalidades (col-2) */}
      <div className="flex-1 p-8">
        <FeatureTour />
      </div>
    </div>
  );
};

export default HomePage;
