import { useState, useEffect, useCallback, useRef } from 'react';
import { getSavedData, saveData, removeData } from './storage';
import { useAuth } from '../../contexts/AuthContext';
import { usePlan } from '../../contexts/PlanContext';
import { differenceInCalendarDays } from 'date-fns';

const useDataManagement = (initialData) => {
  const { user, loading } = useAuth();
  const {
    getHealthMetrics,
    saveHealthMetrics,
    deleteHealthMetrics,
    getPlans,
    savePlan,
    updatePlan,
    deletePlan,
  } = usePlan();

  const [savedHealthMetrics, setSavedHealthMetrics] = useState(() => {
    return getSavedData('healthMetrics', initialData);
  });
  const [savedPlanData, setSavedPlanData] = useState(() => {
    return getSavedData('planData', {});
  });
  const [formData, setFormData] = useState({ ...savedHealthMetrics, ...savedPlanData });
  const [isSaved, setIsSaved] = useState(true);
  const [isSynced, setIsSynced] = useState(false);
  const [error, setError] = useState(null);

  const prevUserRef = useRef();
  const hasFetchedData = useRef(false);

  const fetchData = useCallback(async () => {
    try {
      const healthData = await getHealthMetrics();
      const plansData = await getPlans();

      let combinedHealth = { ...initialData };
      if (healthData) {
        combinedHealth = { ...combinedHealth, ...healthData };
      }

      let combinedPlan = {};
      if (plansData && plansData.length > 0) {
        const plan = plansData[0];
        const startDate = plan.start ? plan.start.split('T')[0] : '';
        const endDate = plan.end ? plan.end.split('T')[0] : '';
        // Calcular la duración en días a partir de start y end
        const duration =
          startDate && endDate
            ? differenceInCalendarDays(new Date(endDate), new Date(startDate))
            : '';

        combinedPlan = {
          _planId: plan._id,
          startDate,
          endDate,
          caloricIntake: plan.caloricIntake,
          useCreatine: plan.useCreatine,
          planDuration: duration,
        };

        saveData('planData', combinedPlan);
        setSavedPlanData(combinedPlan);
      } else {
        const localPlanData = getSavedData('planData', {});
        combinedPlan = { ...localPlanData };
      }

      setSavedHealthMetrics(combinedHealth);
      setFormData({ ...combinedHealth, ...combinedPlan });
      saveData('healthMetrics', combinedHealth);
      setIsSynced(true);
    } catch (error) {
      console.error('Error combinando métricas/plan:', error);
      setError('Error al combinar métricas y planes. Por favor, intenta nuevamente.');
    }
  }, [getHealthMetrics, getPlans, initialData]);

  useEffect(() => {
    if (user && !loading && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [user, loading, fetchData]);

  useEffect(() => {
    const isDataChanged =
      JSON.stringify(formData) !==
      JSON.stringify({ ...savedHealthMetrics, ...savedPlanData });
    setIsSaved(!isDataChanged);
  }, [formData, savedHealthMetrics, savedPlanData]);

  useEffect(() => {
    const prevUser = prevUserRef.current;
    if (prevUser && !user && !loading) {
      removeData('healthMetrics');
      removeData('planData');
      setFormData(initialData);
      setSavedHealthMetrics(initialData);
      setSavedPlanData({});
      setIsSaved(true);
      setIsSynced(false);
      hasFetchedData.current = false;
      setError(null);
    } else if (!prevUser && user && !loading) {
      hasFetchedData.current = false;
    }
    prevUserRef.current = user;
  }, [user, loading, initialData]);

  const saveFormData = async (updatedData) => {
    const {
      dob,
      sex,
      weight,
      height,
      activityFactor,
      neck,
      waist,
      hip,
      bmi,
      bodyFat,
      tdee,
      bmr,
      age,
      bmiPercentile,
      _planId,
      startDate,
      endDate,
      caloricIntake,
      useCreatine,
    } = updatedData;

    const healthMetricsPayload = {
      dob,
      sex,
      weight,
      height,
      activityFactor,
      neck,
      waist,
      hip,
      bmi,
      bodyFat,
      tdee,
      bmr,
      age,
      bmiPercentile,
    };

    const planPayload = {
      start: startDate ? new Date(startDate).toISOString() : null,
      end: endDate ? new Date(endDate).toISOString() : null,
      caloricIntake,
      useCreatine,
    };

    try {
      const savedHealthMetrics = await saveHealthMetrics(healthMetricsPayload);
      saveData('healthMetrics', savedHealthMetrics);
      setSavedHealthMetrics(savedHealthMetrics);

      let savedPlan = null;
      if (_planId) {
        savedPlan = await updatePlan(_planId, planPayload);
      } else {
        savedPlan = await savePlan(planPayload);
      }

      let combinedPlan = {};
      if (savedPlan && savedPlan._id) {
        combinedPlan = {
          _planId: savedPlan._id,
          startDate: savedPlan.start ? savedPlan.start.split('T')[0] : '',
          endDate: savedPlan.end ? savedPlan.end.split('T')[0] : '',
          caloricIntake: savedPlan.caloricIntake,
          useCreatine: savedPlan.useCreatine,
        };

        // Calcular la duración en días y agregarla al objeto
        if (combinedPlan.startDate && combinedPlan.endDate) {
          combinedPlan.planDuration = differenceInCalendarDays(
            new Date(combinedPlan.endDate),
            new Date(combinedPlan.startDate)
          );
        }

        saveData('planData', combinedPlan);
        setSavedPlanData(combinedPlan);
      }

      setFormData({ ...savedHealthMetrics, ...combinedPlan });
      setIsSaved(true);
      setIsSynced(true);
      setError(null);
    } catch (error) {
      console.error('Error al guardar los datos combinados:', error);
      setError('Error al guardar los datos. Por favor, intenta nuevamente.');
    }
  };

  const handleDelete = async (isPlanActive) => {
    if (isPlanActive) {
      alert(
        'No puedes eliminar tus datos mientras exista un plan activo. Por favor, elimina el plan primero.'
      );
      return;
    }

    setFormData(initialData);
    setSavedHealthMetrics(initialData);
    setSavedPlanData({});
    removeData('healthMetrics');
    removeData('planData');
    setIsSaved(true);
    setIsSynced(false);
    setError(null);

    if (!user) {
      return;
    }

    try {
      await deleteHealthMetrics();

      if (formData._planId) {
        await deletePlan(formData._planId);
        removeData('planData');
      }
      setIsSynced(false);
    } catch (error) {
      console.error('Error al eliminar métricas/plan:', error);
      setError('Error al eliminar los datos. Por favor, intenta nuevamente.');
    }
  };

  return {
    formData,
    setFormData,
    savedHealthMetrics,
    setSavedHealthMetrics,
    savedPlanData,
    setSavedPlanData,
    isSaved,
    setIsSaved,
    handleDelete,
    saveFormData,
    user,
    loading,
    isSynced,
    setIsSynced,
    error,
  };
};

export default useDataManagement;
