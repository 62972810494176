// src/components/EntrySummary.js
import React from 'react';
import useEntrySummary from './useEntrySummary'; // Ajusta la ruta si difiere
import EntrySummaryView from './EntrySummaryView';           // Ajusta la ruta si difiere
import EntryDetailModal from './EntryDetailModal';           // Ajusta la ruta si difiere

const EntrySummary = () => {
  const {
    // Estados
    isOpen,
    toggleIsOpen,
    foodView,
    setFoodView,
    workoutView,
    setWorkoutView,
    selectedEntry,
    handleSelectEntry,
    handleCloseModal,

    // Contextos y Temas
    theme,
    deleteEntry,

    // Estadísticas
    totalEntries,
    foodStats,
    workoutStats,
    moodStats,
    stateStats,

    // Vistas
    previewCounters,

    // Listas
    foodList,
    workoutList,
    moodList,
    physicalStateList,

    // Macro
    macroDistribution,

    // Calorías de hoy
    todayFoodCalories,
    todayWorkoutCalories,
  } = useEntrySummary();

  return (
    <>
      <EntrySummaryView
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        previewCounters={previewCounters}
        totalEntries={totalEntries}
        // Estadísticas
        foodStats={foodStats}
        workoutStats={workoutStats}
        moodStats={moodStats}
        stateStats={stateStats}
        // Vistas
        foodView={foodView}
        setFoodView={setFoodView}
        workoutView={workoutView}
        setWorkoutView={setWorkoutView}
        // Tema
        theme={theme}
        // Listas
        foodList={foodList}
        workoutList={workoutList}
        moodList={moodList}
        physicalStateList={physicalStateList}
        // Macro
        macroDistribution={macroDistribution}
        // Funciones para eliminar y seleccionar
        deleteEntry={deleteEntry}
        onSelectEntry={handleSelectEntry}
        // Calorías de hoy
        todayFoodCalories={todayFoodCalories}
        todayWorkoutCalories={todayWorkoutCalories}
      />

      {/* Modal de detalles */}
      {selectedEntry && (
        <EntryDetailModal
          entry={selectedEntry}
          onClose={handleCloseModal}
          theme={theme}
        />
      )}
    </>
  );
};

export default EntrySummary;
