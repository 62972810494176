import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const IllustrationSlideshow = () => {
  // Array de objetos: cada uno contiene la ruta de la imagen y la historia/ descripción correspondiente
  const slides = [
    {
      image: '/images/illustrations/better-self.svg',
      title: 'Tu Mejor Versión',
      description:
        'Cada día es una oportunidad para forjar buenos hábitos y acercarte a una vida más saludable. ¡Tú puedes lograrlo!',
    },
    {
      image: '/images/illustrations/check.svg',
      title: 'Logra tus Metas',
      description:
        'Registra tus objetivos y disfruta de la satisfacción de verlos convertidos en logros tangibles.',
    },
    {
      image: '/images/illustrations/coffe-time.svg',
      title: 'Tu Bebida Favorita',
      description:
        '¿Un té, un café o un batido cargado de energía? Anímate a llevar un registro de lo que tomas y encuentra el equilibrio perfecto.',
    },
    {
      image: '/images/illustrations/cookie.svg',
      title: 'Pequeños Gustos',
      description:
        'Darte un gusto de vez en cuando no está mal, ¡cuenta tus snacks y lleva un mejor control de tus antojos!',
    },
    {
      image: '/images/illustrations/diet.svg',
      title: 'Control de Tu Dieta',
      description:
        'Registra tus comidas y calorías para entender mejor tus hábitos alimenticios y transformarlos con inteligencia.',
    },
    {
      image: '/images/illustrations/fitness-tracker.svg',
      title: 'Rutina Activa',
      description:
        'Ejercítate, registra el tiempo de actividad y las calorías que quemas. Cada paso cuenta hacia una vida más activa.',
    },
    {
      image: '/images/illustrations/hiking.svg',
      title: 'Aire Libre',
      description:
        '¿Prefieres trotar o ir de senderismo? Lleva el recuento de tus aventuras y siente la motivación de cada meta superada.',
    },
    {
      image: '/images/illustrations/medical-information.svg',
      title: 'Información Médica',
      description:
        'Guarda tus datos médicos importantes en un solo lugar para llevar un seguimiento más completo de tu salud.',
    },
    {
      image: '/images/illustrations/positive.svg',
      title: 'Emociones Positivas',
      description:
        'Registra tus sentimientos alegres, ¡celebra esos momentos y refuérzalos para mantener la motivación al máximo!',
    },
    {
      image: '/images/illustrations/progress.svg',
      title: 'Resumen de Tu Progreso',
      description:
        'Mira la línea de tiempo de tus logros y hábitos registrados para inspirarte a seguir avanzando.',
    },
    {
      image: '/images/illustrations/resume.svg',
      title: 'Todo en Orden',
      description:
        'Con un solo vistazo, obtén un panorama completo de tu bienestar. ¡La organización te empuja a mejorar cada día!',
    },
    {
      image: '/images/illustrations/sad.svg',
      title: 'Cuando No Todo Es Perfecto',
      description:
        'No temas registrar también esos días grises. Aceptar y entender tus emociones es parte esencial del proceso.',
    },
    {
      image: '/images/illustrations/wonder.svg',
      title: 'Descubre Nuevas Posibilidades',
      description:
        'Explora distintas opciones de alimentación, ejercicio y rutinas para encontrar lo que mejor se adapte a ti.',
    },
    {
      image: '/images/illustrations/working-out.svg',
      title: 'En Movimiento',
      description:
        'Entrenamientos, estiramientos o paseos: cada actividad cuenta. Regístrala y mantén esa racha de movimiento.',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Cambia de slide cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [slides.length]);

  // Variantes de animación basadas en principios de UX: suavidad, escalado y desplazamiento sutil
  const slideVariants = {
    initial: { opacity: 0, scale: 0.95, x: 50 },
    animate: { opacity: 1, scale: 1, x: 0 },
    exit: { opacity: 0, scale: 0.95, x: -50 },
  };

  const currentSlide = slides[currentIndex];

  return (
    <div
      className="relative w-full h-80 flex items-center justify-center mb-8 overflow-hidden p-4"
      // Fondo con degradado suave que genera calma y positividad
      style={{ background: 'transparent' }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={currentIndex}
          variants={slideVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.8, ease: 'easeInOut' }}
          className="absolute flex flex-col md:flex-row items-center justify-center gap-6 md:gap-12"
          style={{ width: '100%', height: '100%' }}
        >
          <motion.img
            src={currentSlide.image}
            alt={currentSlide.title}
            className="max-h-52 md:max-h-64 object-contain"
            // Animación sutil al pasar el ratón
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300 }}
          />
          <motion.div
            className="max-w-md text-center md:text-left"
            // Un leve desplazamiento al aparecer el texto
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.6 }}
          >
            <h2 className="text-xl md:text-2xl font-bold mb-2 text-indigo-600">
              {currentSlide.title}
            </h2>
            <p className="text-sm md:text-base text-gray-700">
              {currentSlide.description}
            </p>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default IllustrationSlideshow;
