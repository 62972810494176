import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const generatePDF = async (user, healthMetrics, planData, report) => {
  try {
    // Inicializar documento PDF
    const doc = new jsPDF('p', 'pt', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Definir márgenes y ancho utilizable
    const marginTop = 70;
    const marginBottom = 60; // Margen inferior incrementado para separar del pie de página
    const marginLeft = 50;
    const marginRight = 50;
    const usableWidth = pageWidth - marginLeft - marginRight;
    let yPosition = marginTop;

    /**
     * Función para restablecer los estilos por defecto para el cuerpo del documento.
     */
    const setBodyDefaults = () => {
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
    };

    /**
     * Comprueba si hay espacio suficiente en la página actual para el contenido
     * sin invadir el área reservada para el pie de página.
     */
    const addPageIfNeeded = (requiredSpace) => {
      if (yPosition + requiredSpace > pageHeight - marginBottom) {
        doc.addPage();
        addHeader();
        setBodyDefaults();
      }
    };

    /**
     * Agrega un encabezado profesional en la parte superior de cada página.
     */
    const addHeader = () => {
      const headerHeight = 70;
      doc.setFillColor(22, 160, 133);
      doc.rect(0, 0, pageWidth, headerHeight, 'F');

      doc.setFontSize(16);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor(255, 255, 255);
      doc.text('Carendary', marginLeft, 30);

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      doc.text('Análisis de salud con IA de OpenAI', marginLeft, 50);

      const version = '1.0';
      const year = new Date().getFullYear();
      doc.text(`Formato v${version} - ${year}`, pageWidth - marginRight, 30, { align: 'right' });

      doc.setDrawColor(200, 200, 200);
      doc.setLineWidth(1);
      doc.line(marginLeft, headerHeight, pageWidth - marginRight, headerHeight);

      yPosition = headerHeight + 20;
      doc.setTextColor(0, 0, 0);
      setBodyDefaults();
    };

    /**
     * Imprime un párrafo simple (sin justificación) dividiéndolo en líneas.
     */
    const printParagraph = (text, options = {}) => {
      const { fontSize = 12, lineSpacing = 1.5, font = 'Helvetica', fontStyle = 'normal' } = options;
      doc.setFontSize(fontSize);
      doc.setFont(font, fontStyle);
      const lines = doc.splitTextToSize(text, usableWidth);
      const lineHeight = fontSize * lineSpacing;
      lines.forEach((line) => {
        addPageIfNeeded(lineHeight);
        doc.text(line, marginLeft, yPosition);
        yPosition += lineHeight;
      });
      yPosition += 10;
    };

    /**
     * Agrega un título de sección.
     */
    const addSectionTitle = (title) => {
      addPageIfNeeded(30);
      doc.setFontSize(18);
      doc.setFont('Helvetica', 'bold');
      doc.text(title, marginLeft, yPosition);
      yPosition += 30;
    };

    /**
     * Agrega detalles generales del reporte.
     */
    const addReportDetails = () => {
      if (report) {
        addPageIfNeeded(50);
        doc.setFontSize(16);
        doc.setFont('Helvetica', 'bold');
        printParagraph(report.title || 'Reporte Clínico', { fontSize: 16 });
        doc.setFontSize(12);
        doc.setFont('Helvetica', 'normal');
        const creationDate = new Date(report.createdAt).toLocaleString('es-ES', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
        printParagraph(`Fecha de Creación: ${creationDate}`, { fontSize: 12 });
        addPageIfNeeded(20);
        doc.line(marginLeft, yPosition, pageWidth - marginRight, yPosition);
        yPosition += 20;
      }
    };

    /**
     * Función para imprimir un párrafo con texto justificado.
     * (Para párrafos sin formato “rich”)
     */
    const printJustifiedParagraph = (paragraph, options = {}) => {
      const { fontSize = 14, lineSpacing = 1.5, font = 'Helvetica', fontStyle = 'normal' } = options;
      doc.setFontSize(fontSize);
      doc.setFont(font, fontStyle);

      const words = paragraph.split(/\s+/);
      let line = '';
      const lines = [];
      words.forEach(word => {
        const testLine = line ? line + ' ' + word : word;
        if (doc.getTextWidth(testLine) > usableWidth && line) {
          lines.push(line);
          line = word;
        } else {
          line = testLine;
        }
      });
      if (line) lines.push(line);

      const lineHeight = fontSize * lineSpacing;
      lines.forEach((lineText, index) => {
        addPageIfNeeded(lineHeight);
        const lineWords = lineText.split(' ');
        if (index === lines.length - 1 || lineWords.length === 1) {
          doc.text(lineText, marginLeft, yPosition);
        } else {
          const totalWordsWidth = lineWords.reduce((acc, word) => acc + doc.getTextWidth(word), 0);
          const spacesCount = lineWords.length - 1;
          const spaceWidth = (usableWidth - totalWordsWidth) / spacesCount;
          let currentX = marginLeft;
          lineWords.forEach((word, i) => {
            doc.text(word, currentX, yPosition);
            currentX += doc.getTextWidth(word) + spaceWidth;
          });
        }
        yPosition += lineHeight;
      });
      yPosition += 10;
    };

    /**
     * NUEVA FUNCIÓN: Imprime un párrafo “rich” (con **negritas** y *itálicas*) justificado.
     *
     * Se procesa el texto:
     * 1. Se extraen los segmentos con formato en negrita e itálica.
     * 2. Se dividen los segmentos en palabras, manteniendo el estilo correspondiente.
     * 3. Se agrupan las palabras en líneas, respetando el ancho utilizable.
     * 4. Se imprime cada línea con justificación (distribuyendo el espacio extra entre las palabras)
     *    excepto la última línea o líneas con una sola palabra, que se alinean a la izquierda.
     */
    const printJustifiedRichParagraph = (text, options = {}) => {
      const { fontSize = 12, lineSpacing = 1.5, font = 'Helvetica' } = options;
      doc.setFontSize(fontSize);

      // 1. Parsear el texto en segmentos basados en **negrita** y *itálica*
      // La siguiente expresión regular captura:
      // - Grupo 1: **texto** (negrita)
      // - Grupo 3: *texto* (itálica) que no forma parte de ** **
      const regex = /(\*\*([^*]+)\*\*)|(\*([^*]+)\*)/g;
      let segments = [];
      let lastIndex = 0;
      let match;
      while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          // Agrega segmento sin formato
          segments.push({ text: text.substring(lastIndex, match.index), style: 'normal' });
        }
        if (match[1]) {
          // Coincidencia de **negrita**
          segments.push({ text: match[2], style: 'bold' });
        } else if (match[3]) {
          // Coincidencia de *itálica*
          segments.push({ text: match[4], style: 'italic' });
        }
        lastIndex = match.index + match[0].length;
      }
      if (lastIndex < text.length) {
        segments.push({ text: text.substring(lastIndex), style: 'normal' });
      }

      // 2. Dividir cada segmento en palabras y generar un arreglo de palabras con su estilo
      let words = [];
      segments.forEach(segment => {
        // Separamos las palabras (evitando espacios vacíos)
        const splitWords = segment.text.split(/\s+/).filter(word => word.length > 0);
        splitWords.forEach(word => {
          words.push({ text: word, style: segment.style });
        });
      });

      // Función para obtener el ancho de un espacio (usando estilo normal)
      const getSpaceWidth = () => {
        doc.setFont(font, 'normal');
        return doc.getTextWidth(' ');
      };
      const spaceWidth = getSpaceWidth();

      // 3. Agrupar palabras en líneas que no excedan el ancho utilizable
      let lines = [];
      let currentLine = [];
      let currentLineWidth = 0;
      words.forEach(wordObj => {
        doc.setFont(font, wordObj.style === 'normal' ? 'normal' : wordObj.style);
        const wordWidth = doc.getTextWidth(wordObj.text);
        const additionalSpace = currentLine.length > 0 ? spaceWidth : 0;
        if (currentLineWidth + additionalSpace + wordWidth > usableWidth) {
          lines.push(currentLine);
          currentLine = [wordObj];
          currentLineWidth = wordWidth;
        } else {
          if (currentLine.length > 0) currentLineWidth += spaceWidth;
          currentLine.push(wordObj);
          currentLineWidth += wordWidth;
        }
      });
      if (currentLine.length > 0) lines.push(currentLine);

      // 4. Imprimir cada línea justificada
      const lineHeight = fontSize * lineSpacing;
      lines.forEach((lineWords, index) => {
        addPageIfNeeded(lineHeight);
        let currentX = marginLeft;
        // Si es la última línea o la línea tiene una sola palabra: alineación a la izquierda
        if (index === lines.length - 1 || lineWords.length === 1) {
          lineWords.forEach(wordObj => {
            doc.setFont(font, wordObj.style === 'normal' ? 'normal' : wordObj.style);
            doc.text(wordObj.text, currentX, yPosition);
            currentX += doc.getTextWidth(wordObj.text) + spaceWidth;
          });
        } else {
          // Calcular el ancho total de las palabras
          let totalWordsWidth = 0;
          lineWords.forEach(wordObj => {
            doc.setFont(font, wordObj.style === 'normal' ? 'normal' : wordObj.style);
            totalWordsWidth += doc.getTextWidth(wordObj.text);
          });
          const gaps = lineWords.length - 1;
          const extraSpace = (usableWidth - totalWordsWidth) / gaps;
          lineWords.forEach((wordObj, idx) => {
            doc.setFont(font, wordObj.style === 'normal' ? 'normal' : wordObj.style);
            doc.text(wordObj.text, currentX, yPosition);
            currentX += doc.getTextWidth(wordObj.text);
            if (idx < lineWords.length - 1) {
              currentX += extraSpace;
            }
          });
        }
        yPosition += lineHeight;
      });
      yPosition += 10;
    };

    /**
     * Procesa y renderiza un bloque de texto en Markdown aplicando justificación.
     * Se detectan los encabezados (que se imprimen de forma normal) y se agrupan las líneas
     * de párrafo. Además, se respeta que las líneas que inician con "-" se impriman como párrafos
     * independientes, respetando los saltos de línea.
     */
    const printMarkdownBlockJustified = (markdownText) => {
      const lines = markdownText.split("\n");
      let paragraphBuffer = "";
      lines.forEach(line => {
        // Si la línea inicia con "-" se considera una sección o ítem independiente
        if (line.trim().startsWith('-')) {
          if (paragraphBuffer.trim() !== "") {
            if (paragraphBuffer.includes('**') || paragraphBuffer.includes('*')) {
              printJustifiedRichParagraph(paragraphBuffer, { fontSize: 14 });
            } else {
              printJustifiedParagraph(paragraphBuffer, { fontSize: 14 });
            }
            paragraphBuffer = "";
          }
          // Imprimir la línea que inicia con "-" de forma individual
          if (line.trim().includes('**') || line.trim().includes('*')) {
            printJustifiedRichParagraph(line, { fontSize: 14 });
          } else {
            printJustifiedParagraph(line, { fontSize: 14 });
          }
        } else {
          // Detectar encabezados (por ejemplo: "## Título")
          const headingMatch = line.match(/^(#{1,6})\s+(.*)/);
          if (headingMatch) {
            if (paragraphBuffer.trim() !== "") {
              if (paragraphBuffer.includes('**') || paragraphBuffer.includes('*')) {
                printJustifiedRichParagraph(paragraphBuffer, { fontSize: 14 });
              } else {
                printJustifiedParagraph(paragraphBuffer, { fontSize: 14 });
              }
              paragraphBuffer = "";
            }
            const level = headingMatch[1].length;
            let fontSize;
            switch (level) {
              case 1:
                fontSize = 20;
                break;
              case 2:
                fontSize = 18;
                break;
              case 3:
                fontSize = 16;
                break;
              default:
                fontSize = 14;
            }
            addPageIfNeeded(fontSize * 1.5 + 5);
            doc.setFontSize(fontSize);
            doc.setFont("Helvetica", "bold");
            const headerText = headingMatch[2].replace(/\*\*/g, "").replace(/\*/g, "");
            doc.text(headerText, marginLeft, yPosition);
            yPosition += fontSize * 1.5 + 5;
          } else if (line.trim() === "") {
            if (paragraphBuffer.trim() !== "") {
              if (paragraphBuffer.includes('**') || paragraphBuffer.includes('*')) {
                printJustifiedRichParagraph(paragraphBuffer, { fontSize: 14 });
              } else {
                printJustifiedParagraph(paragraphBuffer, { fontSize: 14 });
              }
              paragraphBuffer = "";
            } else {
              yPosition += 10;
            }
          } else {
            paragraphBuffer += (paragraphBuffer ? " " : "") + line;
          }
        }
      });
      if (paragraphBuffer.trim() !== "") {
        if (paragraphBuffer.includes('**') || paragraphBuffer.includes('*')) {
          printJustifiedRichParagraph(paragraphBuffer, { fontSize: 14 });
        } else {
          printJustifiedParagraph(paragraphBuffer, { fontSize: 14 });
        }
      }
      yPosition += 10;
    };

    // INICIO DEL DOCUMENTO
    addHeader();

    // Título principal del informe (centrado)
    addPageIfNeeded(50);
    doc.setFontSize(24);
    doc.setFont('Helvetica', 'bold');
    doc.setTextColor(0, 0, 0);
    doc.text('Informe Clínico de Salud y Fitness', pageWidth / 2, yPosition, { align: 'center' });
    yPosition += 50;

    // Detalles del reporte
    addReportDetails();

    // Sección: Información del Usuario
    addSectionTitle('Información del Usuario');
    doc.setFontSize(14);
    doc.setFont('Helvetica', 'normal');
    printParagraph(`Nombre: ${user?.displayName || 'N/A'}`, { fontSize: 14 });
    printParagraph(`Correo Electrónico: ${user?.email || 'N/A'}`, { fontSize: 14 });
    addPageIfNeeded(20);
    doc.line(marginLeft, yPosition, pageWidth - marginRight, yPosition);
    yPosition += 20;

    // Sección: Métricas de Salud
    addSectionTitle('Métricas de Salud');
    doc.setFontSize(14);
    doc.setFont('Helvetica', 'normal');
    if (healthMetrics) {
      const metrics = [
        ['Peso', `${healthMetrics.weight} kg`],
        ['Altura', `${healthMetrics.height} cm`],
        ['Edad', `${healthMetrics.age} años`],
        ['Sexo', healthMetrics.sex],
        ['Factor de Actividad', healthMetrics.activityFactor],
      ];
      addPageIfNeeded(metrics.length * 20 + 40);
      autoTable(doc, {
        startY: yPosition,
        margin: { bottom: marginBottom },
        head: [['Métrica', 'Valor']],
        body: metrics,
        theme: 'grid',
        styles: { font: 'Helvetica', fontSize: 12 },
        headStyles: { fillColor: [22, 160, 133], textColor: 255 },
        columnStyles: {
          0: { cellWidth: 150 },
          1: { cellWidth: 150 },
        },
      });
      yPosition = doc.previousAutoTable.finalY + 20;
    } else {
      printParagraph('No se encontraron métricas de salud.', { fontSize: 14 });
    }
    addPageIfNeeded(20);
    doc.line(marginLeft, yPosition, pageWidth - marginRight, yPosition);
    yPosition += 20;

    // Sección: Información del Plan
    addSectionTitle('Información del Plan');
    doc.setFontSize(14);
    doc.setFont('Helvetica', 'normal');
    if (planData) {
      printParagraph(`Uso de Creatina: ${planData.useCreatine ? 'Sí' : 'No'}`, { fontSize: 14 });
      printParagraph(`Fecha de Inicio: ${new Date(planData.start).toLocaleDateString('es-ES')}`, { fontSize: 14 });
      printParagraph(`Fecha de Finalización: ${new Date(planData.end).toLocaleDateString('es-ES')}`, { fontSize: 14 });
    } else {
      printParagraph('No se encontró información del plan.', { fontSize: 14 });
    }
    addPageIfNeeded(20);
    doc.line(marginLeft, yPosition, pageWidth - marginRight, yPosition);
    yPosition += 20;

    // Sección: Resumen del Reporte (procesa Markdown con justificación)
    addSectionTitle('Resumen del Reporte');
    const rawContent = report?.report?.text || 'No hay contenido disponible.';
    printMarkdownBlockJustified(rawContent);

    /**
     * Agrega el pie de página con paginación y fecha de generación.
     */
    const addFooters = () => {
      const pageCount = doc.internal.getNumberOfPages();
      const generationDate = new Date().toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.setFont('Helvetica', 'normal');
        const footerText = `Página ${i} de ${pageCount} | PDF Descargado el ${generationDate}`;
        doc.text(footerText, pageWidth / 2, pageHeight - 30, { align: 'center' });
      }
    };

    addFooters();

    return doc;
  } catch (error) {
    console.error('Error al generar el PDF:', error);
    throw error;
  }
};
