// src/components/ChatHistory.js
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';
import './ChatWindow.css'; // Importa los estilos personalizados

// Usar forwardRef para permitir la referencia desde el padre
const ChatHistory = forwardRef(
  (
    {
      chats,
      currentChatId,
      handleSelectChat,
      handleRenameChat,
      handleDeleteChat,
      className = '',
    },
    ref
  ) => {
    const { theme } = useTheme();

    const [editingChatId, setEditingChatId] = useState(null);
    const [newChatName, setNewChatName] = useState('');

    const [deletingChatId, setDeletingChatId] = useState(null);

    const startEditing = (id, currentName) => {
      setEditingChatId(id);
      setNewChatName(currentName);
    };

    const cancelEditing = () => {
      setEditingChatId(null);
      setNewChatName('');
    };

    const submitRename = (id) => {
      if (newChatName.trim() !== '') {
        handleRenameChat(id, newChatName.trim());
        cancelEditing();
      }
    };

    const confirmDelete = (id) => {
      setDeletingChatId(id);
    };

    const cancelDelete = () => {
      setDeletingChatId(null);
    };

    const submitDelete = (id) => {
      handleDeleteChat(id);
      setDeletingChatId(null);
    };

    // Variantes para los elementos de la lista de chats
    const chatItemVariants = {
      hidden: { opacity: 0, x: -20 },
      visible: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: 20 },
    };

    return (
      <div
        ref={ref} // Asignar la referencia
        className={`w-64 h-full border-r ${
          theme === 'dark'
            ? 'border-gray-700 bg-gray-900'
            : 'border-gray-300 bg-gray-100'
        } flex flex-col ${className}`}
      >
        {/* Header */}
        <div
          className={`flex items-center justify-between p-4 ${
            theme === 'dark' ? 'border-b border-gray-700' : ''
          }`}
        >
          <h3
            className={`text-lg font-semibold ${
              theme === 'dark' ? 'text-white' : 'text-gray-800'
            }`}
          >
            Chats
          </h3>
          {/* El botón "Nuevo Chat" ha sido eliminado */}
        </div>
        {/* Lista de chats */}
        <div className="flex-1 overflow-y-auto custom-scrollbar"> {/* Añadida la clase custom-scrollbar */}
          {chats.length === 0 ? (
            <div className="flex items-center justify-center h-full text-gray-500 p-4">
              Recuerda que debes haber Creado un Plan en el Calendario para tener acceso al Chat. No hay Chats creados.
            </div>
          ) : (
            <AnimatePresence>
              {chats.map((chat) => (
                <motion.div
                  key={chat.id || chat.name}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={chatItemVariants}
                  transition={{ duration: 0.3 }}
                  className={`flex items-center justify-between px-4 py-2 transition-colors ${
                    chat.id === currentChatId
                      ? theme === 'dark'
                        ? 'bg-gray-800'
                        : 'bg-blue-200'
                      : 'hover:bg-blue-100 dark:hover:bg-gray-700'
                  }`}
                >
                  {editingChatId === chat.id ? (
                    <div className="flex-1 flex items-center">
                      <input
                        type="text"
                        value={newChatName}
                        onChange={(e) => setNewChatName(e.target.value)}
                        className={`flex-1 p-1 border ${
                          theme === 'dark'
                            ? 'border-gray-600 bg-gray-800 text-white'
                            : 'border-gray-300 bg-white text-gray-800'
                        } rounded mr-2 focus:outline-none`}
                        autoFocus
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            submitRename(chat.id);
                          } else if (e.key === 'Escape') {
                            cancelEditing();
                          }
                        }}
                      />
                      <button
                        onClick={() => submitRename(chat.id)}
                        className="text-green-500 hover:text-green-700 mr-1 focus:outline-none"
                        aria-label="Guardar nombre"
                      >
                        <AiOutlineCheck />
                      </button>
                      <button
                        onClick={cancelEditing}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                        aria-label="Cancelar edición"
                      >
                        <AiOutlineClose />
                      </button>
                    </div>
                  ) : deletingChatId === chat.id ? (
                    <div className="flex-1 flex items-center">
                      <span
                        className={`text-sm ${
                          theme === 'dark' ? 'text-white' : 'text-gray-800'
                        }`}
                      >
                        ¿Eliminar "{chat.name}"?
                      </span>
                      <button
                        onClick={() => submitDelete(chat.id)}
                        className="text-green-500 hover:text-green-700 ml-2 focus:outline-none"
                        aria-label="Confirmar eliminación"
                      >
                        <AiOutlineCheck />
                      </button>
                      <button
                        onClick={cancelDelete}
                        className="text-red-500 hover:text-red-700 ml-1 focus:outline-none"
                        aria-label="Cancelar eliminación"
                      >
                        <AiOutlineClose />
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => handleSelectChat(chat.id)}
                        className={`flex-1 text-left rounded px-2 py-1 transition-colors ${
                          chat.id === currentChatId
                            ? ''
                            : 'hover:bg-blue-200 dark:hover:bg-gray-700'
                        }`}
                      >
                        <span
                          className={`${
                            theme === 'dark' ? 'text-white' : 'text-gray-800'
                          }`}
                        >
                          {chat.name}
                        </span>
                      </button>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => startEditing(chat.id, chat.name)}
                          className="text-yellow-500 hover:text-yellow-700 focus:outline-none"
                          aria-label="Renombrar chat"
                        >
                          <AiOutlineEdit />
                        </button>
                        <button
                          onClick={() => confirmDelete(chat.id)}
                          className="text-red-500 hover:text-red-700 focus:outline-none"
                          aria-label="Eliminar chat"
                        >
                          <AiOutlineDelete />
                        </button>
                      </div>
                    </>
                  )}
                </motion.div>
              ))}
            </AnimatePresence>
          )}
        </div>
      </div>
    );
  }
);

ChatHistory.propTypes = {
  chats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      messages: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          sender: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  currentChatId: PropTypes.string,
  handleSelectChat: PropTypes.func.isRequired,
  handleRenameChat: PropTypes.func.isRequired,
  handleDeleteChat: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ChatHistory.defaultProps = {
  currentChatId: '',
};

export default ChatHistory;
