// src/components/TransactionsList.jsx
import React, { useState, useEffect } from 'react';
import { useTheme } from '../ThemeContext';
import '../Sidebar/TransactionList.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TransactionsList = () => {
  const { theme } = useTheme();
  const [isListVisible, setIsListVisible] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [expandedTransactionId, setExpandedTransactionId] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/transactions`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('Error al obtener las transacciones');
        }
        const data = await response.json();
        setTransactions(data.transactions);
      } catch (error) {
        console.error('Error al obtener las transacciones:', error);
      }
    };

    fetchTransactions();
  }, []);

  const toggleListVisibility = () => {
    setIsListVisible(prev => !prev);
    if (isListVisible) {
      setExpandedTransactionId(null);
    }
  };

  const handleItemClick = (transaction) => {
    const transactionId = transaction._id || transaction.id;
    setExpandedTransactionId(prev =>
      prev === transactionId ? null : transactionId
    );
  };

  return (
    <div className={`transactions-container ${theme}`}>
      <h3 className="transactions-title">Transacciones</h3>
      <button onClick={toggleListVisibility} className="toggle-button">
        {isListVisible ? 'Ocultar Transacciones' : 'Mostrar Transacciones'}
      </button>

      {isListVisible && (
        <ul className="transactions-list">
          {transactions.map((transaction) => {
            const transactionId = transaction._id || transaction.id;
            const isItemExpanded = expandedTransactionId === transactionId;
            return (
              <li key={transactionId} className="transaction-item">
                <div
                  onClick={() => handleItemClick(transaction)}
                  className="transaction-summary"
                >
                  <span
                    className="transaction-reference"
                    title={transaction.reference}
                  >
                    {transaction.reference}
                  </span>
                  <span className="transaction-date">
                    {transaction.finalizedAt
                      ? new Date(transaction.finalizedAt).toLocaleDateString()
                      : transaction.date}
                  </span>
                  <span className="transaction-status">
                    {transaction.status}
                  </span>
                  <span
                    className={`transaction-icon ${isItemExpanded ? 'expanded' : ''}`}
                  >
                    &#9654;
                  </span>
                </div>
                <div className={`transaction-details ${isItemExpanded ? 'open' : ''}`}>
                  <div className="details-content">
                    <p>
                      <strong>Referencia:</strong> {transaction.reference}
                    </p>
                    <p>
                      <strong>Fecha:</strong>{' '}
                      {transaction.finalizedAt
                        ? new Date(transaction.finalizedAt).toLocaleString()
                        : transaction.date}
                    </p>
                    <p>
                      <strong>Estado:</strong> {transaction.status}
                    </p>
                    {transaction.customerEmail && (
                      <p>
                        <strong>Email:</strong> {transaction.customerEmail}
                      </p>
                    )}
                    {transaction.paymentMethodType && (
                      <p>
                        <strong>Método de Pago:</strong> {transaction.paymentMethodType}
                      </p>
                    )}
                    {transaction.amountInCents && (
                      <p>
                        <strong>Monto:</strong>{' '}
                        {(transaction.amountInCents / 100).toFixed(2)} {transaction.currency}
                      </p>
                    )}
                    {transaction.fullName && (
                      <p>
                        <strong>Nombre:</strong> {transaction.fullName}
                      </p>
                    )}
                    {transaction.phoneNumber && (
                      <p>
                        <strong>Teléfono:</strong> {transaction.phoneNumber}
                      </p>
                    )}
                    {transaction.legalId && (
                      <p>
                        <strong>Identificación:</strong> {transaction.legalId}
                      </p>
                    )}
                    {transaction.details && (
                      <p>
                        <strong>Detalles:</strong> {transaction.details}
                      </p>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default TransactionsList;
