import React, { useRef, useEffect, useState } from 'react';
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineArrowDown,
  AiOutlinePlus,
  AiOutlineArrowUp,
  AiOutlinePicture,
} from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import ChatHistory from './ChatHistory';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CanvasComponent from './CanvasComponent';
import './ChatWindow.css';

import { usePlan } from '../contexts/PlanContext';
import { useAuth } from '../contexts/AuthContext';

const ChatWindow = ({
  handlers,
  theme,
  toggleChat,
  isSidebarOpen,
  handleToggleSidebar,
  sidebarRef,
  toggleButtonRef,
  chats,
  currentChatId,
  handleSelectChat,
  handleNewChat,
  handleRenameChat,
  handleDeleteChat,
  currentChat,
  input,
  setInput,
  handleSend,
  handleAddMessage,
  isLoading,
  canCreateNewChat,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);

  const { user } = useAuth();
  const { entries } = usePlan();

  const planType = user?.plan?.toLowerCase() || 'free';
  const isPlusUser = planType === 'plus';
  const isFreeUser = !isPlusUser;

  const today = new Date();
  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);
  const todayEntries = entries.filter((entry) => {
    const timestamp = entry.timestamp || entry.createdAt;
    if (!timestamp) return false;
    const entryDate = new Date(timestamp);
    return entryDate >= startOfDay && entryDate <= endOfDay;
  });
  const entriesCountToday = todayEntries.length;
  const freeLimit = 10;
  const entriesRemaining = freeLimit - entriesCountToday;
  const hasReachedLimit = entriesCountToday >= freeLimit;

  // Variantes de animación con efecto rebote para el chat
  const chatVariants = {
    hidden: { opacity: 0, scale: 0.9, y: 20 },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 260, damping: 20 },
    },
    exit: { opacity: 0, scale: 0.9, y: 20, transition: { duration: 0.2 } },
  };

  // Variante para la sidebar con transición suave
  const sidebarVariants = {
    hidden: { x: -300 },
    visible: { x: 0, transition: { type: 'spring', stiffness: 300, damping: 30 } },
    exit: { x: -300, transition: { duration: 0.3 } },
  };

  useEffect(() => {
    if (isAtBottom && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentChat ? currentChat.messages : null, isAtBottom]);

  useEffect(() => {
    const messageContainer = messageContainerRef.current;
    const handleScroll = () => {
      if (!messageContainer) return;
      const { scrollTop, scrollHeight, clientHeight } = messageContainer;
      const atBottom = scrollHeight - scrollTop <= clientHeight + 10;
      setIsAtBottom(atBottom);
    };

    if (messageContainer) {
      messageContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messageContainer) {
        messageContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploadError(null);

    if (!currentChatId) {
      setUploadError("No hay un chat seleccionado.");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);

    try {
      const res = await fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        { method: "POST", body: formData }
      );
      if (!res.ok) throw new Error("Error al subir la imagen");
      const data = await res.json();
      const secureUrl = data.secure_url;

      const saveRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/images`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ chat_id: currentChatId, url: secureUrl }),
      });
      if (!saveRes.ok) throw new Error("Error al guardar la imagen en la base de datos");

      const describeRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/images/describe`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: secureUrl }),
      });
      if (!describeRes.ok) throw new Error("Error al obtener la descripción del modelo");
      const descriptionData = await describeRes.json();

      console.log("Descripción de la imagen:", descriptionData);

      try {
        let jsonStr = descriptionData.description;
        if (jsonStr.startsWith("\\json") || jsonStr.startsWith("```json")) {
          jsonStr = jsonStr.replace(/^\\json\s*|^```json\s*/i, "");
          jsonStr = jsonStr.replace(/```$/i, "").trim();
        }
        const cardData = JSON.parse(jsonStr);

        const assistantMessage = {
          id: Date.now(),
          sender: 'assistant',
          text: "Aquí está tu tarjeta",
          cardData: cardData,
        };

        if (handleAddMessage && typeof handleAddMessage === 'function') {
          handleAddMessage(assistantMessage);
        } else {
          currentChat.messages.push(assistantMessage);
        }
      } catch (error) {
        setUploadError("Error al parsear la descripción: " + error.message);
      }

    } catch (err) {
      setUploadError(err.message);
    } finally {
      setUploading(false);
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const renderInputArea = () => {
    if (hasReachedLimit) {
      return (
        <div className="p-4 text-center text-red-500">
          Has alcanzado el límite diario de {freeLimit} registros. Por favor, vuelve mañana.
        </div>
      );
    }
    return (
      <div className={`p-4 flex items-center border-t ${theme === 'dark' ? 'bg-gray-800 border-gray-700' : 'bg-gray-50 border-gray-200'}`}>
        <div className="flex-1 flex items-center space-x-3">
          <textarea
            id="chat-input"
            ref={inputRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={`flex-1 p-3 border rounded-lg focus:outline-none resize-none max-h-40 custom-scrollbar transition-colors duration-200 ${
              theme === 'dark'
                ? 'bg-gray-700 text-white placeholder-gray-400 border-transparent focus:border-blue-400'
                : 'bg-white text-gray-800 placeholder-gray-500 border-gray-300 focus:border-blue-500'
            }`}
            placeholder="Escribe tu mensaje..."
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            disabled={isLoading || uploading}
            rows={1}
          />
          <motion.button
            onClick={handleSend}
            whileHover={{ scale: 1.15, rotate: 3 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            className={`bg-gradient-to-r from-blue-500 to-purple-600 text-white w-10 h-10 rounded-lg flex items-center justify-center transition-shadow duration-200 ${
              isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl'
            }`}
            aria-label="Enviar mensaje"
            title="Enviar mensaje"
            disabled={isLoading || uploading}
          >
            <AiOutlineArrowUp size={20} />
          </motion.button>
          <motion.button
            onClick={() => {
              if (!isFreeUser && fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
            whileHover={{ scale: isFreeUser || uploading ? 1 : 1.15 }}
            whileTap={{ scale: isFreeUser || uploading ? 1 : 0.95 }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
            className={`relative bg-green-500 text-white w-10 h-10 rounded-lg flex items-center justify-center transition-shadow duration-200 ${
              uploading || isFreeUser ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl'
            }`}
            aria-label={isFreeUser ? "Adjuntar imagen (Disponible en Plus)" : "Adjuntar imagen"}
            title={isFreeUser ? "Disponible en Plus" : "Adjuntar imagen"}
            disabled={uploading || isFreeUser}
          >
            {uploading ? (
              <FaSpinner size={20} className="animate-spin" />
            ) : (
              <AiOutlinePicture size={20} />
            )}
            {isFreeUser && (
              <span className="absolute bottom-0 right-0 bg-white text-green-500 rounded-full p-0.5">
                <AiOutlinePlus size={10} />
              </span>
            )}
          </motion.button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </div>
      </div>
    );
  };

  return (
    <motion.div
      {...handlers}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={chatVariants}
      transition={{ duration: 0.3 }}
      className={`fixed inset-0 flex flex-col transition-colors duration-500 z-50 ${
        theme === 'dark'
          ? 'bg-gradient-to-b from-gray-900 to-gray-800'
          : 'bg-gradient-to-b from-gray-100 to-gray-50'
      }`}
    >
      {/* Encabezado */}
      <div className={`relative p-4 bg-gradient-to-r ${
        theme === 'dark' ? 'from-gray-800 to-gray-700' : 'from-indigo-500 to-purple-500'
      } shadow-lg rounded-b-lg`}>
        {/* Vista móvil */}
        <div className="md:hidden flex flex-col items-center">
          <h2 className="text-white text-2xl font-bold mb-3">
            Asistente Lógico de Bienestar Avanzado (ALBA)
          </h2>
          <div className="flex justify-around w-full px-4">
            <button
              onClick={handleToggleSidebar}
              className="p-2 rounded-full hover:bg-gray-600 transition-colors duration-200"
              aria-label="Historial de chat"
              title="Historial de chat"
            >
              <AiOutlineMenu size={24} className="text-white" />
            </button>
            <button
              onClick={handleNewChat}
              className={`p-2 rounded-full bg-green-500 hover:bg-green-600 transition-colors duration-200 ${
                canCreateNewChat ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              aria-label="Nuevo chat"
              title={canCreateNewChat ? 'Nuevo chat' : 'No puedes crear un nuevo chat hasta que el último tenga al menos un mensaje.'}
              disabled={!canCreateNewChat}
            >
              <AiOutlinePlus size={24} className="text-white" />
            </button>
            <button
              onClick={toggleChat}
              className="p-2 rounded-full hover:bg-gray-600 transition-colors duration-200"
              aria-label="Cerrar chat"
              title="Cerrar chat"
            >
              <AiOutlineClose size={24} className="text-white" />
            </button>
          </div>
          {isFreeUser && (
            <div className="mt-2 text-xs text-white">
              {hasReachedLimit
                ? 'Has alcanzado el límite diario de 10 registros.'
                : `Plan Free: Te quedan ${entriesRemaining} registros hoy.`}
            </div>
          )}
          {isPlusUser && (
            <div className="mt-2 text-xs text-white">Plan Plus</div>
          )}
        </div>
        {/* Vista escritorio */}
        <div className="hidden md:flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={handleToggleSidebar}
              ref={toggleButtonRef}
              className="p-2 rounded-full hover:bg-gray-600 transition-colors duration-200"
              aria-label="Mostrar u ocultar barra lateral"
              title="Mostrar u ocultar barra lateral"
            >
              <AiOutlineMenu size={24} className="text-white" />
            </button>
            <button
              onClick={handleNewChat}
              className={`p-2 rounded-full bg-green-500 hover:bg-green-600 transition-colors duration-200 ${
                canCreateNewChat ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              aria-label="Crear nuevo chat"
              title={canCreateNewChat ? 'Crear nuevo chat' : 'No puedes crear un nuevo chat hasta que el último tenga al menos un mensaje.'}
              disabled={!canCreateNewChat}
            >
              <AiOutlinePlus size={24} className="text-white" />
            </button>
          </div>
          <h2 className="text-white text-xl font-bold flex-1 text-center px-4">
            Asistente Lógico de Bienestar Avanzado (ALBA)
          </h2>
          <button
            onClick={toggleChat}
            className="p-2 rounded-full hover:bg-gray-600 transition-colors duration-200"
            aria-label="Cerrar chat"
            title="Cerrar chat"
          >
            <AiOutlineClose size={24} className="text-white" />
          </button>
          {isFreeUser && (
            <div className="absolute bottom-2 left-0 right-0 text-center text-xs text-white">
              {hasReachedLimit
                ? 'Has alcanzado el límite diario de 10 registros.'
                : `Plan Free: Te quedan ${entriesRemaining} registros hoy.`}
            </div>
          )}
          {isPlusUser && (
            <div className="absolute bottom-2 left-0 right-0 text-center text-xs text-white">
              Plan Plus
            </div>
          )}
        </div>
      </div>

      {/* Cuerpo del Chat */}
      <div className="flex flex-1 relative overflow-hidden">
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              ref={sidebarRef}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={sidebarVariants}
              className="w-64 flex-shrink-0 bg-gray-800 h-full rounded-r-lg overflow-hidden"
            >
              <ChatHistory
                chats={chats}
                currentChatId={currentChatId}
                handleSelectChat={handleSelectChat}
                handleRenameChat={handleRenameChat}
                handleDeleteChat={handleDeleteChat}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <div className="flex-1 flex flex-col relative">
          {currentChat && currentChat.messages.length > 0 ? (
            <>
              <div
                ref={messageContainerRef}
                className={`flex-1 p-4 overflow-y-auto custom-scrollbar ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}
              >
                {currentChat.messages.map((msg, index) => (
                  <motion.div
                    key={msg.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ type: 'spring', stiffness: 260, damping: 20, delay: index * 0.05 }}
                    className={`mb-4 flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                  >
                    {msg.cardData && Object.keys(msg.cardData).length > 0 ? (
                      <div
                        className={`flex flex-col md:flex-row items-center gap-4 p-4 rounded-xl shadow-lg ${
                          msg.sender === 'user'
                            ? 'bg-gradient-to-r from-indigo-500 to-blue-500 text-white'
                            : theme === 'dark'
                            ? 'bg-gray-700 text-white'
                            : 'bg-gray-200 text-gray-800'
                        } max-w-full md:max-w-3xl`}
                      >
                        <div className="flex-1">
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {msg.text}
                          </ReactMarkdown>
                        </div>
                        <div className="w-full md:w-auto">
                          <CanvasComponent cardData={msg.cardData} />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`px-4 py-2 rounded-xl shadow-lg ${
                          msg.sender === 'user'
                            ? 'bg-gradient-to-r from-indigo-500 to-blue-500 text-white'
                            : theme === 'dark'
                            ? 'bg-gray-700 text-white'
                            : 'bg-gray-200 text-gray-800'
                        } max-w-full md:max-w-3xl`}
                      >
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {msg.text}
                        </ReactMarkdown>
                      </div>
                    )}
                  </motion.div>
                ))}

                {isLoading && (
                  <div className="mb-4 flex justify-center">
                    <div
                      className={`px-4 py-2 rounded-xl shadow-lg flex items-center ${
                        theme === 'dark'
                          ? 'bg-gray-700 text-white'
                          : 'bg-gray-200 text-gray-800'
                      } max-w-full md:max-w-3xl`}
                    >
                      <FaSpinner className="animate-spin mr-2" />
                      Procesando...
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>

              {/* Botón para ir al último mensaje */}
              {!isAtBottom && (
                <button
                  onClick={() => {
                    if (messagesEndRef.current) {
                      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="absolute bottom-24 right-4 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-200"
                  aria-label="Ir al último mensaje"
                  title="Ir al último mensaje"
                >
                  <AiOutlineArrowDown size={24} />
                </button>
              )}

              {renderInputArea()}
              {uploadError && (
                <div className="text-sm text-red-500 mt-2 text-center">{uploadError}</div>
              )}
            </>
          ) : (
            <motion.div
              className={`flex-1 flex flex-col items-center justify-center p-8 ${
                theme === 'dark' ? 'text-white' : 'text-gray-800'
              }`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <motion.img
                src="/images/tyto.svg"
                alt="tyto"
                className="mb-6 w-32 h-32"
                animate={{
                  y: [0, -40, 0],
                  scaleY: [1, 0.8, 1],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: 'loop',
                  ease: 'easeInOut',
                }}
              />
              <p className="text-center mb-6 max-w-md">
                Estoy aquí para ayudarte. ¿En qué puedo asistirte hoy?
              </p>
              <div className="w-full max-w-md px-4">
                <div className={`flex items-center rounded-lg shadow-sm overflow-hidden ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}>
                  <textarea
                    id="chat-input"
                    ref={inputRef}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    className={`flex-1 p-3 border border-transparent focus:outline-none resize-none max-h-40 custom-scrollbar transition-colors duration-200 ${
                      theme === 'dark'
                        ? 'bg-gray-700 text-white placeholder-gray-400'
                        : 'bg-white text-gray-800 placeholder-gray-500'
                    }`}
                    placeholder="Escribe tu mensaje..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSend();
                      }
                    }}
                    disabled={isLoading || uploading}
                    rows={1}
                  />
                  <motion.button
                    onClick={handleSend}
                    whileHover={{ scale: 1.15, rotate: 3 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                    className={`bg-gradient-to-r from-blue-500 to-purple-600 text-white w-10 h-10 rounded-lg flex items-center justify-center transition-shadow duration-200 ${
                      isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl'
                    }`}
                    aria-label="Enviar mensaje"
                    title="Enviar mensaje"
                    disabled={isLoading || uploading}
                  >
                    <AiOutlineArrowUp size={20} />
                  </motion.button>
                  <motion.button
                    onClick={() => {
                      if (!isFreeUser && fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                    }}
                    whileHover={{ scale: uploading || isFreeUser ? 1 : 1.15 }}
                    whileTap={{ scale: uploading || isFreeUser ? 1 : 0.95 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                    className={`relative bg-green-500 text-white w-10 h-10 rounded-lg flex items-center justify-center transition-shadow duration-200 ${
                      uploading || isFreeUser ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-xl'
                    }`}
                    aria-label={isFreeUser ? "Adjuntar imagen (Disponible en Plus)" : "Adjuntar imagen"}
                    title={isFreeUser ? "Disponible en Plus" : "Adjuntar imagen"}
                    disabled={uploading || isFreeUser}
                  >
                    {uploading ? (
                      <FaSpinner size={20} className="animate-spin" />
                    ) : (
                      <AiOutlinePicture size={20} />
                    )}
                    {isFreeUser && (
                      <span className="absolute bottom-0 right-0 bg-white text-green-500 rounded-full p-0.5">
                        <AiOutlinePlus size={10} />
                      </span>
                    )}
                  </motion.button>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ChatWindow;
