import React, { useState } from 'react';
import { useTheme } from '../ThemeContext';
import './ThemeSwitch.css';

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useTheme();
  // Estado para activar la micro-animación (efecto “dopamine burst”)
  const [animate, setAnimate] = useState(false);

  const handleToggleTheme = () => {
    toggleTheme();
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500); // efecto de 0.5s
  };

  return (
    <button
      onClick={handleToggleTheme}
      className={`theme-switch-container ${animate ? 'dopamine' : ''}`}
      aria-label="Cambiar tema"
    >
      <div className="theme-switch">
        <span className="slider">
          {theme === 'light' ? (
            // SVG para el modo claro (sol)
            <svg
              width="200"
              height="200"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <radialGradient
                  id="sun-gradient"
                  cx="50%"
                  cy="50%"
                  r="50%"
                  fx="50%"
                  fy="50%"
                >
                  <stop offset="0%" style={{ stopColor: 'yellow', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: 'orange', stopOpacity: 1 }} />
                </radialGradient>
                <linearGradient id="ray-gradient" x1="50%" y1="0%" x2="50%" y2="100%">
                  <stop offset="0%" style={{ stopColor: 'orange', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: 'transparent', stopOpacity: 0 }} />
                </linearGradient>
                <filter id="blur" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
                </filter>
              </defs>

              {/* Sol */}
              <circle cx="50" cy="50" r="20" fill="url(#sun-gradient)">
                <animate attributeName="r" values="18;22;18" dur="4s" repeatCount="indefinite" />
              </circle>

              {/* Rayos del Sol */}
              <g filter="url(#blur)">
                <line x1="50" y1="5" x2="50" y2="25" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="y2" values="20;25;20" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="50" y1="75" x2="50" y2="95" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="y2" values="80;95;80" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="5" y1="50" x2="25" y2="50" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="x2" values="20;25;20" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="75" y1="50" x2="95" y2="50" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="x2" values="80;95;80" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="15" y1="15" x2="30" y2="30" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="x2" values="25;30;25" dur="4s" repeatCount="indefinite" />
                  <animate attributeName="y2" values="25;30;25" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="70" y1="70" x2="85" y2="85" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="x2" values="80;85;80" dur="4s" repeatCount="indefinite" />
                  <animate attributeName="y2" values="80;85;80" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="15" y1="85" x2="30" y2="70" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="x2" values="25;30;25" dur="4s" repeatCount="indefinite" />
                  <animate attributeName="y2" values="75;70;75" dur="4s" repeatCount="indefinite" />
                </line>
                <line x1="70" y1="30" x2="85" y2="15" stroke="url(#ray-gradient)" strokeWidth="4">
                  <animate attributeName="x2" values="80;85;80" dur="4s" repeatCount="indefinite" />
                  <animate attributeName="y2" values="25;15;25" dur="4s" repeatCount="indefinite" />
                </line>
              </g>

              {/* Brillos alrededor del Sol */}
              <circle cx="50" cy="50" r="30" fill="none" stroke="yellow" strokeWidth="2" opacity="0.5">
                <animate attributeName="r" values="28;32;28" dur="4s" repeatCount="indefinite" />
              </circle>
              <circle cx="50" cy="50" r="40" fill="none" stroke="orange" strokeWidth="2" opacity="0.3">
                <animate attributeName="r" values="38;42;38" dur="4s" repeatCount="indefinite" />
              </circle>
              <circle cx="50" cy="50" r="50" fill="none" stroke="red" strokeWidth="2" opacity="0.1">
                <animate attributeName="r" values="48;52;48" dur="4s" repeatCount="indefinite" />
              </circle>

              {/* Nubes con animación de vaivén */}
              <g className="clouds">
                <path
                  d="M40 70 
                     C35 65 25 65 20 70 
                     C15 75 25 80 30 75 
                     C35 80 45 80 50 75 
                     C55 80 65 80 70 75 
                     C75 80 85 75 80 70 
                     C75 65 65 65 60 70 
                     Z"
                  fill="lightgrey"
                  stroke="grey"
                  strokeWidth="1"
                  opacity="0.8"
                >
                  <animate attributeName="opacity" values="0.8;0.5;0.8" dur="6s" repeatCount="indefinite" />
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="0 0;10 0;0 0"
                    keyTimes="0;0.5;1"
                    dur="20s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M70 30 
                     C65 25 55 25 50 30 
                     C45 35 55 40 60 35 
                     C65 40 75 40 80 35 
                     C85 40 95 35 90 30 
                     C85 25 75 25 70 30 
                     Z"
                  fill="lightgrey"
                  stroke="grey"
                  strokeWidth="1"
                  opacity="0.8"
                >
                  <animate attributeName="opacity" values="0.8;0.5;0.8" dur="6s" repeatCount="indefinite" />
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="0 0;-10 0;0 0"
                    keyTimes="0;0.5;1"
                    dur="20s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </svg>
          ) : (
            // SVG para el modo oscuro (luna creciente)
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <filter id="moonTexture" x="0" y="0" width="100%" height="100%">
                  <feTurbulence type="fractalNoise" baseFrequency="2" numOctaves="5" stitchTiles="stitch" />
                  <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
                  <feBlend in="SourceGraphic" in2="monoNoise" mode="multiply" />
                </filter>
                <mask id="crescentMask">
                  <rect width="100%" height="100%" fill="black" />
                  <circle cx="50" cy="50" r="45" fill="white" />
                  <circle cx="65" cy="50" r="45" fill="black" />
                </mask>
                <g id="starSparkle">
                  <circle cx="0" cy="0" r="0.5" fill="white" opacity="0.8">
                    <animate attributeName="opacity" values="0.8;1;0.8" dur="1.5s" repeatCount="indefinite" />
                    <animateTransform attributeName="transform" type="scale" values="1;1.5;1" dur="1.5s" repeatCount="indefinite" />
                  </circle>
                </g>
              </defs>

              {/* Nubes en el cielo nocturno */}
              <g id="clouds">
                <path
                  d="M40 70 
                     C35 65 25 65 20 70 
                     C15 75 25 80 30 75 
                     C35 80 45 80 50 75 
                     C55 80 65 80 70 75 
                     C75 80 85 75 80 70 
                     C75 65 65 65 60 70 
                     Z"
                  fill="lightgrey"
                  stroke="grey"
                  strokeWidth="1"
                  opacity="0.8"
                >
                  <animate attributeName="opacity" values="0.8;0.5;0.8" dur="6s" repeatCount="indefinite" />
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="0 0;2 0;0 0"
                    keyTimes="0;0.5;1"
                    dur="6s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M60 80 
                     C55 75 45 75 40 80 
                     C35 85 45 90 50 85 
                     C55 90 65 90 70 85 
                     C75 90 85 85 80 80 
                     C75 75 65 75 60 80 
                     Z"
                  fill="lightgrey"
                  stroke="grey"
                  strokeWidth="1"
                  opacity="0.7"
                >
                  <animate attributeName="opacity" values="0.7;0.4;0.7" dur="8s" repeatCount="indefinite" />
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="0 0;2 0;0 0"
                    keyTimes="0;0.5;1"
                    dur="8s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M10 90 
                     C5 85 -5 85 0 90 
                     C5 95 15 95 20 90 
                     C25 95 35 95 40 90 
                     C45 95 55 95 50 90 
                     C45 85 35 85 30 90 
                     Z"
                  fill="lightgrey"
                  stroke="grey"
                  strokeWidth="1"
                  opacity="0.6"
                >
                  <animate attributeName="opacity" values="0.6;0.3;0.6" dur="7s" repeatCount="indefinite" />
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="0 0;3 0;0 0"
                    keyTimes="0;0.5;1"
                    dur="7s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M80 10 
                     C75 5 65 5 60 10 
                     C55 15 65 20 70 15 
                     C75 20 85 20 90 15 
                     C95 20 105 15 100 10 
                     C95 5 85 5 80 10 
                     Z"
                  fill="lightgrey"
                  stroke="grey"
                  strokeWidth="1"
                  opacity="0.8"
                >
                  <animate attributeName="opacity" values="0.8;0.5;0.8" dur="9s" repeatCount="indefinite" />
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="translate"
                    values="0 0;2 0;0 0"
                    keyTimes="0;0.5;1"
                    dur="9s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>

              {/* Luna Creciente con textura */}
              <g id="crescentMoon" mask="url(#crescentMask)">
                <circle cx="50" cy="50" r="45" fill="white" filter="url(#moonTexture)" />
              </g>

              {/* Estrellas y partículas */}
              <g id="stars">
                <circle cx="15" cy="20" r="1.5" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="5s" repeatCount="indefinite" />
                </circle>
                <circle cx="85" cy="20" r="2" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="4s" repeatCount="indefinite" />
                </circle>
                <circle cx="30" cy="70" r="1" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="6s" repeatCount="indefinite" />
                </circle>
                <circle cx="70" cy="80" r="1.5" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="5.5s" repeatCount="indefinite" />
                </circle>
                <circle cx="50" cy="10" r="1.5" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="6.2s" repeatCount="indefinite" />
                </circle>
                <circle cx="90" cy="40" r="2" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="5.1s" repeatCount="indefinite" />
                </circle>
                <circle cx="25" cy="30" r="1" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="4.8s" repeatCount="indefinite" />
                </circle>
                <circle cx="75" cy="60" r="1.2" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="5.3s" repeatCount="indefinite" />
                </circle>
                <circle cx="40" cy="20" r="1" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="7s" repeatCount="indefinite" />
                </circle>
                <circle cx="60" cy="40" r="1.5" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="3.5s" repeatCount="indefinite" />
                </circle>
                <circle cx="80" cy="70" r="2" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="6.5s" repeatCount="indefinite" />
                </circle>
                <circle cx="20" cy="50" r="1.5" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="5.7s" repeatCount="indefinite" />
                </circle>
                <circle cx="55" cy="30" r="1.2" fill="white">
                  <animate attributeName="opacity" values="0;1;0.5;1;0" dur="4.9s" repeatCount="indefinite" />
                </circle>
                <use href="#starSparkle" x="20" y="20" />
                <use href="#starSparkle" x="40" y="60">
                  <animateTransform attributeName="transform" type="translate" values="0 0;1 1;0 0" keyTimes="0;0.5;1" dur="2s" repeatCount="indefinite" />
                </use>
                <use href="#starSparkle" x="70" y="30">
                  <animateTransform attributeName="transform" type="translate" values="0 0;-1 1;0 0" keyTimes="0;0.5;1" dur="3s" repeatCount="indefinite" />
                </use>
                <use href="#starSparkle" x="85" y="60">
                  <animateTransform attributeName="transform" type="translate" values="0 0;1 -1;0 0" keyTimes="0;0.5;1" dur="2.5s" repeatCount="indefinite" />
                </use>
              </g>
            </svg>
          )}
        </span>
      </div>
    </button>
  );
};

export default ThemeSwitch;
