// src/components/Sidebar.jsx
import React, { useEffect, useState } from 'react';
import { useTheme } from '../ThemeContext';
import { useAuth } from '../contexts/AuthContext';
import HealthMetricsForm from './formats/HealthMetricsForm';
import CardPaymentForm from './CardPaymentForm';
import TransactionsList from './TransactionList';
import { ReactComponent as ToggleSide } from './toggleside.svg';
import { FaGoogle, FaCreditCard, FaHeartbeat } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';

const Sidebar = () => {
  const { theme, isSidebarVisible, toggleSidebar } = useTheme();
  const { user, login, logout } = useAuth();

  // Estados para controlar los collapsibles de las secciones
  const [isPaymentsExpanded, setPaymentsExpanded] = useState(false);
  const [isMetricsExpanded, setMetricsExpanded] = useState(false);

  useEffect(() => {
    // Evita el scroll de la página principal cuando la Sidebar está abierta
    document.body.style.overflow = isSidebarVisible ? 'hidden' : '';
  }, [isSidebarVisible]);

  // Clases para el scrollbar según el theme
  const scrollbarClasses = `
    scrollbar-thin 
    ${theme === 'dark'
      ? 'scrollbar-thumb-gray-700 scrollbar-track-transparent'
      : 'scrollbar-thumb-gray-400 scrollbar-track-gray-200'
    }
    scrollbar-thumb-rounded 
    hover:scrollbar-thumb-gray-500 
    transition-colors
  `;

  // Fondo degradado del header según el theme
  const headerBg =
    theme === 'dark'
      ? 'bg-gradient-to-r from-gray-800 to-gray-600'
      : 'bg-gradient-to-r from-blue-500 to-purple-500';

  // Clase estandarizada para headers de accordion
  const accordionHeaderClasses = `flex items-center justify-between w-full px-4 py-3 ${
    theme === 'dark'
      ? 'bg-gray-800 hover:bg-gray-700'
      : 'bg-gray-200 hover:bg-gray-300'
  } transition-colors`;

  // Ícono chevron para el accordion
  const ChevronIcon = ({ isOpen }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`w-5 h-5 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );

  return (
    <>
      {/* Botón para abrir/cerrar la Sidebar */}
      <button
        className={`
          ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'} 
          p-0.5 
          rounded-full 
          fixed 
          top-2 
          left-2 
          shadow-md 
          hover:bg-opacity-80 
          transition-all 
          z-50 
          flex 
          items-center 
          justify-center
          w-10 
          h-10
        `}
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        {user ? (
          <div className="relative">
            <img
              src={user.photo}
              alt={`${user.displayName}'s profile`}
              className="w-10 h-10 rounded-full object-cover border-2 border-blue-400 hover:border-blue-300 transition-all"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-avatar.png';
              }}
            />
          </div>
        ) : (
          <ToggleSide className={`${isSidebarVisible ? 'transform rotate-180' : ''} text-blue-400`} />
        )}
      </button>

      {/* Contenedor de la Sidebar */}
      <div
        className={`
          scrollbar 
          ${scrollbarClasses}
          transform
          ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'}
          ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}
          fixed 
          top-0 
          left-0 
          w-80
          h-full 
          z-40 
          shadow-2xl 
          transition-transform 
          duration-300 
          ease-in-out 
          overflow-y-auto
        `}
      >
        {/* Header de la Sidebar */}
        <div
          className={`
            ${headerBg} 
            ${user ? 'pt-16 px-4 pb-4' : 'p-4'} 
            rounded-b-lg 
            shadow-md
          `}
        >
          {user ? (
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-bold">¡Bienvenido, {user.displayName}!</h2>
              <p className="text-sm">
                Plan:{' '}
                <span className="font-semibold">
                  {user.plan.toUpperCase()}
                </span>
                {user.plan.toLowerCase() === 'plus' && user.subscriptionExpiresAt && (
                  <span className="ml-2 text-xs text-gray-300">
                    (Válido hasta:{' '}
                    {new Date(user.subscriptionExpiresAt).toLocaleDateString('es-ES', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    })}
                    )
                  </span>
                )}
              </p>
            </div>
          ) : (
            <button
              onClick={login}
              className="flex items-center justify-center px-4 py-2 bg-white text-gray-800 rounded hover:bg-gray-200 transition-colors font-medium"
            >
              <FaGoogle className="mr-2" /> Iniciar sesión con Google
            </button>
          )}
        </div>

        <div className="px-4 pt-4 pb-2 space-y-4">
          {/* Sección de Suscripciones y Transacciones (solo si el usuario está logueado) */}
          {user && (
            <div className="border rounded-lg overflow-hidden shadow-sm">
              <button
                onClick={() => setPaymentsExpanded(!isPaymentsExpanded)}
                className={accordionHeaderClasses}
              >
                <div className="flex items-center">
                  <FaCreditCard className="mr-2" />
                  <span className="text-md font-semibold">Suscripciones y Transacciones</span>
                </div>
                <ChevronIcon isOpen={isPaymentsExpanded} />
              </button>
              {isPaymentsExpanded && (
                <div className={`px-4 py-4 space-y-4 ${
                  theme === 'dark' ? 'bg-gray-900' : 'bg-white'
                }`}>
                  {user.plan.toLowerCase() === 'free' && (
                    <section>
                      <h3 className="text-md font-semibold mb-2">Mejorar Mi Plan</h3>
                      <CardPaymentForm />
                    </section>
                  )}
                  {(user.plan.toLowerCase() === 'free' ||
                    user.plan.toLowerCase() === 'plus') && (
                    <section>
                      <h3 className="text-md font-semibold mb-2">Mis Transacciones</h3>
                      <TransactionsList />
                    </section>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Sección de Métricas de Salud */}
          <div className="border rounded-lg overflow-hidden shadow-sm">
            <button
              onClick={() => setMetricsExpanded(!isMetricsExpanded)}
              className={accordionHeaderClasses}
            >
              <div className="flex items-center">
                <FaHeartbeat className="mr-2" />
                <span className="text-md font-semibold">Mis Métricas de Salud</span>
              </div>
              <ChevronIcon isOpen={isMetricsExpanded} />
            </button>
            {isMetricsExpanded && (
              <div className={`px-4 py-4 ${
                theme === 'dark' ? 'bg-gray-900' : 'bg-white'
              }`}>
                <HealthMetricsForm />
              </div>
            )}
          </div>
        </div>

        {/* Botón de Cerrar Sesión */}
        {user && (
          <div className="px-4 py-4">
            <button
              onClick={logout}
              className="flex items-center justify-center w-full px-4 py-2 bg-white text-gray-800 rounded hover:bg-gray-200 transition-colors text-sm"
            >
              <FiLogOut className="mr-2" /> Cerrar sesión
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
