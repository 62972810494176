// src/Sidebar/formats/storage.js

/**
 * Función para parsear una cadena de fecha 'YYYY-MM-DD' o 'YYYY-MM-DDTHH:MM:SSZ' como una fecha local.
 * @param {string} dateString - Cadena de fecha en formato ISO.
 * @returns {Date|null} - Objeto Date con la hora local o null si inválida.
 */
import { parseISO, isValid } from 'date-fns';

export const parseLocalDate = (dateString) => {
  if (!dateString) return null;
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : null;
};

/**
 * Obtiene datos guardados en localStorage y parsea fechas si es necesario.
 * @param {string} key - Clave en localStorage.
 * @param {any} defaultValue - Valor por defecto si no se encuentra la clave.
 * @returns {any} - Datos parseados o valor por defecto.
 */
export const getSavedData = (key, defaultValue) => {
  const data = localStorage.getItem(key);
  if (!data) return defaultValue;
  try {
    const parsedData = JSON.parse(data);

    // Parsear fechas si el objeto lo contiene
    if (parsedData.start) {
      parsedData.start = parseLocalDate(parsedData.start);
    }
    if (parsedData.end) {
      parsedData.end = parseLocalDate(parsedData.end);
    }

    return parsedData;
  } catch (error) {
    console.error(`Error al parsear datos de ${key}:`, error);
    return defaultValue;
  }
};

/**
 * Guarda datos en localStorage, asegurando que las fechas estén en formato ISO.
 * @param {string} key - Clave en localStorage.
 * @param {any} value - Valor a guardar.
 */
export const saveData = (key, value) => {
  try {
    const dataToSave = { ...value };

    // Convertir fechas a cadenas ISO si existen
    if (dataToSave.start instanceof Date) {
      dataToSave.start = dataToSave.start.toISOString();
    }
    if (dataToSave.end instanceof Date) {
      dataToSave.end = dataToSave.end.toISOString();
    }

    localStorage.setItem(key, JSON.stringify(dataToSave));
  } catch (error) {
    console.error(`Error al guardar datos en ${key}:`, error);
  }
};

/**
 * Elimina datos de localStorage.
 * @param {string} key - Clave en localStorage.
 */
export const removeData = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error al eliminar datos de ${key}:`, error);
  }
};
