// src/Sidebar/formats/apiService.js

// === URL base de la API, usando variable de entorno definida en .env o en Vercel ===
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Intenta parsear la respuesta como JSON. Si no es JSON (e.g. es HTML),
 * imprime la respuesta cruda y lanza un Error.
 */
async function parseJsonSafe(response) {
  const rawText = await response.text();

  try {
    return JSON.parse(rawText);
  } catch (err) {
    console.error('La respuesta no es JSON. Respuesta cruda:', rawText);
    throw new Error(
      `La respuesta no es JSON. Código: ${response.status} - ${response.statusText}`
    );
  }
}

// === Funciones para manejar las métricas de salud ===

export const getHealthMetrics = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/healthmetrics`, {
      credentials: 'include',
    });

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al obtener las métricas de salud:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al obtener las métricas de salud:', error);
    throw error;
  }
};

export const saveHealthMetrics = async (updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/healthmetrics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedData),
    });

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al guardar las métricas de salud:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al guardar las métricas de salud:', error);
    throw error;
  }
};

export const deleteHealthMetrics = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/healthmetrics`, {
      method: 'DELETE',
      credentials: 'include',
    });

    if (response.ok) {
      console.log('Métricas eliminadas de la base de datos');
      return true;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al eliminar las métricas de salud:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al eliminar las métricas de salud:', error);
    throw error;
  }
};

// === Funciones para manejar los planes ===

export const getPlans = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans`, {
      credentials: 'include',
    });

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al obtener los planes:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al obtener los planes:', error);
    throw error;
  }
};

export const savePlan = async (planData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(planData),
    });

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al guardar el plan:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al guardar el plan:', error);
    throw error;
  }
};

export const updatePlan = async (planId, planData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans/${planId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(planData),
    });

    const data = await parseJsonSafe(response);

    if (!response.ok) {
      throw new Error(data.message || 'Error al actualizar el plan');
    }
    return data;
  } catch (error) {
    console.error('Error al actualizar el plan:', error);
    throw error;
  }
};

export const deletePlan = async (planId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans/${planId}`, {
      method: 'DELETE',
      credentials: 'include',
    });

    if (response.ok) {
      console.log('Plan eliminado de la base de datos');
      return true;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al eliminar el plan:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al eliminar el plan:', error);
    throw error;
  }
};

// === Funciones para manejar las entradas (Entries) ===

export const getEntries = async (planId, startDate, endDate) => {
  try {
    // Validar que startDate y endDate sean objetos Date válidos
    if (!(startDate instanceof Date) || isNaN(startDate)) {
      throw new Error('startDate es inválido o no está definido.');
    }
    if (!(endDate instanceof Date) || isNaN(endDate)) {
      throw new Error('endDate es inválido o no está definido.');
    }

    const response = await fetch(
      `${API_BASE_URL}/entries/plan/${planId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      {
        credentials: 'include',
      }
    );

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al obtener las entradas:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al obtener las entradas:', error);
    throw error;
  }
};

export const saveEntry = async (entryData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/entries`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(entryData),
    });

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al guardar la entrada:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al guardar la entrada:', error);
    throw error;
  }
};

export const updateEntry = async (entryId, updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/entries/${entryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedData),
    });

    if (response.ok) {
      const data = await parseJsonSafe(response);
      return data;
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al actualizar la entrada:', errorMessage);
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Error al actualizar la entrada:', error);
    throw error;
  }
};

export const deleteEntry = async (entryId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/entries/${entryId}`, {
      method: 'DELETE',
      credentials: 'include',
    });

    if (response.ok) {
      console.log('Entrada eliminada de la base de datos');
      return { success: true };
    } else {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      console.error('Error al eliminar la entrada:', errorMessage);
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    console.error('Error al eliminar la entrada:', error);
    return { success: false, message: error.message };
  }
};

// === Funciones para manejar los reportes ===
export const getReports = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/reports`, {
      credentials: 'include',
    });

    if (!response.ok) {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      throw new Error(errorMessage || 'Error al obtener los reportes');
    }

    const data = await parseJsonSafe(response);
    return data; // Array de reportes
  } catch (error) {
    console.error('Error al obtener los reportes:', error);
    throw error;
  }
};

export const generateReport = async (planId, prompt) => {
  try {
    const today = new Date();
    const bodyData = {
      planId,
      prompt,
      startDate: today.toISOString(),
    };

    const response = await fetch(`${API_BASE_URL}/reports/generate-report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      throw new Error(errorMessage || 'Error al generar el reporte');
    }

    const data = await parseJsonSafe(response);
    return data; // { message: '...', report: { ... } }
  } catch (error) {
    console.error('Error al generar el reporte:', error);
    throw error;
  }
};

/**
 * Llama a GET /api/reports/:id para obtener los detalles de un reporte.
 */
export const getReportById = async (reportId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reports/${reportId}`, {
      credentials: 'include',
    });

    if (!response.ok) {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      throw new Error(errorMessage || 'Error al obtener el reporte');
    }

    const data = await parseJsonSafe(response);
    return data; // Un objeto con los datos del reporte
  } catch (error) {
    console.error('Error al obtener reporte:', error);
    throw error;
  }
};

export const deleteReport = async (reportId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/reports/${reportId}`, {
      method: 'DELETE',
      credentials: 'include',
    });

    if (!response.ok) {
      let errorMessage = response.statusText;
      try {
        const errorData = await parseJsonSafe(response);
        errorMessage = errorData.message || errorMessage;
      } catch (_) {}
      throw new Error(errorMessage || 'Error al eliminar el reporte');
    }

    return true;
  } catch (error) {
    console.error('Error al eliminar el reporte:', error);
    throw error;
  }
};
