import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import MainApp from './MainApp';
import { ThemeProvider } from './Components/ThemeContext';
import { AuthProvider } from './Components/contexts/AuthContext';
import { PlanProvider } from './Components/contexts/PlanContext';
import { ChatProvider } from './Components/contexts/ChatContext';

// Configuración de react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const App = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AuthProvider>
          <PlanProvider>
            <ChatProvider>
              <MainApp isChatOpen={isChatOpen} toggleChat={toggleChat} />
            </ChatProvider>
          </PlanProvider>
        </AuthProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
