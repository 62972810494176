// src/MainApp.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // Asegúrate de tener Framer Motion instalado
import { usePlan } from './Components/contexts/PlanContext';
import { useAuth } from './Components/contexts/AuthContext';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Components/Dashboard/Dashboard';
import Footer from './Components/Footer/Footer';
import AssistantChat from './Components/assistant/AssistantChat';
import PlanMetricsModal from './PlanMetricsModal';
import HomePage from './HomePage';

const MainApp = ({ isChatOpen, toggleChat }) => {
  const { planCreated } = usePlan();
  const { user, loading } = useAuth();
  const [progress, setProgress] = useState(0);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [modalDismissed, setModalDismissed] = useState(false);

  // Si el usuario está autenticado y no tiene un plan creado, mostramos el modal
  useEffect(() => {
    if (user && !planCreated) {
      setShowPlanModal(true);
    }
  }, [user, planCreated]);

  // Simulamos un progreso mientras se carga la aplicación
  useEffect(() => {
    if (loading) {
      setProgress(0); // Reiniciamos el progreso cuando loading es true
      const interval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 1;
        });
      }, 30); // Ajusta la velocidad según lo necesites
      return () => clearInterval(interval);
    }
  }, [loading]);

  // Mientras se verifica la autenticación, mostramos la pantalla de carga interactiva
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-br from-blue-400 to-purple-600">
        <div className="text-center">
          <motion.img
            src="/images/tyto.svg"
            alt="Cargando..."
            className="w-32 h-32 mx-auto"
            animate={{
              x: [0, 50, 0, -50, 0], // Efecto de ‘bounce’ en eje X
              rotate: [0, 360, 0]    // Rotación completa y vuelve a 0
            }}
            transition={{
              duration: 2,          // Duración de la animación completa
              repeat: Infinity,     // Repetir indefinidamente
              ease: "easeInOut"     // Suaviza la animación
            }}
          />
          <p className="mt-4 text-white text-xl font-bold">
            ¡Estamos preparando una experiencia increíble! {progress}%
          </p>
        </div>
      </div>
    );
  }

  // Si no hay usuario autenticado, mostramos la HomePage
  if (!user) {
    return <HomePage />;
  }

  return (
    <>
      {showPlanModal && (
        <PlanMetricsModal
          onClose={() => {
            setShowPlanModal(false);
            setModalDismissed(true);
          }}
        />
      )}

      {modalDismissed && !planCreated && (
        <div className="fixed bottom-4 right-4 bg-yellow-200 dark:bg-yellow-700 p-4 rounded shadow-lg z-50">
          <p className="mb-2">
            Recuerda que debes completar tu plan y tus métricas para que el sitio web funcione correctamente.
          </p>
          <button
            onClick={() => {
              setShowPlanModal(true);
              setModalDismissed(false);
            }}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Guarda aquí el plan que deseas y tus métricas
          </button>
        </div>
      )}

      <div
        className="
          flex flex-col min-h-screen 
          overflow-y-auto 
          scrollbar-thin 
          scrollbar-thumb-gray-400 scrollbar-track-gray-100
          dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800
        "
      >
        {!isChatOpen && <Sidebar />}
        <Dashboard isChatOpen={isChatOpen} />
        <Footer />
        <AssistantChat isOpen={isChatOpen} toggleChat={toggleChat} />
      </div>
    </>
  );
};

export default MainApp;
