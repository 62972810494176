import { useEffect, useState, useRef } from 'react';
import useDataManagement from './useDataManagement';
import useFormValidation from './useFormValidation';
import { usePlan } from '../../contexts/PlanContext';
import {
  calculateAge,
  calculateBMI,
  calculateBMIPercentile,
  calculateBodyFat,
  calculateTDEE,
  generateSummary,
  // Se ha eliminado calculateFitnessLevel
} from './healthCalculations';

const useHealthMetrics = () => {
  const initialData = {
    // Métricas de salud
    dob: '',
    sex: 'male',
    weight: '',
    height: '',
    activityFactor: 1.2,
    neck: '',
    waist: '',
    hip: '',
    bmi: null,
    bodyFat: null,
    tdee: null,
    bmr: null,
    age: null,
    bmiPercentile: null,
    // Plan
    _planId: null,
    startDate: '',
    endDate: '',
    caloricIntake: 'recomp',
    useCreatine: false,
    planDuration: '',
  };

  const {
    formData,
    setFormData,
    isSaved,
    setIsSaved,
    handleDelete,
    saveFormData,
    user,
    error,
  } = useDataManagement(initialData);

  const { validateForm } = useFormValidation();
  const { planData } = usePlan();

  // Sincroniza los cambios del plan (planData) con el estado local del formulario
  useEffect(() => {
    if (planData) {
      setFormData((prev) => ({
        ...prev,
        // Actualiza las propiedades del plan. Ajusta según la estructura de planData.
        _planId: planData._id || planData._planId || prev._planId,
        startDate:
          planData.startDate ||
          (planData.start
            ? (typeof planData.start === 'string'
                ? planData.start.split('T')[0]
                : new Date(planData.start).toISOString().split('T')[0])
            : prev.startDate),
        endDate:
          planData.endDate ||
          (planData.end
            ? (typeof planData.end === 'string'
                ? planData.end.split('T')[0]
                : new Date(planData.end).toISOString().split('T')[0])
            : prev.endDate),
        caloricIntake: planData.caloricIntake || prev.caloricIntake,
        useCreatine:
          typeof planData.useCreatine === 'boolean'
            ? planData.useCreatine
            : prev.useCreatine,
        planDuration: planData.planDuration || prev.planDuration,
      }));
    }
  }, [planData, setFormData]);

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [visibleSVG, setVisibleSVG] = useState(null);
  const svgContainerRef = useRef(null);
  const timeoutId = useRef(null);
  const [isPlanActive, setIsPlanActive] = useState(!!formData._planId);
  const resultsRef = useRef(null);

  useEffect(() => {
    setIsPlanActive(!!formData._planId);
  }, [formData._planId]);

  useEffect(() => {
    if (formData.planDuration && typeof formData.planDuration === 'string') {
      const parsedDuration = Number(formData.planDuration);
      if (!isNaN(parsedDuration)) {
        setFormData((prev) => ({
          ...prev,
          planDuration: parsedDuration,
        }));
      }
    }
  }, [formData.planDuration, setFormData]);

  useEffect(() => {
    if (!formData.startDate) {
      const today = new Date().toISOString().split('T')[0];
      setFormData((prev) => ({ ...prev, startDate: today }));
    }
  }, [formData.startDate, setFormData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { errors, isValid, age } = validateForm(formData);
    if (!isValid) {
      setErrorMessages(errors);
      return;
    } else {
      setErrorMessages([]);
    }

    const calculatedAge = age || calculateAge(formData.dob);
    const bmiValue = calculateBMI(formData.weight, formData.height);
    const bmiPercentile = calculateBMIPercentile(
      calculatedAge,
      formData.sex,
      bmiValue
    );
    const bodyFatValue = calculateBodyFat(
      formData.sex,
      formData.weight,
      formData.height,
      calculatedAge,
      formData.neck,
      formData.waist,
      formData.hip
    );
    const { bmr, tdee } = calculateTDEE(
      formData.sex,
      formData.weight,
      formData.height,
      calculatedAge,
      formData.activityFactor
    );

    // Se ha eliminado la llamada a calculateFitnessLevel

    const start = new Date(formData.startDate);
    const msToAdd = formData.planDuration * 24 * 60 * 60 * 1000;
    const computedEnd = new Date(start.getTime() + msToAdd);
    const endDate = computedEnd.toISOString().split('T')[0];

    const updatedData = {
      ...formData,
      age: calculatedAge,
      bmi: bmiValue,
      bmiPercentile,
      bodyFat: bodyFatValue,
      tdee,
      bmr,
      endDate,
    };

    await saveFormData(updatedData);

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'planDuration' ? Number(value) : value,
    }));
    setIsSaved(false);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    setIsSaved(false);
  };

  const handleActivityFactorChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      activityFactor: newValue,
    }));
    setIsSaved(false);
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    const days = value ? Number(value) : '';
    setFormData((prev) => ({
      ...prev,
      planDuration: days,
    }));
    setIsSaved(false);
  };

  const activityLevels = [
    { label: 'Sedentario', value: 1.2 },
    { label: 'Ligera actividad', value: 1.375 },
    { label: 'Actividad moderada', value: 1.55 },
    { label: 'Alta actividad', value: 1.725 },
    { label: 'Actividad muy alta', value: 1.9 },
  ];

  const toggleSVG = (type) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setVisibleSVG(type);
    timeoutId.current = setTimeout(() => {
      setVisibleSVG(null);
      const inputField = document.getElementById(type);
      if (inputField) {
        inputField.focus();
      }
    }, 3000);
  };

  useEffect(() => {
    if (visibleSVG && svgContainerRef.current) {
      svgContainerRef.current.focus();
    }
  }, [visibleSVG]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return {
    formData,
    handleSubmit,
    handleDelete: () => handleDelete(isPlanActive),
    handleInputChange,
    handleCheckboxChange,
    handleActivityFactorChange,
    handleDurationChange,
    errorMessages,
    isSaved,
    isPlanActive,
    showAlert,
    activityLevels,
    generateSummary,
    resultsRef,
    visibleSVG,
    toggleSVG,
    svgContainerRef,
    error,
  };
};

export default useHealthMetrics;
