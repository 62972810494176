// src/components/ContributionGraph.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTheme } from '../ThemeContext';
import { usePlan } from '../contexts/PlanContext';
import { 
  startOfWeek, 
  addDays, 
  format, 
  parseISO, 
  startOfMonth, 
  endOfMonth, 
  getYear 
} from 'date-fns';

const ContributionGraph = () => {
  const { theme } = useTheme();
  const { entries, planData } = usePlan();
  const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
  const [visibleStart, setVisibleStart] = useState(0);
  const [visibleEnd, setVisibleEnd] = useState(0);
  const scrollContainerRef = useRef(null);
  const [selectedType, setSelectedType] = useState('Total');

  // Para mostrar información en móviles al tocar una celda
  const [activeDay, setActiveDay] = useState(null);

  const tiposEntradas = [
    { key: 'Total', label: 'Total', class: 'from-rainbow-400 to-rainbow-600' },
    { key: 'Workout', label: 'Ejercicio', class: 'from-green-400 to-green-600' },
    { key: 'Food', label: 'Alimentación', class: 'from-orange-400 to-orange-600' },
    { key: 'Mood', label: 'Estado de Ánimo', class: 'from-blue-400 to-blue-600' },
    { key: 'State', label: 'Estado Físico', class: 'from-purple-400 to-purple-600' },
  ];

  const gradientMap = {
    'Total': { start: '168, 85, 247', end: '139, 92, 246' },
    'Workout': { start: '77, 222, 156', end: '16, 185, 129' },
    'Food': { start: '251, 146, 60', end: '234, 88, 12' },
    'Mood': { start: '96, 165, 250', end: '59, 130, 246' },
    'State': { start: '251, 191, 36', end: '245, 158, 11' },
  };

  const generateWeeksData = (startDate, endDate, counts) => {
    const weeks = [];
    let currentDate = startOfWeek(startDate, { weekStartsOn: 0 });

    while (currentDate <= endDate) {
      const week = { days: [] };
      for (let i = 0; i < 7; i++) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        week.days.push({
          date: new Date(currentDate),
          count: counts[dateStr] || 0,
        });
        currentDate = addDays(currentDate, 1);
      }
      weeks.push(week);
    }
    return weeks;
  };

  const countsByDate = useMemo(() => {
    const counts = {};
    entries.forEach(entry => {
      if (selectedType === 'Total' || entry.type.toLowerCase() === selectedType.toLowerCase()) {
        const date = parseISO(entry.timestamp);
        const dateStr = format(date, 'yyyy-MM-dd');
        counts[dateStr] = (counts[dateStr] || 0) + 1;
      }
    });
    return counts;
  }, [entries, selectedType]);

  const totalCount = useMemo(() => {
    return Object.values(countsByDate).reduce((acc, val) => acc + val, 0);
  }, [countsByDate]);

  const graphData = useMemo(() => {
    let startDate;
    let endDate;
    if (planData) {
      startDate = startOfWeek(startOfMonth(planData.start), { weekStartsOn: 0 });
      endDate = addDays(endOfMonth(planData.end), 6 - endOfMonth(planData.end).getDay());
    } else {
      endDate = new Date();
      startDate = startOfWeek(startOfMonth(addDays(endDate, -365)), { weekStartsOn: 0 });
    }
    return generateWeeksData(startDate, endDate, countsByDate);
  }, [planData, countsByDate]);

  const monthRanges = useMemo(() => {
    const ranges = [];
    let currentMonth = null;
    let startWeekIndex = 0;
    graphData.forEach((week, index) => {
      const weekMonth = week.days[0]?.date?.getMonth();
      if (weekMonth !== currentMonth) {
        if (currentMonth !== null) {
          ranges.push({
            month: currentMonth,
            startWeek: startWeekIndex,
            endWeek: index - 1,
          });
        }
        currentMonth = weekMonth;
        startWeekIndex = index;
      }
    });
    if (currentMonth !== null) {
      ranges.push({
        month: currentMonth,
        startWeek: startWeekIndex,
        endWeek: graphData.length - 1,
      });
    }
    return ranges;
  }, [graphData]);

  const yearRanges = useMemo(() => {
    const ranges = [];
    let currentYear = null;
    let startWeekIndex = 0;
    graphData.forEach((week, index) => {
      const weekYear = getYear(week.days[0]?.date);
      if (weekYear !== currentYear) {
        if (currentYear !== null) {
          ranges.push({
            year: currentYear,
            startWeek: startWeekIndex,
            endWeek: index - 1,
          });
        }
        currentYear = weekYear;
        startWeekIndex = index;
      }
    });
    if (currentYear !== null) {
      ranges.push({
        year: currentYear,
        startWeek: startWeekIndex,
        endWeek: graphData.length - 1,
      });
    }
    return ranges;
  }, [graphData]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const handleScroll = () => {
      const scrollLeft = container.scrollLeft;
      const containerWidth = container.clientWidth;
      const weekWidth = 24;
      const start = Math.floor(scrollLeft / weekWidth);
      const end = Math.ceil((scrollLeft + containerWidth) / weekWidth);
      setVisibleStart(Math.max(0, start));
      setVisibleEnd(Math.min(graphData.length - 1, end));
    };
    container.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => container.removeEventListener('scroll', handleScroll);
  }, [graphData.length]);

  const getColorStyle = (count) => {
    const gradient = gradientMap[selectedType];
    if (!gradient) return {};
    const opacity = count > 0 ? Math.min((count * 0.2) + 0.2, 1) : 0.1;
    return {
      background: `linear-gradient(135deg, rgba(${gradient.start}, ${opacity}) 0%, rgba(${gradient.end}, ${opacity * 0.8}) 100%)`,
      boxShadow: count > 2 ? `0 2px 8px -1px rgba(${gradient.end}, 0.3)` : 'none',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    };
  };

  const formatDate = (date) => format(date, 'dd/MM/yyyy');

  return (
    <div 
      className={`w-full p-6 rounded-xl shadow-xl
        ${theme === 'dark' ? 'bg-gray-900 text-gray-300' : 'bg-white text-gray-800'} 
        transition-all duration-300`}
    >
      {/* Selector de tipo */}
      <div className="mb-4 relative">
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className={`w-full p-3 rounded-lg border appearance-none
            ${theme === 'dark' 
              ? 'bg-gray-800 border-gray-700 focus:border-blue-500' 
              : 'bg-gray-50 border-gray-200 focus:border-blue-400'}
            focus:ring-2 focus:ring-opacity-50 transition-all
            ${gradientMap[selectedType] 
              ? `focus:ring-${selectedType.toLowerCase()}-300` 
              : 'focus:ring-blue-300'}
            cursor-pointer pr-10`}
        >
          {tiposEntradas.map((tipo) => (
            <option 
              key={tipo.key} 
              value={tipo.key}
              className={`bg-gradient-to-r ${tipo.class} text-white`}
            >
              {tipo.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3">
          <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>

      {/* Muestra el total de registros */}
      <div className="mb-6 text-lg font-semibold">
        Total de registros: {totalCount}
      </div>

      {/* Contenedor principal: columna para etiquetas de días y gráfico */}
      <div className="flex">
        {/* Columna para etiquetas de días con padding-top para bajarlas dos filas */}
        <div className="flex flex-col gap-1 mr-2 pt-8">
          {daysOfWeek.map((dayLabel) => (
            <div key={dayLabel} className="text-xs font-medium text-center" style={{ height: '16px', lineHeight: '16px' }}>
              {dayLabel}
            </div>
          ))}
        </div>

        {/* Gráfico en contenedor scrollable */}
        <div 
          className="overflow-x-auto pb-2 scrollbar-thin scrollbar-thumb-rounded"
          ref={scrollContainerRef}
        >
          <div style={{ width: `${graphData.length * 24}px`, position: 'relative' }}>
            {/* Etiquetas de Año */}
            <div className="absolute top-0 left-0 right-0 h-4 flex">
              {yearRanges
                .filter(range => range.startWeek <= visibleEnd && range.endWeek >= visibleStart)
                .map((range, index) => {
                  const left = range.startWeek * 24;
                  const width = (range.endWeek - range.startWeek + 1) * 24;
                  return (
                    <div
                      key={`year-${index}`}
                      className="text-xs absolute text-center min-w-[40px] font-semibold"
                      style={{ left: `${left}px`, width: `${width}px` }}
                    >
                      {range.year}
                    </div>
                  );
                })}
            </div>

            {/* Etiquetas de Mes */}
            <div className="absolute top-5 left-0 right-0 h-4 flex">
              {monthRanges
                .filter(range => range.startWeek <= visibleEnd && range.endWeek >= visibleStart)
                .map((range, index) => {
                  const left = range.startWeek * 24;
                  const width = (range.endWeek - range.startWeek + 1) * 24;
                  return (
                    <div
                      key={`month-${index}`}
                      className="text-xs absolute text-center min-w-[40px] font-medium"
                      style={{ left: `${left}px`, width: `${width}px` }}
                    >
                      {months[range.month]}
                    </div>
                  );
                })}
            </div>

            {/* Espacio para las etiquetas */}
            <div className="h-10"></div>

            {/* Celdas del gráfico */}
            <div className="flex">
              {graphData.map((week, weekIndex) => (
                <div key={weekIndex} className="flex flex-col gap-1 mr-2">
                  {week.days.map((day, dayIndex) => (
                    <div
                      key={`${weekIndex}-${dayIndex}`}
                      className="w-4 h-4 rounded-sm cursor-pointer hover:scale-110 transition-transform duration-300"
                      style={getColorStyle(day.count)}
                      title={`${day.count} actividades el ${formatDate(day.date)}`}
                      onClick={() => setActiveDay({ count: day.count, date: day.date })}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Tooltip para móviles: muestra información del día seleccionado */}
      {activeDay && (
  <div
    className={`mt-4 p-3 rounded shadow text-sm text-center ${
      theme === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-800'
    }`}
  >
    <p>
      {activeDay.count} {activeDay.count === 1 ? 'actividad' : 'actividades'} el {formatDate(activeDay.date)}
    </p>
    <button
      className="mt-2 text-blue-500 hover:underline text-xs"
      onClick={() => setActiveDay(null)}
    >
      Cerrar
    </button>
  </div>
)}


      {/* Leyenda */}
      <div className="flex items-center gap-4 mt-6 flex-wrap">
        <span className="text-sm font-medium">
          {tiposEntradas.find(t => t.key === selectedType)?.label}
        </span>
        <div className="flex items-center gap-2">
          <span className="text-xs text-gray-500">Menos</span>
          {[0, 1, 2, 3, 4].map((count) => (
            <div
              key={count}
              className="w-5 h-5 rounded-sm transform hover:scale-125 transition-all"
              style={{
                background: `linear-gradient(135deg, rgba(${gradientMap[selectedType].start}, ${count * 0.2 + 0.2}) 0%, rgba(${gradientMap[selectedType].end}, ${count * 0.2 + 0.2}) 100%)`,
                opacity: count > 0 ? 0.8 : 0.3
              }}
            />
          ))}
          <span className="text-xs text-gray-500">Más</span>
        </div>
      </div>
    </div>
  );
};

export default ContributionGraph;
