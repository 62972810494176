import React, { useState } from 'react';
import { usePlan } from './Components/contexts/PlanContext';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const predefinedDurations = [
  { label: '1 semana', value: 7 },
  { label: '2 semanas', value: 14 },
  { label: '1 mes', value: 30 },
  { label: '2 meses', value: 60 },
  { label: '3 meses', value: 90 },
];

const PlanMetricsModal = ({ onClose }) => {
  const {
    caloricIntake,
    setCaloricIntake,
    useCreatine,
    setUseCreatine,
    handleCreatePlan,
    planData,
    updatePlan,
  } = usePlan();

  // Estados locales para los datos del plan
  const [localCaloricIntake, setLocalCaloricIntake] = useState(caloricIntake);
  const [localUseCreatine, setLocalUseCreatine] = useState(useCreatine);
  const [localStartDate, setLocalStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [localPlanDuration, setLocalPlanDuration] = useState(30);

  // Estado para sección colapsable de datos del plan
  const [isPlanOpen, setIsPlanOpen] = useState(true);
  const togglePlanSection = () => setIsPlanOpen((prev) => !prev);

  const handleSave = async () => {
    // Actualizamos las preferencias del plan
    setCaloricIntake(localCaloricIntake);
    setUseCreatine(localUseCreatine);

    // Calculamos la fecha de fin a partir de la fecha de inicio y la duración del plan
    const startDateObj = new Date(localStartDate);
    const msToAdd = localPlanDuration * 24 * 60 * 60 * 1000;
    const endDateObj = new Date(startDateObj.getTime() + msToAdd);

    try {
      if (planData && planData._id) {
        // Actualizamos el plan existente con solo los datos del plan
        await updatePlan(planData._id, {
          caloricIntake: localCaloricIntake,
          useCreatine: localUseCreatine,
          start: startDateObj.toISOString(),
          end: endDateObj.toISOString(),
        });
      } else {
        // Creamos un nuevo plan sin datos de health metrics
        await handleCreatePlan({
          caloricIntake: localCaloricIntake,
          useCreatine: localUseCreatine,
          start: startDateObj.toISOString(),
          end: endDateObj.toISOString(),
        });
      }
      onClose();
    } catch (error) {
      console.error('Error al guardar el plan:', error);
      // Aquí podrías gestionar el error mostrando un mensaje al usuario
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="relative bg-gradient-to-br from-white to-indigo-50 dark:from-gray-800 dark:to-indigo-900 rounded-xl p-8 shadow-2xl max-w-2xl w-full transform transition-all duration-300 overflow-y-auto max-h-full">
        {/* Botón de cierre */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white"
        >
          <FaTimes size={22} />
        </button>

        {/* Encabezado motivador */}
        <h2 className="text-4xl font-extrabold mb-2 text-center text-indigo-600">
          ¡Transforma tu Vida!
        </h2>
        <p className="text-center text-lg text-gray-700 mb-8">
          Estás a punto de dar el primer paso hacia una versión más fuerte y saludable de ti.
        </p>

        {/* Sección: Datos del Plan */}
        <div className="mb-6 border-b pb-4">
          <button
            type="button"
            onClick={togglePlanSection}
            className="w-full flex justify-between items-center text-2xl font-semibold text-indigo-600 dark:text-indigo-300 focus:outline-none"
            aria-expanded={isPlanOpen}
          >
            <span>Datos del Plan</span>
            <span>{isPlanOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
          </button>
          {isPlanOpen && (
            <div className="mt-6 space-y-6">
              <div>
                <label className="block text-lg font-medium mb-1">Objetivo del Plan:</label>
                <select
                  value={localCaloricIntake}
                  onChange={(e) => setLocalCaloricIntake(e.target.value)}
                  className="w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                >
                  <option value="recomp">Recomposición</option>
                  <option value="volum">Volumen</option>
                  <option value="defin">Definición</option>
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={localUseCreatine}
                  onChange={(e) => setLocalUseCreatine(e.target.checked)}
                  id="useCreatine"
                  className="mr-3 w-5 h-5"
                />
                <label htmlFor="useCreatine" className="text-lg font-medium">
                  ¿Incluir Creatina en el plan?
                </label>
              </div>
              <div>
                <label className="block text-lg font-medium mb-1">Fecha de inicio:</label>
                <input
                  type="date"
                  value={localStartDate}
                  onChange={(e) => setLocalStartDate(e.target.value)}
                  className="w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                />
              </div>
              <div>
                <label className="block text-lg font-medium mb-1">Duración del Plan:</label>
                <select
                  value={localPlanDuration}
                  onChange={(e) => setLocalPlanDuration(Number(e.target.value))}
                  className="w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                >
                  <option value="" disabled>
                    Selecciona la duración
                  </option>
                  {predefinedDurations.map((d) => (
                    <option key={d.value} value={d.value}>
                      {d.label}
                    </option>
                  ))}
                </select>
                <p className="text-sm text-gray-500 mt-1">
                  La fecha de fin se calculará automáticamente.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Botones de acción */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSave}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-md transition-colors duration-300 shadow-lg"
          >
            Guardar y Empezar
          </button>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-white transition-colors duration-300 font-semibold py-3 px-6"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanMetricsModal;
