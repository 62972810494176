import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '../ThemeContext';
import { FaTrash, FaSave, FaCheck } from 'react-icons/fa';
import { usePlan } from '../contexts/PlanContext';

const CanvasComponent = ({ cardData }) => {
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const { theme } = useTheme();
  const { planId, addEntry, updateEntry } = usePlan();

  const [cards, setCards] = useState([]);
  const [notification, setNotification] = useState({ message: '', type: '' });

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.parentElement.clientWidth;
    canvas.height = canvas.parentElement.clientHeight;

    const ctx = canvas.getContext('2d');
    setContext(ctx);
    // Dibujar la cuadrícula de fondo según el tema
    drawGrid(ctx, canvas.width, canvas.height, theme);
  }, [theme]);

  useEffect(() => {
    if (cardData && Object.keys(cardData).length > 0) {
      const newCard = createCardFromData(cardData);
      setCards((prevCards) => [...prevCards, newCard]);
    }
  }, [cardData]);

  const drawGrid = (ctx, width, height, theme) => {
    const gridSize = 20;
    const dotRadius = 1;
    ctx.fillStyle = theme === 'dark' ? '#0d0d0d' : '#f9f9f9';
    ctx.fillRect(0, 0, width, height);
    ctx.fillStyle = theme === 'dark' ? '#1a1a1a' : '#e0e0e0';
    for (let x = 0; x < width; x += gridSize) {
      for (let y = 0; y < height; y += gridSize) {
        ctx.beginPath();
        ctx.arc(x, y, dotRadius, 0, Math.PI * 2);
        ctx.fill();
      }
    }
  };

  // Formato de fecha para inputs datetime-local
  const formatDateForInput = (date) => {
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return localDate.toISOString().slice(0, 16);
  };

  // Parsear fecha y hora en formato datetime-local como hora local
  const parseDateTimeLocal = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  };

  // Crear una tarjeta basándose en la información recibida
  const createCardFromData = (data) => {
    const now = new Date();
    const formattedDate = formatDateForInput(now);
    let newCard = {
      id: Date.now(),
      type: data.tipo || 'Alimentación',
      title: data.titulo || '',
      date: formattedDate,
      position: { x: 0, y: 0 },
      saved: false,
      saving: false,
    };

    if (newCard.type === 'Ejercicio') {
      newCard.duration = data.datos?.duracion || '';
      newCard.caloriesBurned = data.datos?.caloriasQuemadas || '';
    } else if (newCard.type === 'Alimentación') {
      newCard.calories = data.datos?.calorias || '';
      newCard.proteins = data.datos?.proteinas || '';
      newCard.carbs = data.datos?.carbohidratos || '';
      newCard.sodium = data.datos?.sodio || '';
      newCard.fats = data.datos?.grasas || '';
    } else if (
      newCard.type === 'Estado de Ánimo' ||
      newCard.type === 'Estado Físico' ||
      newCard.type === 'Estado de Salud'
    ) {
      newCard.description = data.datos?.descripcion || '';
    }
    return newCard;
  };

  // Actualizar campos de una tarjeta por id
  const updateCard = (id, field, value) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, [field]: value } : card
      )
    );
  };

  // Eliminar tarjeta
  const removeCard = (id) => {
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };

  // Mapeo de tipos de tarjetas a tipos de entrada en la API
  const typeMapping = {
    Ejercicio: 'workout',
    Alimentación: 'food',
    'Estado de Ánimo': 'mood',
    'Estado Físico': 'state',
    'Estado de Salud': 'state',
  };

  // Guardar o actualizar tarjeta en la base de datos
  const handleSaveCard = async (cardId) => {
    const card = cards.find((c) => c.id === cardId);
    if (!card || card.saved || card.saving) return;

    updateCard(cardId, 'saving', true);
    if (!planId) {
      setNotification({
        message: 'No hay un plan activo. Por favor, crea un plan antes de guardar.',
        type: 'error',
      });
      updateCard(cardId, 'saving', false);
      return;
    }

    const entryType = typeMapping[card.type];
    let data = {};
    if (entryType === 'workout') {
      data = {
        workoutType: card.title,
        duration: Number(card.duration),
        caloriesBurned: Number(card.caloriesBurned),
      };
    } else if (entryType === 'food') {
      data = {
        food: card.title,
        nutritionDetails: {
          calories: card.calories,
          protein: card.proteins,
          carbohydrates: card.carbs,
          fat: card.fats,
          sodium: card.sodium,
        },
      };
    } else if (entryType === 'mood' || entryType === 'state') {
      data = { [entryType]: card.description };
    }

    const timestamp = parseDateTimeLocal(card.date);
    const entry = {
      type: entryType,
      data: data,
      icons: [entryType],
      timestamp: timestamp,
    };

    try {
      if (card.saved) {
        await updateEntry({ id: card.id, ...entry });
        setNotification({ message: 'Entrada actualizada exitosamente.', type: 'success' });
      } else {
        await addEntry(entry);
        setNotification({ message: 'Entrada guardada exitosamente.', type: 'success' });
      }
      updateCard(cardId, 'saved', true);
      updateCard(cardId, 'saving', false);
    } catch (error) {
      updateCard(cardId, 'saving', false);
      console.error('Error al guardar la entrada:', error);
      setNotification({
        message: 'Error al guardar la entrada. Por favor, intenta nuevamente.',
        type: 'error',
      });
    }
  };

  // Estilos compactos y minimalistas
  const inputClassName =
    theme === 'dark'
      ? 'bg-gray-800 text-white p-1 border border-gray-700 w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-xs'
      : 'bg-white text-black p-1 border border-gray-300 w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 text-xs';

  const textareaClassName =
    theme === 'dark'
      ? 'bg-gray-800 text-white p-1 border border-gray-700 w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 resize-none h-12 text-xs'
      : 'bg-white text-black p-1 border border-gray-300 w-full rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 resize-none h-12 text-xs';

  const cardClassName =
    theme === 'dark'
      ? 'bg-gradient-to-br from-gray-900 to-gray-800 p-2 border border-gray-700 shadow rounded-md'
      : 'bg-gradient-to-br from-white to-gray-100 p-2 border border-gray-300 shadow rounded-md';

  const labelClassName = 'block text-xs font-medium mb-0.5';

  return (
    <div className="canvas-container flex flex-col items-center w-full h-full relative">
      {notification.message && (
        <div
          className={`p-1 mb-1 mt-2 rounded-md text-center max-w-lg mx-auto ${
            notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          } text-xs`}
        >
          {notification.message}
        </div>
      )}

      <div className="canvas-wrapper w-full h-full relative flex-1">
        <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />

        {cards.map((card) => (
          <div
            key={card.id}
            style={{ left: card.position.x, top: card.position.y, position: 'absolute' }}
            className={`${cardClassName} w-60 md:w-64`}
          >
            <div className="flex justify-between items-center mb-1">
              <h3 className="text-sm font-semibold text-blue-500 truncate">{card.type}</h3>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  removeCard(card.id);
                }}
                className="text-gray-400 hover:text-red-500 transition-colors text-xs"
                title="Eliminar"
              >
                <FaTrash />
              </button>
            </div>

            <div className="space-y-1">
              <div>
                <label className={labelClassName}>Título:</label>
                <input
                  type="text"
                  value={card.title}
                  onChange={(e) => updateCard(card.id, 'title', e.target.value)}
                  placeholder={`Título de ${card.type}`}
                  className={inputClassName}
                />
              </div>

              <div>
                <label className={labelClassName}>Fecha y hora:</label>
                <input
                  type="datetime-local"
                  value={card.date}
                  onChange={(e) => updateCard(card.id, 'date', e.target.value)}
                  className={inputClassName}
                />
              </div>

              {card.type === 'Ejercicio' && (
                <div className="grid grid-cols-2 gap-1">
                  <div>
                    <label className={labelClassName}>Dur. (min):</label>
                    <input
                      type="number"
                      value={card.duration}
                      onChange={(e) => updateCard(card.id, 'duration', e.target.value)}
                      placeholder="Min"
                      className={inputClassName}
                    />
                  </div>
                  <div>
                    <label className={labelClassName}>Cal. (kcal):</label>
                    <input
                      type="number"
                      value={card.caloriesBurned}
                      onChange={(e) => updateCard(card.id, 'caloriesBurned', e.target.value)}
                      placeholder="Kcal"
                      className={inputClassName}
                    />
                  </div>
                </div>
              )}

              {card.type === 'Alimentación' && (
                <>
                  <div className="grid grid-cols-2 gap-1">
                    <div>
                      <label className={labelClassName}>Calorías:</label>
                      <input
                        type="number"
                        value={card.calories}
                        onChange={(e) => updateCard(card.id, 'calories', e.target.value)}
                        placeholder="Cal"
                        className={inputClassName}
                      />
                    </div>
                    <div>
                      <label className={labelClassName}>Proteínas:</label>
                      <input
                        type="number"
                        value={card.proteins}
                        onChange={(e) => updateCard(card.id, 'proteins', e.target.value)}
                        placeholder="Prot"
                        className={inputClassName}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div>
                      <label className={labelClassName}>Carbs:</label>
                      <input
                        type="number"
                        value={card.carbs}
                        onChange={(e) => updateCard(card.id, 'carbs', e.target.value)}
                        placeholder="Carbs"
                        className={inputClassName}
                      />
                    </div>
                    <div>
                      <label className={labelClassName}>Grasas:</label>
                      <input
                        type="number"
                        value={card.fats}
                        onChange={(e) => updateCard(card.id, 'fats', e.target.value)}
                        placeholder="Gras"
                        className={inputClassName}
                      />
                    </div>
                  </div>
                  <div>
                    <label className={labelClassName}>Sodio:</label>
                    <input
                      type="number"
                      value={card.sodium}
                      onChange={(e) => updateCard(card.id, 'sodium', e.target.value)}
                      placeholder="Sodio"
                      className={inputClassName}
                    />
                  </div>
                </>
              )}

              {(card.type === 'Estado de Ánimo' ||
                card.type === 'Estado Físico' ||
                card.type === 'Estado de Salud') && (
                <div>
                  <label className={labelClassName}>Desc.:</label>
                  <textarea
                    value={card.description}
                    onChange={(e) => updateCard(card.id, 'description', e.target.value)}
                    placeholder={`Describe tu ${card.type.toLowerCase()}`}
                    className={textareaClassName}
                  />
                </div>
              )}
            </div>

            <div className="flex justify-end mt-1">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSaveCard(card.id);
                }}
                className={`flex items-center px-2 py-1 rounded-md transition-colors text-xs ${
                  card.saving
                    ? 'bg-gray-500 text-white cursor-not-allowed'
                    : 'bg-green-600 hover:bg-green-700 text-white'
                }`}
                disabled={card.saving}
                title="Guardar"
              >
                {card.saving ? (
                  'Guardando...'
                ) : card.saved ? (
                  <>
                    <FaCheck className="mr-1" />
                    Guardado
                  </>
                ) : (
                  <>
                    <FaSave className="mr-1" />
                    Guardar
                  </>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CanvasComponent;
