// src/Components/FeatureTour.js
import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';

const steps = [
  {
    title: "Resumen",
    description:
      "Administra y visualiza tu salud de forma inteligente. Siéntete al mando de tu bienestar y toma decisiones informadas.",
    image: "/images/summary.png",
  },
  {
    title: "Reporte",
    description:
      "Recibe un análisis profundo basado en inteligencia artificial, que te brindará recomendaciones personalizadas para optimizar tu salud.",
    image: "/images/report.png",
  },
  {
    title: "Gráfica",
    description:
      "Descubre tu progreso con gráficas interactivas y siente la motivación de seguir adelante en tu camino hacia el bienestar.",
    image: "/images/graph.png",
  },
  {
    title: "Comida",
    description:
      "Registra lo que consumes y obtén información nutricional clara sobre calorías y macronutrientes para una dieta balanceada.",
    image: "/images/comida.png",
  },
  {
    title: "Ejercicio",
    description:
      "Monitorea tu actividad física y observa cómo tus entrenamientos impactan en las calorías quemadas y tu estado de forma.",
    image: "/images/ejercicio.png",
  },
  {
    title: "Estado de Ánimo",
    description:
      "Registra tus emociones y captura momentos memorables para mejorar tu autoconocimiento y equilibrio emocional.",
    image: "/images/estado_animo.png",
  },
  {
    title: "Estado Físico",
    description:
      "Toma nota de tus sensaciones diarias, registra síntomas o lesiones y lleva un control completo de tu salud física.",
    image: "/images/estado_fisico.png",
  },
  {
    title: "Visión",
    description:
      "Sube y almacena documentos o reportes médicos para hacer registro de información con base a estas imágenes.",
    image: "/images/foto.png",
  },
];

const FeatureTour = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [zoomed, setZoomed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detecta el tamaño de pantalla
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Configuración de animación tipo "spring"
  const springTransition = { type: "spring", stiffness: 120, damping: 20 };

  const nextStep = () => setCurrentStep((prev) => (prev + 1) % steps.length);
  const prevStep = () =>
    setCurrentStep((prev) => (prev - 1 + steps.length) % steps.length);

  // Configuración para detectar swipes (izquierda para siguiente, derecha para anterior)
  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextStep,
    onSwipedRight: prevStep,
    trackMouse: true,
  });

  return (
    <div className="max-w-4xl mx-auto my-10 px-4" {...swipeHandlers}>
      <div className="p-[1px] rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 shadow-2xl">
        {/* Se elimina el hover de escalado para evitar cambios bruscos */}
        <div className="bg-gray-900 rounded-xl p-8">
          <h2 className="text-3xl font-black text-center mb-6 text-white tracking-tight">
            Explora las Funcionalidades
          </h2>
          <div className="flex flex-col md:flex-row items-center gap-8">
            {/* Contenedor del texto y controles */}
            <div className="md:w-1/2 flex flex-col justify-center relative overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.div
                  key={steps[currentStep].title}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={springTransition}
                >
                  <h3 className="text-2xl font-semibold mb-3 text-white">
                    {steps[currentStep].title}
                  </h3>
                  <p className="text-gray-300 mb-6 leading-relaxed">
                    {steps[currentStep].description}
                  </p>
                </motion.div>
              </AnimatePresence>

              {/* Botones de navegación */}
              <div className="flex justify-between items-center mb-6">
                <motion.button
                  onClick={prevStep}
                  className="flex-1 mr-2 bg-purple-600 text-white py-2 rounded-md font-medium shadow-md transition-colors duration-200 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  aria-label="Paso anterior"
                  whileTap={{ scale: 0.95 }}
                >
                  Anterior
                </motion.button>
                <motion.button
                  onClick={nextStep}
                  className="flex-1 ml-2 bg-pink-600 text-white py-2 rounded-md font-medium shadow-md transition-colors duration-200 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  aria-label="Siguiente paso"
                  whileTap={{ scale: 0.95 }}
                >
                  Siguiente
                </motion.button>
              </div>

              {/* Indicadores de pasos */}
              <div className="flex justify-center space-x-2">
                {steps.map((_, index) => (
                  <motion.div
                    key={index}
                    className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                      index === currentStep ? "bg-pink-500" : "bg-gray-600"
                    }`}
                    whileHover={{ scale: 1.2 }}
                  />
                ))}
              </div>
            </div>

            {/* Contenedor de la imagen */}
            <div className="md:w-1/2 flex justify-center relative overflow-hidden">
              <AnimatePresence mode="wait">
                <motion.img
                  key={steps[currentStep].image}
                  src={steps[currentStep].image}
                  alt={steps[currentStep].title}
                  className="w-full h-auto object-contain rounded-xl border border-gray-700 shadow-lg cursor-pointer"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={springTransition}
                  layoutId={`zoomable-image-${steps[currentStep].image}`}
                  onClick={() => setZoomed(true)}
                />
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de imagen ampliada */}
      <AnimatePresence>
        {zoomed && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.img
              src={steps[currentStep].image}
              alt={steps[currentStep].title}
              className="max-w-full max-h-full object-contain rounded-xl shadow-xl"
              layoutId={`zoomable-image-${steps[currentStep].image}`}
            />
            <motion.button
              onClick={() => setZoomed(false)}
              className="absolute top-5 right-5 bg-white text-black rounded-full p-3 shadow-lg hover:bg-gray-200 transition"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              aria-label="Cerrar imagen ampliada"
            >
              ✕
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FeatureTour;
