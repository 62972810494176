// CardPaymentForm.jsx
import React, { useState, useEffect } from 'react';
import { useTheme } from '../ThemeContext'; // Ajusta la ruta según donde esté tu ThemeContext

const CardPaymentForm = () => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);

  // Valores de configuración para Wompi
  const WOMPI_CURRENCY = 'COP';
  const WOMPI_AMOUNT_IN_CENTS = 2000000; // 20.000 COP en centavos
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Cargar el widget de Wompi si aún no se ha cargado
    if (!window.WidgetCheckout) {
      const script = document.createElement('script');
      script.src = 'https://checkout.wompi.co/widget.js';
      script.async = true;
      script.onload = () => console.log('Wompi Widget cargado');
      document.body.appendChild(script);
    }
  }, []);

  const handleTokenizeWidget = async () => {
    setLoading(true);
    try {
      // Solicitar al backend la referencia y firma para inicializar el widget
      const response = await fetch(`${API_BASE_URL}/wompi/create-payment`, {
        method: 'POST',
        credentials: 'include', // Enviar cookies y credenciales de sesión
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amountInCents: WOMPI_AMOUNT_IN_CENTS,
          currency: WOMPI_CURRENCY
        })
      });

      if (!response.ok) {
        throw new Error('Error al obtener los datos de pago del servidor.');
      }

      const { reference, signature, publicKey } = await response.json();
      console.log('Datos recibidos del backend:', { reference, signature, publicKey });

      if (!window.WidgetCheckout) {
        console.error('El sistema de pago no está disponible.');
        alert('El sistema de pago no está disponible. Por favor, intenta más tarde.');
        setLoading(false);
        return;
      }

      // Inicializar el widget con los datos recibidos
      const widget = new window.WidgetCheckout({
        publicKey,
        currency: WOMPI_CURRENCY,
        amountInCents: WOMPI_AMOUNT_IN_CENTS,
        reference,
        signature: { integrity: signature }
      });

      widget.open(async (result) => {
        console.log('Callback del widget ejecutado:', result);
        // Enviar el objeto completo "result" al backend para su log
        try {
          const logResponse = await fetch(`${API_BASE_URL}/wompi/log-result`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(result)
          });

          if (!logResponse.ok) {
            throw new Error('Error al enviar el resultado al servidor.');
          }

          console.log('Resultado enviado y loggeado en el backend.');
          alert('¡Felicidades! Tu Plus ha sido activado exitosamente.');
        } catch (err) {
          console.error('Error enviando el resultado al backend:', err);
          alert('Hubo un problema al confirmar tu pago. Por favor, inténtalo nuevamente.');
        }
        setLoading(false);
      });
    } catch (error) {
      console.error('Error en el proceso de pago:', error);
      setLoading(false);
      alert('Ocurrió un error durante el proceso de pago. Por favor, intenta más tarde.');
    }
  };

  // Estilos adaptados al tema y con un toque premium
  const containerStyle = {
    padding: '2rem',
    borderRadius: '10px',
    background: theme === 'dark' ? '#2c2c2c' : 'linear-gradient(135deg, #f6f9fc, #e9eff5)',
    color: theme === 'dark' ? '#eee' : '#333',
    maxWidth: '400px',
    margin: '3rem auto',
    boxShadow: theme === 'dark'
      ? '0 4px 6px rgba(0, 0, 0, 0.4)'
      : '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  const buttonStyle = {
    background: 'linear-gradient(45deg, #0070f3, #005bb5)',
    color: '#fff',
    padding: '1rem',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    fontSize: '1.1rem',
    transition: 'background 0.3s, transform 0.2s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
  };

  return (
    <div style={containerStyle}>
      <h2 style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
        ¡Activa tu Suscripcióm Plus!
      </h2>
      <p style={{ textAlign: 'center', marginBottom: '1.5rem', fontSize: '1.1rem' }}>
        Por solo $20,000 COP, disfruta de todos los beneficios, más inteligete, menos limites.
      </p>
      <button
        onClick={handleTokenizeWidget}
        disabled={loading}
        style={buttonStyle}
        onMouseOver={e => {
          e.target.style.background = '#005bb5';
          e.target.style.transform = 'scale(1.02)';
        }}
        onMouseOut={e => {
          e.target.style.background = 'linear-gradient(45deg, #0070f3, #005bb5)';
          e.target.style.transform = 'scale(1)';
        }}
      >
        {loading ? 'Procesando...' : 'Activar mi Plus'}
      </button>
    </div>
  );
};

export default CardPaymentForm;
