// ProjectionPanel/ProjectionPanel.js

import React, { useState } from 'react';
import useProjection from './useProjection';
import {
  MdExpandMore,
  MdExpandLess,
  MdDateRange,
  MdArrowDropUp,
  MdArrowDropDown,
  MdRemove,
  MdFastfood,
  MdLocalFireDepartment,
} from 'react-icons/md';

const ProjectionPanel = () => {
  const projection = useProjection();
  const {
    totalDays,
    weight,
    finalWeight,
    bodyFat,
    finalBodyFatPercentage,
    mustEatToday,
    mustBurnToday,
    isBodyFatAvailable,
    bmiClassification,
    finalBmiClassification,
    bodyFatClassification,
    finalBodyFatClassification,
    theme,
    start,
    end,
    initialMuscleMass,
    finalMuscleMass,
    initialBmi,
    finalBmi,
    getCaloricIntakeLabel,
    getSexLabel,
  } = projection;

  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => setIsOpen((prev) => !prev);

  // Función para formatear las diferencias con íconos y colores
  const formatDiff = (diffValue, label, isPercent = false) => {
    const sign = diffValue > 0 ? '+' : diffValue < 0 ? '-' : '';
    let icon = <MdRemove className="inline" />;

    if (diffValue > 0) {
      icon = <MdArrowDropUp className="inline" />;
    } else if (diffValue < 0) {
      icon = <MdArrowDropDown className="inline" />;
    }

    const colorClass =
      diffValue > 0
        ? 'text-green-500 dark:text-green-300'
        : diffValue < 0
        ? 'text-red-500 dark:text-red-300'
        : 'text-gray-500 dark:text-gray-400';

    const absDiff = Number(Math.abs(diffValue).toFixed(1));

    return (
      <div
        className={
          'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
          'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
        }
      >
        <span className={colorClass}>
          {icon} {sign}
          {absDiff}
          {isPercent ? '%' : ''} {label}
        </span>
      </div>
    );
  };

  // Calculamos diferencias para el preview
  const weightDiff = finalWeight - weight;
  const bfDiff = isBodyFatAvailable ? finalBodyFatPercentage - bodyFat : null;
  const bmiDiffValue = finalBmi - initialBmi;

  return (
    <div className="p-4 w-full relative transition-colors duration-300 ease-in-out">
      {/* 
        BOTÓN + Resumen de Proyección 
      */}
      <div className="flex items-center gap-4 mb-4 flex-wrap">
        {/* BOTÓN: Ver/Ocultar Proyección */}
        <button
          className={
            `flex items-center gap-2 px-4 py-2 rounded-md font-semibold 
             transition-all duration-300 transform hover:scale-105 text-white hover:shadow-lg
             focus:outline-none focus:ring-2 focus:ring-indigo-400 
             ${
               isOpen
                 ? theme === 'dark'
                   ? 'bg-gradient-to-r from-indigo-600 to-indigo-500 hover:to-indigo-600'
                   : 'bg-indigo-700 hover:bg-indigo-600'
                 : theme === 'dark'
                 ? 'bg-gradient-to-r from-indigo-500 to-indigo-400 hover:to-indigo-500'
                 : 'bg-indigo-600 hover:bg-indigo-700'
             }`
          }
          onClick={togglePanel}
          aria-expanded={isOpen}
          aria-label={isOpen ? 'Ocultar proyección' : 'Ver proyección'}
        >
          <span className="text-white dark:text-gray-200">
            {isOpen ? 'Ocultar Proyección' : 'Ver Proyección'}
          </span>
          {isOpen ? (
            <MdExpandLess className="text-white dark:text-gray-200" />
          ) : (
            <MdExpandMore className="text-white dark:text-gray-200" />
          )}
        </button>

        {/* PREVISUALIZACIÓN (solo cuando el panel NO está abierto) */}
        {!isOpen && (
          <div className="flex items-center gap-4 transition-all duration-500 flex-wrap">
            {formatDiff(weightDiff, 'Peso (kg)')}
            {isBodyFatAvailable && formatDiff(bfDiff, '% Grasa', true)}
            {formatDiff(finalMuscleMass - initialMuscleMass, 'Músculo (kg)')}
            {formatDiff(bmiDiffValue, 'IMC')}
            {/* Calorías a Comer Hoy */}
            <div
              className={
                'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
                'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
              }
            >
              <MdFastfood className="inline text-indigo-500 dark:text-indigo-300" />
              <span className="text-gray-700 dark:text-gray-200">
                {Number(mustEatToday).toFixed(0)} Calorías/día
              </span>
            </div>

            {/* Calorías a Gastar Hoy */}
            <div
              className={
                'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
                'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
              }
            >
              <MdLocalFireDepartment className="inline text-indigo-500 dark:text-indigo-300" />
              <span className="text-gray-700 dark:text-gray-200">
                {Number(mustBurnToday).toFixed(0)} Calorías/día
              </span>
            </div>

            {/* Días Totales */}
            <div
              className={
                'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
                'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
              }
            >
              <MdDateRange className="text-indigo-500 dark:text-indigo-300" />
              <span className="text-gray-700 dark:text-gray-200">
                {totalDays} días
              </span>
            </div>
          </div>
        )}
      </div>

      {/* CONTENEDOR EXPANDIBLE */}
      <div
        className={
          `overflow-hidden transition-all duration-700 ease-in-out 
           transform ${isOpen ? 'max-h-[4000px] opacity-100' : 'max-h-0 opacity-0'}`
        }
      >
        <div
          className={
            `w-full p-4 rounded-md shadow-lg transition-colors duration-300
             ${theme === 'dark' ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'}`
          }
          style={{
            transitionProperty: 'background-color, color, max-height, opacity',
          }}
        >
          {/* SECCIÓN DE BIENVENIDA O “MARKETING EMOCIONAL” */}
          <div
            className={
              `py-3 px-4 mb-4 rounded-md border-l-4 
               ${
                 theme === 'dark'
                   ? 'border-indigo-400 bg-gray-700 text-gray-100'
                   : 'border-indigo-600 bg-indigo-50 text-indigo-900'
               } 
               transition-colors duration-300`
            }
          >
            <h2 className="text-lg font-bold mb-1">¡Tu viaje de transformación comienza ahora!</h2>
            <p className="text-sm">
              Estás a un paso de alcanzar tu mejor versión. Mira cómo <strong>evolucionarás</strong> 
              en los próximos días y prepárate para celebrar cada logro.
            </p>
          </div>

          {/* TÍTULO PRINCIPAL */}
          <div
            className={
              `mb-4 pb-2 border-b 
               ${theme === 'dark' ? 'border-gray-700' : 'border-gray-300'}`
            }
          >
            <h2 className="text-lg font-semibold">
              Proyección de tu estado físico
            </h2>
            <p
              className={
                `mt-1 text-sm 
                 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`
              }
            >
              Visualiza una estimación aproximada de tu progreso. ¡Sigue adelante y mantén la motivación!
            </p>
          </div>

          {/* Fechas y días (si existen) */}
          {start && end ? (
            <div className="mb-4 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
              <div>
                <p className="font-semibold">Inicio del plan:</p>
                <p>
                  {new Date(start).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </p>
              </div>
              <div>
                <p className="font-semibold">Fin del plan:</p>
                <p>
                  {new Date(end).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </p>
              </div>
              <div>
                <p className="font-semibold">Días Totales:</p>
                <p>{totalDays}</p>
              </div>
            </div>
          ) : (
            <div className="mb-4">
              <p className="text-sm italic">
                Aún no has definido las fechas de tu plan.
              </p>
            </div>
          )}

          <hr
            className={
              `my-2 ${theme === 'dark' ? 'border-gray-700' : 'border-gray-300'}`
            }
          />

          {/* Clasificación del IMC */}
          <div className="mb-4">
            <h3 className="font-semibold mb-2">Clasificación del IMC</h3>
            <p className="text-sm">
              <strong>IMC Inicial:</strong>{' '}
              {Number(initialBmi).toFixed(1)} kg/m² -{' '}
              {bmiClassification.category} (
              {bmiClassification.risk})
            </p>
            <p className="text-sm">
              <strong>IMC Final:</strong>{' '}
              {Number(finalBmi).toFixed(1)} kg/m² -{' '}
              {finalBmiClassification.category} (
              {finalBmiClassification.risk})
            </p>
          </div>

          {/* Clasificación de Grasa Corporal */}
          {isBodyFatAvailable && (
            <div className="mb-4">
              <h3 className="font-semibold mb-2">
                Clasificación de Grasa Corporal
              </h3>
              <p className="text-sm">
                <strong>% Grasa Inicial:</strong> {Number(bodyFat).toFixed(1)}% -{' '}
                {bodyFatClassification}
              </p>
              <p className="text-sm">
                <strong>% Grasa Final:</strong> {Number(finalBodyFatPercentage).toFixed(1)}% -{' '}
                {finalBodyFatClassification}
              </p>
            </div>
          )}

          <hr
            className={
              `my-2 ${theme === 'dark' ? 'border-gray-700' : 'border-gray-300'}`
            }
          />

          {/* Estimaciones - Grids comparativas */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Estado Inicial */}
            <div
              className={
                `p-3 rounded shadow-inner
                 ${theme === 'dark' ? 'bg-gray-700 text-gray-100' : 'bg-gray-100 text-gray-800'}`
              }
            >
              <h3 className="font-semibold mb-2">Estado Inicial</h3>
              <ul className="list-disc ml-5 space-y-1 text-sm">
                <li>Peso (total): {Number(weight).toFixed(1)} kg</li>
                {isBodyFatAvailable && (
                  <li>% Grasa: {Number(bodyFat).toFixed(1)} %</li>
                )}
                {isBodyFatAvailable && (
                  <li>Masa Grasa: {Number(projection.initialFatMass).toFixed(1)} kg</li>
                )}
                <li>Masa Muscular: {Number(initialMuscleMass).toFixed(1)} kg</li>
                <li>IMC: {Number(initialBmi).toFixed(1)}</li>
              </ul>
            </div>

            {/* Proyección Final */}
            <div
              className={
                `p-3 rounded shadow-inner
                 ${theme === 'dark' ? 'bg-gray-700 text-gray-100' : 'bg-gray-100 text-gray-800'}`
              }
            >
              <h3 className="font-semibold mb-2">
                Proyección al finalizar ({totalDays} días)
              </h3>
              <ul className="list-disc ml-5 space-y-1 text-sm">
                <li>
                  Peso proyectado (total):{' '}
                  <span className="font-semibold">
                    {Number(finalWeight).toFixed(1)} kg
                  </span>
                </li>
                {isBodyFatAvailable && (
                  <li>
                    % Grasa proyectado:{' '}
                    <span className="font-semibold">
                      {Number(finalBodyFatPercentage).toFixed(1)} %
                    </span>
                  </li>
                )}
                {isBodyFatAvailable && (
                  <li>
                    Masa Grasa proyectada:{' '}
                    <span className="font-semibold">
                      {Number(projection.finalFatMass).toFixed(1)} kg
                    </span>
                  </li>
                )}
                <li>
                  Masa Muscular proyectada:{' '}
                  <span className="font-semibold">
                    {Number(finalMuscleMass).toFixed(1)} kg
                  </span>
                </li>
                <li>
                  IMC proyectado:{' '}
                  <span className="font-semibold">
                    {Number(finalBmi).toFixed(1)}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Mensaje de precisión si bodyFat no está disponible */}
          {!isBodyFatAvailable && (
            <div
              className={
                `mt-4 p-3 rounded 
                 ${theme === 'dark' ? 'bg-yellow-700' : 'bg-yellow-100'}`
              }
            >
              <p
                className={
                  `text-sm 
                   ${theme === 'dark' ? 'text-yellow-200' : 'text-yellow-800'}`
                }
              >
                <strong>Nota:</strong> Para una proyección más precisa, 
                por favor proporciona tu porcentaje de grasa corporal en el formulario.
              </p>
            </div>
          )}

          <hr
            className={
              `my-4 ${theme === 'dark' ? 'border-gray-700' : 'border-gray-300'}`
            }
          />

          {/* Datos adicionales */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
            <div>
              <p>
                <span className="font-semibold">Sexo:</span>{' '}
                {getSexLabel(projection.sex)}
              </p>
              <p>
                <span className="font-semibold">Edad:</span>{' '}
                {projection.age} años
              </p>
              <p>
                <span className="font-semibold">Altura:</span>{' '}
                {projection.height} cm
              </p>
              <p>
                <span className="font-semibold">Factor de Actividad:</span>{' '}
                {projection.activityFactor}
              </p>
              <p>
                <span className="font-semibold">Objetivo Calórico:</span>{' '}
                {getCaloricIntakeLabel(projection.effectiveCaloricIntake)}
              </p>
              <p>
                <span className="font-semibold">TDEE estimado:</span>{' '}
                {Number(projection.tdee).toFixed(0)} kcal/día
              </p>
              <p>
                <span className="font-semibold">
                  Calorías Diarias Recomendadas:
                </span>{' '}
                {Number(projection.dailyRecommendedCalories).toFixed(0)} kcal
              </p>
              <p>
                <span className="font-semibold">Proteína Recomendada:</span>{' '}
                {Number(projection.dailyProteinRecommended).toFixed(0)} g/día
              </p>
              <p>
                <span className="font-semibold">Uso de Creatina:</span>{' '}
                {projection.effectiveUseCreatine ? 'Sí' : 'No'}
              </p>
            </div>
          </div>

          <hr
            className={
              `my-4 ${theme === 'dark' ? 'border-gray-700' : 'border-gray-300'}`
            }
          />

          <p
            className={
              `text-xs leading-5 
               ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`
            }
          >
            <strong>Nota Importante:</strong> Estas cifras son estimaciones generales y <strong>no</strong>{' '}
            sustituyen el asesoramiento profesional. Se basan en la fórmula Mifflin-St Jeor,
            supuestos simplificados de distribución de peso y aproximaciones de ganancia/pérdida
            de músculo y grasa. El factor de actividad influye en las tasas de ganancia/pérdida
            muscular y grasa. Más actividad puede resultar en mayor ganancia muscular y mayor
            pérdida de grasa.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectionPanel;
