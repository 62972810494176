// src/contexts/ChatContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './AuthContext';
import { usePlan } from './PlanContext';
import { v4 as uuidv4 } from 'uuid';

// Definir la URL base de la API como una constante
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Crear el contexto
const ChatContext = createContext();

// Componente proveedor
export const ChatProvider = ({ children }) => {
  const { user } = useAuth();
  const { planId } = usePlan();

  // Estado para indicar si los chats están cargando
  const [loadingChats, setLoadingChats] = useState(true);

  // Estado para las conversaciones
  const [chats, setChats] = useState([]);

  // Estado para el chat actual
  const [currentChatId, setCurrentChatId] = useState(null);

  // Estado para indicar si está cargando
  const [isLoading, setIsLoading] = useState(false);

  // Función para obtener las conversaciones desde el backend
  const fetchConversationsFromBackend = useCallback(async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/conversations/${planId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.chats && data.chats.length > 0) {
          setChats(data.chats);
        } else {
          // Inicializar con un chat predeterminado si no hay conversaciones
          const initialChat = {
            id: uuidv4(),
            name: 'Chat Principal',
            messages: [
              {
                id: uuidv4(),
                sender: 'assistant',
                text: '¡Hola! ¿En qué puedo ayudarte hoy?',
              },
            ],
          };
          setChats([initialChat]);
        }
      } else {
        console.log('No hay conversaciones guardadas.');
        // Inicializar con un chat predeterminado si no hay conversaciones
        const initialChat = {
          id: uuidv4(),
          name: 'Chat Principal',
          messages: [
            {
              id: uuidv4(),
              sender: 'assistant',
              text: '¡Hola! ¿En qué puedo ayudarte hoy?',
            },
          ],
        };
        setChats([initialChat]);
      }
    } catch (error) {
      console.error('Error al obtener las conversaciones:', error);
      // Inicializar con un chat predeterminado en caso de error
      const initialChat = {
        id: uuidv4(),
        name: 'Chat Principal',
        messages: [
          {
            id: uuidv4(),
            sender: 'assistant',
            text: '¡Hola! ¿En qué puedo ayudarte hoy?',
          },
        ],
      };
      setChats([initialChat]);
    } finally {
      setLoadingChats(false); // Finalizar la carga
    }
  }, [planId]);

  // Función para guardar las conversaciones en el backend
  const saveConversationsToBackend = useCallback(
    async (updatedChats) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/conversations/${planId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ chats: updatedChats }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error en la respuesta del servidor:', errorData);
          throw new Error(errorData.message || 'Error en la solicitud');
        }

        const data = await response.json();
        console.log('Conversaciones guardadas:', data.message);
      } catch (error) {
        console.error('Error al guardar las conversaciones:', error);
      }
    },
    [planId]
  );

  // Cargar conversaciones cuando cambie el usuario o el plan
  useEffect(() => {
    if (user && planId) {
      fetchConversationsFromBackend();
    }
  }, [user, planId, fetchConversationsFromBackend]);

  // Establecer el chat actual después de cargar las conversaciones
  useEffect(() => {
    if (!currentChatId && chats.length > 0) {
      setCurrentChatId(chats[0].id);
    }
  }, [chats, currentChatId]);

  // Guardar conversaciones en el backend cuando cambien
  useEffect(() => {
    if (user && planId && chats.length > 0) {
      saveConversationsToBackend(chats);
    }
  }, [chats, user, planId, saveConversationsToBackend]);

  // Funciones para manejar las conversaciones
  const addChat = (chat) => {
    setChats((prevChats) => [chat, ...prevChats]);
  };

  const updateChat = (chatId, updatedChat) => {
    setChats((prevChats) =>
      prevChats.map((chat) => (chat.id === chatId ? updatedChat : chat))
    );
  };

  const deleteChat = (chatId) => {
    setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
  };

  // Función para enviar un mensaje
  const sendMessage = async (userInput) => {
    if (!userInput.trim()) return;

    const currentChat = chats.find((chat) => chat.id === currentChatId);

    if (!currentChat) {
      console.error('No hay un chat seleccionado');
      return;
    }

    // Añadir el mensaje del usuario al chat actual
    const userMessage = {
      id: uuidv4(),
      sender: 'user',
      text: userInput,
    };

    const updatedChat = {
      ...currentChat,
      messages: [...(currentChat.messages || []), userMessage],
    };
    updateChat(currentChatId, updatedChat);
    setIsLoading(true); // Iniciar el loading

    try {
      // Verifica que planId está disponible
      if (!planId) {
        throw new Error('Plan ID no disponible');
      }

      // Realizar la solicitud al backend usando fetch
      const response = await fetch(`${API_BASE_URL}/openai`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Incluir credenciales para autenticación
        body: JSON.stringify({
          prompt: userInput,
          conversationId: currentChatId,
          planId: planId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en la solicitud');
      }

      const data = await response.json();
      const assistantMessageText = data.response;
      const suggestedName = data.suggestedName;
      const conversationIdFromResponse = data.conversationId;
      const cardData = data.cardData; // Añadir esta línea

      // Añadir la respuesta del asistente al chat
      const assistantMessage = {
        id: uuidv4(),
        sender: 'assistant',
        text: assistantMessageText,
        cardData: cardData, // Almacenar este indicador en el mensaje
      };

      const updatedChatWithResponse = {
        ...updatedChat,
        messages: [...updatedChat.messages, assistantMessage],
      };

      // Si hay un nombre sugerido, actualizarlo
      if (suggestedName) {
        updatedChatWithResponse.name = suggestedName;
      }

      // Verificar si el conversationId ha cambiado y actualizarlo
      if (
        conversationIdFromResponse &&
        conversationIdFromResponse !== currentChatId
      ) {
        // Actualizar el ID del chat en la lista de chats
        deleteChat(currentChatId); // Eliminar el chat con el ID antiguo
        updatedChatWithResponse.id = conversationIdFromResponse; // Asignar el nuevo ID
        addChat(updatedChatWithResponse); // Añadir el chat actualizado con el nuevo ID
        setCurrentChatId(conversationIdFromResponse); // Actualizar el currentChatId
      } else {
        updateChat(currentChatId, updatedChatWithResponse);
      }
    } catch (error) {
      console.error('Error al comunicarse con el backend:', error);
      // Añadir un mensaje de error al chat
      const errorAssistantMessage = {
        id: uuidv4(),
        sender: 'assistant',
        text: 'Lo siento, hubo un error al procesar tu solicitud.',
      };

      const updatedChatWithError = {
        ...updatedChat,
        messages: [...updatedChat.messages, errorAssistantMessage],
      };
      updateChat(currentChatId, updatedChatWithError);
    } finally {
      setIsLoading(false); // Finalizar el loading
    }
  };

  // Exponer las conversaciones y funciones
  return (
    <ChatContext.Provider
      value={{
        chats,
        setChats,
        addChat,
        updateChat,
        deleteChat,
        loadingChats,
        currentChatId,
        setCurrentChatId,
        isLoading,
        sendMessage,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook para usar el contexto
export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};
