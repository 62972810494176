// src/components/EntrySummaryView.js

import React, { useState } from 'react';
import {
  MdExpandMore,
  MdExpandLess,
  MdDelete,
  MdFastfood,
  MdLocalFireDepartment
} from 'react-icons/md';
import {
  RiBarChartFill,
  RiRestaurantFill,
  RiRunFill,
  RiEmotionHappyLine,
  RiPulseFill
} from 'react-icons/ri';
import { AnimatePresence, motion } from 'framer-motion';
import { parse } from 'date-fns';

const EntrySummaryView = ({
  isOpen,
  toggleIsOpen,
  previewCounters,
  totalEntries,
  foodStats,
  workoutStats,
  moodStats,
  stateStats,
  // Por defecto se asigna 'daily'
  foodView = 'daily',
  setFoodView,
  workoutView = 'daily',
  setWorkoutView,
  theme,
  // Listas
  foodList,
  workoutList,
  moodList,
  physicalStateList,
  // Función para eliminar entradas
  deleteEntry,
  // Handler para seleccionar una entrada (abre modal)
  onSelectEntry,
  // Calorías de hoy
  todayFoodCalories,
  todayWorkoutCalories,
}) => {
  // ------- FUNCIÓN ACTUALIZADA CON DATE-FNS PARA PARSEAR FECHAS ------
  const parseDateIosSafe = (dateString) => {
    if (!dateString) return new Date();
    if (dateString.includes("T")) {
      return new Date(dateString);
    }
    return parse(dateString, 'dd/MM/yyyy HH:mm', new Date());
  };

  // ----------- CALCULAR PORCENTAJES DE MACROS -----------
  const calculateMacroPercentages = (stats) => {
    const { calorias, proteinas, carbohidratos, grasas } = stats;
    if (calorias > 0) {
      const proteinCalories = proteinas * 4;
      const carbCalories = carbohidratos * 4;
      const fatCalories = grasas * 9;
      const totalMacroCalories = proteinCalories + carbCalories + fatCalories;

      if (totalMacroCalories > 0) {
        return {
          pPercent: ((proteinCalories / totalMacroCalories) * 100).toFixed(1),
          cPercent: ((carbCalories / totalMacroCalories) * 100).toFixed(1),
          gPercent: ((fatCalories / totalMacroCalories) * 100).toFixed(1),
        };
      }
    }
    return { pPercent: 0, cPercent: 0, gPercent: 0 };
  };

  const currentFoodStats =
    foodView === 'total' ? foodStats.totals : foodStats.daily;

  const {
    pPercent: dailyPPercent,
    cPercent: dailyCPercent,
    gPercent: dailyGPercent
  } = calculateMacroPercentages(foodStats.daily);

  const {
    pPercent: totalPPercent,
    cPercent: totalCPercent,
    gPercent: totalGPercent
  } = calculateMacroPercentages(foodStats.totals);

  let pPercent = 0,
    cPercent = 0,
    gPercent = 0;

  if (foodView === 'total') {
    pPercent = totalPPercent;
    cPercent = totalCPercent;
    gPercent = totalGPercent;
  } else {
    pPercent = dailyPPercent;
    cPercent = dailyCPercent;
    gPercent = dailyGPercent;
  }

  // ----------- ESTADOS DE DESPLIEGUE POR SECCIÓN -----------
  const [foodDetailsOpen, setFoodDetailsOpen] = useState(false);
  const [workoutDetailsOpen, setWorkoutDetailsOpen] = useState(false);
  const [moodDetailsOpen, setMoodDetailsOpen] = useState(false);
  const [stateDetailsOpen, setStateDetailsOpen] = useState(false);

  // ----------- MENSAJES DE ELIMINACIÓN -----------
  const [deleteError, setDeleteError] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(null);

  // ----------- MANEJO DE ELIMINACIÓN CON CONFIRMACIÓN -----------
  const handleDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta entrada?')) {
      try {
        const result = await deleteEntry(id);
        if (result.success) {
          setDeleteSuccess('Entrada eliminada correctamente.');
          setDeleteError(null);
          setTimeout(() => setDeleteSuccess(null), 3000);
        } else {
          setDeleteError(result.message || 'Error al eliminar la entrada.');
          setDeleteSuccess(null);
        }
      } catch (error) {
        setDeleteError('Error al eliminar la entrada.');
        setDeleteSuccess(null);
      }
    }
  };

  // ----------- SELECCIONAR ENTRADA PARA MOSTRAR EN MODAL -----------
  const handleEntryClick = (entry) => {
    onSelectEntry(entry);
  };

  // ----------- OBTENER ÍCONO POR TIPO -----------
  const getIconByType = (type) => {
    switch (type) {
      case 'food':
        return <RiRestaurantFill className="w-5 h-5" />;
      case 'workout':
        return <RiRunFill className="w-5 h-5" />;
      case 'mood':
        return <RiEmotionHappyLine className="w-5 h-5" />;
      case 'state':
        return <RiPulseFill className="w-5 h-5" />;
      default:
        return null;
    }
  };

  // ----------- CLASES DINÁMICAS -----------
  const buttonClasses = `
    flex items-center justify-center gap-2 
    py-2 px-4 rounded-md font-semibold 
    transition-all duration-300 transform
    hover:scale-105
    ${
      isOpen
        ? theme === 'dark'
          ? 'bg-gradient-to-r from-indigo-600 to-indigo-500 hover:to-indigo-600'
          : 'bg-gradient-to-r from-indigo-600 to-indigo-500 hover:to-indigo-600'
        : theme === 'dark'
        ? 'bg-gradient-to-r from-indigo-500 to-indigo-400 hover:from-indigo-400 hover:to-indigo-500'
        : 'bg-gradient-to-r from-indigo-500 to-indigo-400 hover:from-indigo-400 hover:to-indigo-500'
    }
    text-white hover:shadow-lg
    focus:outline-none focus:ring-2 focus:ring-indigo-400
  `;

  const containerClasses = `
    w-full
    mt-4 p-4 rounded-xl shadow-lg 
    transition-colors duration-300
    ${
      theme === 'dark'
        ? 'bg-gray-800/70 text-white border-gray-700 backdrop-filter backdrop-blur-sm'
        : 'bg-white/70 text-gray-800 backdrop-filter backdrop-blur-sm'
    }
  `;

  const headingBorderColor = theme === 'dark' ? 'border-gray-700' : 'border-gray-300';
  // Colores ajustados para mejor contraste en modo dark
  const textSecondary = theme === 'dark' ? 'text-white' : 'text-gray-700';
  const textTertiary = theme === 'dark' ? 'text-gray-100' : 'text-gray-600';
  const textQuaternary = theme === 'dark' ? 'text-gray-200' : 'text-gray-500';
  const dividerColor = theme === 'dark' ? 'border-gray-700' : 'border-gray-300';

  const scrollbarClasses = `
    scrollbar-thin 
    scrollbar-thumb-rounded-full 
    scrollbar-track-rounded-full
    scrollbar-thumb-indigo-400 
    dark:scrollbar-thumb-gray-600 
    scrollbar-track-transparent 
    dark:scrollbar-track-transparent
  `;

  // Se invierte el orden de las listas para mostrar primero la más reciente
  const sortedFoodList = [...foodList].reverse();
  const sortedWorkoutList = [...workoutList].reverse();
  const sortedMoodList = [...moodList].reverse();
  const sortedPhysicalStateList = [...physicalStateList].reverse();

  return (
    <div className="p-4 w-full relative transition-colors duration-300 ease-in-out">
      {/* BOTÓN DE VER/CONTRAER RESUMEN + PREVIEW COUNTERS + CALORÍAS DE LAS ÚLTIMAS 24 HORAS */}
      <div className="flex items-center gap-4 mb-4 flex-wrap">
        {/* Botón principal para expandir/contraer */}
        <button
          className={buttonClasses}
          onClick={toggleIsOpen}
          aria-expanded={isOpen}
          aria-label={
            isOpen
              ? 'Contraer resumen de entradas'
              : 'Expandir resumen de entradas'
          }
        >
          {isOpen ? 'Contraer Resumen' : 'Ver Resumen'}
          <span
            className={`transform transition-transform duration-300 ${
              isOpen ? 'rotate-180' : ''
            }`}
          >
            {isOpen ? <MdExpandLess className="w-5 h-5" /> : <MdExpandMore className="w-5 h-5" />}
          </span>
        </button>

        {/* Previsualización de contadores (tipos de entradas) */}
        <div
          className={`
            flex items-center gap-4 transition-all duration-500 flex-wrap
            ${
              isOpen
                ? 'opacity-0 pointer-events-none scale-90'
                : 'opacity-100 pointer-events-auto scale-100'
            }
          `}
        >
          {previewCounters.map((item, idx) => (
            <div
              key={idx}
              onClick={() => {
                if (!isOpen) {
                  toggleIsOpen();
                }
                switch (item.type) {
                  case 'food':
                    setFoodDetailsOpen(true);
                    break;
                  case 'workout':
                    setWorkoutDetailsOpen(true);
                    break;
                  case 'mood':
                    setMoodDetailsOpen(true);
                    break;
                  case 'state':
                    setStateDetailsOpen(true);
                    break;
                  default:
                    break;
                }
              }}
              className="flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md
                         hover:bg-indigo-50 dark:hover:bg-gray-700
                         transition-colors duration-300
                         text-gray-700 dark:text-gray-200"
            >
              {getIconByType(item.type)}
              <span className="font-semibold">{item.count}</span>
            </div>
          ))}
        </div>

        {/* CALORÍAS DE LAS ÚLTIMAS 24 HORAS: ingeridas y quemadas */}
        {!isOpen && (
          <div
            className={`
              flex items-center gap-3 
              text-sm py-2 px-3
              rounded-md 
              bg-transparent
              hover:bg-indigo-50 dark:hover:bg-gray-700
              transition-colors duration-300
              text-gray-700 dark:text-gray-200
            `}
          >
            <div
              className="flex items-center gap-1"
              title="Calorías ingeridas en las últimas 24 horas"
            >
              <MdFastfood className="w-5 h-5 text-red-500" />
              <span className="font-semibold">{todayFoodCalories} kcal</span>
              <span className="text-xs text-gray-500 dark:text-gray-400">(últimas 24h)</span>
            </div>
            <div
              className="flex items-center gap-1"
              title="Calorías quemadas en las últimas 24 horas"
            >
              <MdLocalFireDepartment className="w-5 h-5 text-green-500" />
              <span className="font-semibold">{todayWorkoutCalories} kcal</span>
              <span className="text-xs text-gray-500 dark:text-gray-400">(últimas 24h)</span>
            </div>
          </div>
        )}
      </div>

      {/* CONTENEDOR EXPANDIBLE */}
      <div
        className={`
          overflow-hidden
          transition-all
          duration-500
          ${isOpen ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'}
        `}
      >
        {isOpen && (
          <div className={`${containerClasses} mt-2`}>
            {/* Mensajes de éxito/error al eliminar */}
            {deleteSuccess && (
              <div className="mb-2 p-2 bg-green-200 text-green-800 rounded transition-all duration-300">
                {deleteSuccess}
              </div>
            )}
            {deleteError && (
              <div className="mb-2 p-2 bg-red-200 text-red-800 rounded transition-all duration-300">
                {deleteError}
              </div>
            )}

            {/* ENCABEZADO / RESUMEN GENERAL */}
            <div className={`mb-4 pb-2 border-b ${headingBorderColor}`}>
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold flex items-center gap-2">
                  <RiBarChartFill className="w-5 h-5" />
                  Resumen de tus Registros
                </h2>
              </div>

              <p className={`mt-1 text-sm ${textSecondary}`}>
                Total de registros: {totalEntries}
              </p>

              {/* Calorías de hoy en expandido */}
              <div className="mt-2 flex items-center gap-4 text-sm">
                <div className="flex items-center gap-1">
                  <MdFastfood className="w-5 h-5 text-red-500" />
                  <span className="font-semibold">Hoy Ingerido:</span>
                  <span>{todayFoodCalories} kcal</span>
                </div>
                <div className="flex items-center gap-1">
                  <MdLocalFireDepartment className="w-5 h-5 text-green-500" />
                  <span className="font-semibold">Hoy Quemado:</span>
                  <span>{todayWorkoutCalories} kcal</span>
                </div>
              </div>
            </div>

            {/* Si no hay entradas, mostramos un mensaje */}
            {totalEntries === 0 ? (
              <p className={`text-sm ${textTertiary}`}>
                Aún no tienes registros. Agrega entradas para ver un análisis detallado.
              </p>
            ) : (
              <div className="space-y-8">
                {/* ALIMENTACIÓN */}
                {foodStats.totalCount > 0 && (
                  <div className="transition-all duration-500 ease-in-out">
                    <div
                      className={`
                        cursor-pointer flex items-center justify-between p-2 rounded 
                        hover:bg-gray-50 dark:hover:bg-gray-700
                        text-gray-700 dark:text-gray-200
                        transition-colors duration-300
                      `}
                      onClick={() => setFoodDetailsOpen((prev) => !prev)}
                    >
                      <h3 className="text-md font-semibold flex items-center gap-2">
                        <RiRestaurantFill
                          className={`
                            w-5 h-5 transition-transform duration-300
                            ${foodDetailsOpen ? 'rotate-12 text-red-500' : 'text-red-500'}
                          `}
                        />
                        Alimentación
                      </h3>
                      <span
                        className={`transform transition-transform duration-300 ${
                          foodDetailsOpen ? 'rotate-180' : ''
                        }`}
                      >
                        {foodDetailsOpen ? <MdExpandLess /> : <MdExpandMore />}
                      </span>
                    </div>

                    <div
                      className={`
                        transition-all duration-500 overflow-hidden
                        ${foodDetailsOpen ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0'}
                      `}
                    >
                      <div className="pt-2">
                        <p className={`text-sm ${textSecondary}`}>
                          Entradas: {foodStats.totalCount}
                          {foodStats.uniqueDays > 0 && ` | Días registrados: ${foodStats.uniqueDays}`}
                        </p>

                        {/* Botones (Total vs Promedio Diario) */}
                        <div className="mt-2 text-sm flex items-center gap-2">
                          <button
                            onClick={() => setFoodView('total')}
                            className={`
                              px-2 py-1 rounded 
                              transition-colors duration-300
                              ${foodView === 'total'
                                  ? 'bg-indigo-500 text-white'
                                  : 'bg-gray-200 dark:bg-gray-600 dark:text-gray-100 text-gray-700'}
                            `}
                          >
                            Total
                          </button>
                          <button
                            onClick={() => setFoodView('daily')}
                            className={`
                              px-2 py-1 rounded 
                              transition-colors duration-300
                              ${foodView === 'daily'
                                  ? 'bg-indigo-500 text-white'
                                  : 'bg-gray-200 dark:bg-gray-600 dark:text-gray-100 text-gray-700'}
                            `}
                          >
                            Promedio Diario
                          </button>
                        </div>

                        {/* Valores Nutricionales */}
                        <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                          <div className={`${textTertiary}`}>Calorías:</div>
                          <div>
                            {foodView === 'total'
                              ? foodStats.totals.calorias
                              : foodStats.daily.calorias}{' '}
                            kcal
                          </div>

                          <div className={`${textTertiary}`}>Proteínas:</div>
                          <div>
                            {foodView === 'total'
                              ? foodStats.totals.proteinas
                              : foodStats.daily.proteinas}{' '}
                            g
                          </div>

                          <div className={`${textTertiary}`}>Carbohidratos:</div>
                          <div>
                            {foodView === 'total'
                              ? foodStats.totals.carbohidratos
                              : foodStats.daily.carbohidratos}{' '}
                            g
                          </div>

                          <div className={`${textTertiary}`}>Grasas:</div>
                          <div>
                            {foodView === 'total'
                              ? foodStats.totals.grasas
                              : foodStats.daily.grasas}{' '}
                            g
                          </div>

                          <div className={`${textTertiary}`}>Sodio:</div>
                          <div>
                            {foodView === 'total'
                              ? foodStats.totals.sodio
                              : foodStats.daily.sodio}{' '}
                            mg
                          </div>
                        </div>

                        {/* Barra de Distribución de Macronutrientes */}
                        {(foodView === 'total'
                          ? foodStats.totals.calorias
                          : foodStats.daily.calorias) > 0 && (
                          <div className="mt-4">
                            <p className={`text-sm ${textTertiary} font-semibold mb-1`}>
                              Distribución de Macronutrientes:
                            </p>
                            <div className="w-full h-4 bg-gray-200 dark:bg-gray-600 rounded-full relative overflow-hidden">
                              <div
                                className="h-full bg-red-500"
                                style={{
                                  width: `${pPercent}%`,
                                  position: 'absolute',
                                  left: 0,
                                  top: 0
                                }}
                                title={`Proteínas: ${pPercent}%`}
                              />
                              <div
                                className="h-full bg-blue-500"
                                style={{
                                  width: `${cPercent}%`,
                                  position: 'absolute',
                                  left: `${pPercent}%`,
                                  top: 0
                                }}
                                title={`Carbohidratos: ${cPercent}%`}
                              />
                              <div
                                className="h-full bg-yellow-500"
                                style={{
                                  width: `${gPercent}%`,
                                  position: 'absolute',
                                  left: `${parseFloat(pPercent) + parseFloat(cPercent)}%`,
                                  top: 0
                                }}
                                title={`Grasas: ${gPercent}%`}
                              />
                            </div>
                            <div className="flex justify-between text-xs mt-1">
                              <span>P: {pPercent}%</span>
                              <span>C: {cPercent}%</span>
                              <span>G: {gPercent}%</span>
                            </div>
                          </div>
                        )}

                        {/* Lista de Entradas de Alimentos con animación */}
                        {sortedFoodList.length > 0 && (
                          <div className={`mt-4 h-64 overflow-y-auto ${scrollbarClasses}`}>
                            <p className={`text-sm ${textTertiary} font-semibold mb-1`}>
                              Lista de Alimentos Registrados:
                            </p>
                            <ul className="text-sm ml-4 list-disc space-y-1 relative">
                              <AnimatePresence>
                                {sortedFoodList.map((item) => (
                                  <motion.li
                                    key={item.id}
                                    layout
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                    className="
                                      flex items-center justify-between cursor-pointer
                                      hover:bg-gray-100 dark:hover:bg-gray-700 rounded p-1
                                      transition-colors duration-300
                                      text-gray-700 dark:text-gray-200
                                    "
                                    onClick={() =>
                                      handleEntryClick({ type: 'food', ...item })
                                    }
                                  >
                                    <span>
                                      {item.name} -{' '}
                                      <span className="text-xs text-gray-500 dark:text-gray-400">
                                        {item.dateTime}
                                      </span>
                                    </span>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(item.id);
                                      }}
                                      className="text-red-500 hover:text-red-700 transition-colors duration-300"
                                      aria-label="Eliminar entrada de alimentación"
                                    >
                                      <MdDelete className="w-4 h-4" />
                                    </button>
                                  </motion.li>
                                ))}
                              </AnimatePresence>
                            </ul>
                          </div>
                        )}

                        <hr className={`mt-4 ${dividerColor}`} />
                        <p className={`mt-2 text-xs ${textQuaternary}`}>
                          Llevar un registro nutricional ayuda a tener control sobre tu dieta.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* EJERCICIO */}
                {workoutStats.count > 0 && (
                  <div className="transition-all duration-500 ease-in-out">
                    <div
                      className={`
                        cursor-pointer flex items-center justify-between p-2 rounded 
                        hover:bg-gray-50 dark:hover:bg-gray-700
                        text-gray-700 dark:text-gray-200
                        transition-colors duration-300
                      `}
                      onClick={() => setWorkoutDetailsOpen((prev) => !prev)}
                    >
                      <h3 className="text-md font-semibold flex items-center gap-2">
                        <RiRunFill
                          className={`
                            w-5 h-5 transition-transform duration-300
                            ${workoutDetailsOpen ? 'rotate-12 text-green-500' : 'text-green-500'}
                          `}
                        />
                        Ejercicio
                      </h3>
                      <span
                        className={`transform transition-transform duration-300 ${
                          workoutDetailsOpen ? 'rotate-180' : ''
                        }`}
                      >
                        {workoutDetailsOpen ? <MdExpandLess /> : <MdExpandMore />}
                      </span>
                    </div>

                    <div
                      className={`
                        transition-all duration-500 overflow-hidden
                        ${workoutDetailsOpen ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0'}
                      `}
                    >
                      <div className="pt-2">
                        <p className={`text-sm ${textSecondary}`}>
                          Sesiones: {workoutStats.count}
                          {workoutStats.uniqueDays > 0 && ` | Días registrados: ${workoutStats.uniqueDays}`}
                        </p>

                        {/* Botones (Total vs Promedio Diario) */}
                        <div className="mt-2 text-sm flex items-center gap-2">
                          <button
                            onClick={() => setWorkoutView('total')}
                            className={`
                              px-2 py-1 rounded 
                              transition-colors duration-300
                              ${workoutView === 'total'
                                  ? 'bg-indigo-500 text-white'
                                  : 'bg-gray-200 dark:bg-gray-600 dark:text-gray-100 text-gray-700'}
                            `}
                          >
                            Total
                          </button>
                          <button
                            onClick={() => setWorkoutView('daily')}
                            className={`
                              px-2 py-1 rounded 
                              transition-colors duration-300
                              ${workoutView === 'daily'
                                  ? 'bg-indigo-500 text-white'
                                  : 'bg-gray-200 dark:bg-gray-600 dark:text-gray-100 text-gray-700'}
                            `}
                          >
                            Promedio Diario
                          </button>
                        </div>

                        {/* Métricas de Ejercicio */}
                        <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
                          <div className={`${textTertiary}`}>Duración:</div>
                          <div>
                            {workoutView === 'total'
                              ? workoutStats.totalDuracion
                              : workoutStats.daily.duracion}{' '}
                            min
                          </div>

                          <div className={`${textTertiary}`}>Calorías quemadas:</div>
                          <div>
                            {workoutView === 'total'
                              ? workoutStats.totalCaloriasQuemadas
                              : workoutStats.daily.caloriasQuemadas}{' '}
                            kcal
                          </div>
                        </div>

                        {/* Lista de Entradas de Ejercicio con animación */}
                        {sortedWorkoutList.length > 0 && (
                          <div className={`mt-4 h-64 overflow-y-auto ${scrollbarClasses}`}>
                            <p className={`text-sm ${textTertiary} font-semibold mb-1`}>
                              Lista de Sesiones de Ejercicio:
                            </p>
                            <ul className="text-sm ml-4 list-disc space-y-1 relative">
                              <AnimatePresence>
                                {sortedWorkoutList.map((item) => (
                                  <motion.li
                                    key={item.id}
                                    layout
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                    className="
                                      flex items-center justify-between cursor-pointer
                                      hover:bg-gray-100 dark:hover:bg-gray-700 rounded p-1
                                      transition-colors duration-300
                                      text-gray-700 dark:text-gray-200
                                    "
                                    onClick={() =>
                                      handleEntryClick({ type: 'workout', ...item })
                                    }
                                  >
                                    <span>
                                      {item.workoutType} -{' '}
                                      <span className="text-xs text-gray-500 dark:text-gray-400">
                                        {item.dateTime}
                                      </span>
                                    </span>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(item.id);
                                      }}
                                      className="text-red-500 hover:text-red-700 transition-colors duration-300"
                                      aria-label="Eliminar sesión de ejercicio"
                                    >
                                      <MdDelete className="w-4 h-4" />
                                    </button>
                                  </motion.li>
                                ))}
                              </AnimatePresence>
                            </ul>
                          </div>
                        )}

                        <hr className={`mt-4 ${dividerColor}`} />
                        <p className={`mt-2 text-xs ${textQuaternary}`}>
                          Mantén una rutina constante para mejorar tu estado físico.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* ESTADO DE ÁNIMO */}
                {moodStats.count > 0 && (
                  <div className="transition-all duration-500 ease-in-out">
                    <div
                      className={`
                        cursor-pointer flex items-center justify-between p-2 rounded 
                        hover:bg-gray-50 dark:hover:bg-gray-700
                        text-gray-700 dark:text-gray-200
                        transition-colors duration-300
                      `}
                      onClick={() => setMoodDetailsOpen((prev) => !prev)}
                    >
                      <h3 className="text-md font-semibold flex items-center gap-2">
                        <RiEmotionHappyLine
                          className={`
                            w-5 h-5 transition-transform duration-300
                            ${moodDetailsOpen ? 'rotate-12 text-yellow-500' : 'text-yellow-500'}
                          `}
                        />
                        Estado de Ánimo
                      </h3>
                      <span
                        className={`transform transition-transform duration-300 ${
                          moodDetailsOpen ? 'rotate-180' : ''
                        }`}
                      >
                        {moodDetailsOpen ? <MdExpandLess /> : <MdExpandMore />}
                      </span>
                    </div>

                    <div
                      className={`
                        transition-all duration-500 overflow-hidden
                        ${moodDetailsOpen ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0'}
                      `}
                    >
                      <div className="pt-2">
                        <p className={`text-sm ${textSecondary}`}>
                          Entradas: {moodStats.count}
                          {moodStats.uniqueDays > 0 && ` | Días registrados: ${moodStats.uniqueDays}`}
                        </p>

                        {/* Lista de Entradas de Ánimo con animación */}
                        {sortedMoodList.length > 0 && (
                          <div className={`mt-4 h-64 overflow-y-auto ${scrollbarClasses}`}>
                            <div className={`${textTertiary} mb-1 text-sm font-semibold`}>
                              Lista de Estados de Ánimo:
                            </div>
                            <ul className="text-sm ml-4 list-disc space-y-1 relative">
                              <AnimatePresence>
                                {sortedMoodList.map((item) => (
                                  <motion.li
                                    key={item.id}
                                    layout
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                    className="
                                      flex items-center justify-between cursor-pointer
                                      hover:bg-gray-100 dark:hover:bg-gray-700 rounded p-1
                                      transition-colors duration-300
                                      text-gray-700 dark:text-gray-200
                                    "
                                    onClick={() =>
                                      handleEntryClick({ type: 'mood', ...item })
                                    }
                                  >
                                    <span>
                                      {item.mood} -{' '}
                                      <span className="text-xs text-gray-500 dark:text-gray-400">
                                        {item.dateTime}
                                      </span>
                                    </span>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(item.id);
                                      }}
                                      className="text-red-500 hover:text-red-700 transition-colors duration-300"
                                      aria-label="Eliminar estado de ánimo"
                                    >
                                      <MdDelete className="w-4 h-4" />
                                    </button>
                                  </motion.li>
                                ))}
                              </AnimatePresence>
                            </ul>
                          </div>
                        )}

                        <hr className={`mt-4 ${dividerColor}`} />
                        <p className={`mt-2 text-xs ${textQuaternary}`}>
                          Analizar tu estado de ánimo puede mejorar tu bienestar emocional.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* ESTADO FÍSICO */}
                {stateStats.count > 0 && (
                  <div className="transition-all duration-500 ease-in-out">
                    <div
                      className={`
                        cursor-pointer flex items-center justify-between p-2 rounded 
                        hover:bg-gray-50 dark:hover:bg-gray-700
                        text-gray-700 dark:text-gray-200
                        transition-colors duration-300
                      `}
                      onClick={() => setStateDetailsOpen((prev) => !prev)}
                    >
                      <h3 className="text-md font-semibold flex items-center gap-2">
                        <RiPulseFill
                          className={`
                            w-5 h-5 transition-transform duration-300
                            ${stateDetailsOpen ? 'rotate-12 text-pink-500' : 'text-pink-500'}
                          `}
                        />
                        Estado Físico
                      </h3>
                      <span
                        className={`transform transition-transform duration-300 ${
                          stateDetailsOpen ? 'rotate-180' : ''
                        }`}
                      >
                        {stateDetailsOpen ? <MdExpandLess /> : <MdExpandMore />}
                      </span>
                    </div>

                    <div
                      className={`
                        transition-all duration-500 overflow-hidden
                        ${stateDetailsOpen ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0'}
                      `}
                    >
                      <div className="pt-2">
                        <p className={`text-sm ${textSecondary}`}>
                          Entradas: {stateStats.count}
                          {stateStats.uniqueDays > 0 && ` | Días registrados: ${stateStats.uniqueDays}`}
                        </p>

                        {/* Lista de Entradas de Estado Físico con animación */}
                        {sortedPhysicalStateList.length > 0 && (
                          <div className={`mt-4 h-64 overflow-y-auto ${scrollbarClasses}`}>
                            <div className={`${textTertiary} mb-1 text-sm font-semibold`}>
                              Lista de Registros de Estado Físico:
                            </div>
                            <ul className="text-sm ml-4 list-disc space-y-1 relative">
                              <AnimatePresence>
                                {sortedPhysicalStateList.map((item) => (
                                  <motion.li
                                    key={item.id}
                                    layout
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 10 }}
                                    transition={{ duration: 0.2 }}
                                    className="
                                      flex items-center justify-between cursor-pointer
                                      hover:bg-gray-100 dark:hover:bg-gray-700 rounded p-1
                                      transition-colors duration-300
                                      text-gray-700 dark:text-gray-200
                                    "
                                    onClick={() =>
                                      handleEntryClick({ type: 'state', ...item })
                                    }
                                  >
                                    <span>
                                      {item.state} -{' '}
                                      <span className="text-xs text-gray-500 dark:text-gray-400">
                                        {item.dateTime}
                                      </span>
                                    </span>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(item.id);
                                      }}
                                      className="text-red-500 hover:text-red-700 transition-colors duration-300"
                                      aria-label="Eliminar registro de estado físico"
                                    >
                                      <MdDelete className="w-4 h-4" />
                                    </button>
                                  </motion.li>
                                ))}
                              </AnimatePresence>
                            </ul>
                          </div>
                        )}

                        <hr className={`mt-4 ${dividerColor}`} />
                        <p className={`mt-2 text-xs ${textQuaternary}`}>
                          Monitorear tus síntomas y mediciones contribuye a tu salud a largo plazo.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EntrySummaryView;
