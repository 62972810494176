// src/context/AuthContext.js
import React, { createContext, useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Crear el contexto de autenticación
const AuthContext = createContext();

// Hook personalizado para usar AuthContext
export const useAuth = () => useContext(AuthContext);

// URL base para los endpoints de autenticación, usando variable de entorno
const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

// Componente AuthProvider
export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();

  // Query para obtener datos del usuario
  const { data: user, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/user`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            return null; // No autenticado
          }
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.user;
      } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
      }
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // Datos frescos por 5 minutos
    cacheTime: 30 * 60 * 1000, // Mantener datos en cache por 30 minutos
  });

  // Función de login
  const login = () => {
    console.log('Initiating Google login');
    window.location.href = `${BASE_URL}/auth/google`;
  };

  // Función de logout
  const logout = async () => {
    console.log('Logging out');
    try {
      // Invalidar la query de usuario antes de redirigir
      await queryClient.invalidateQueries(['user']);
      window.location.href = `${BASE_URL}/logout`;
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Valor del contexto
  const value = {
    user,
    loading: isLoading,
    error,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
