// src/contexts/PlanContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

// (Optional) Replace with your actual AuthContext or remove if not needed
import { useAuth } from './AuthContext';

// Replace these with your real API service methods
import {
  getHealthMetrics as apiGetHealthMetrics,
  saveHealthMetrics as apiSaveHealthMetrics,
  deleteHealthMetrics as apiDeleteHealthMetrics,
  getPlans as apiGetPlans,
  savePlan as apiSavePlan,
  updatePlan as apiUpdatePlan,
  deletePlan as apiDeletePlan,
  getEntries,
  saveEntry as apiSaveEntry,
  deleteEntry as apiDeleteEntry,
} from '../Sidebar/formats/apiService';

// Utility date functions
import { startOfDay, parseISO, isValid } from 'date-fns';

// Local storage helpers
import { getSavedData, saveData, removeData } from '../Sidebar/formats/storage';

// ---------------------------------------------------------
// 1. Create context
// ---------------------------------------------------------
const PlanContext = createContext();

/**
 * Parse an ISO date string ('YYYY-MM-DD' or 'YYYY-MM-DDTHH:MM:SSZ') into a local Date object.
 */
const parseLocalDate = (dateString) => {
  if (!dateString) return null;
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : null;
};

// ---------------------------------------------------------
// 2. Context Provider
// ---------------------------------------------------------
export const PlanProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();

  // ============== Current Date ==============
  const todayDate = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  // ============== State ==============
  const [selectedDate, setSelectedDate] = useState(todayDate);

  const [caloricIntake, setCaloricIntake] = useState(
    localStorage.getItem('caloricIntake') || 'recomp'
  );
  const [useCreatine, setUseCreatine] = useState(
    localStorage.getItem('useCreatine') === 'true' || false
  );

  const [entries, setEntries] = useState([]);
  const [planData, setPlanData] = useState(() => getSavedData('planData', null));
  const [planCreated, setPlanCreated] = useState(!!planData);
  const [plans, setPlans] = useState([]);

  const [healthMetrics, setHealthMetrics] = useState(() =>
    getSavedData('healthMetrics', null)
  );
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Derive planId from planData
  const planId = useMemo(() => (planData ? planData._id : null), [planData]);

  // Save local preferences
  useEffect(() => {
    localStorage.setItem('caloricIntake', caloricIntake);
  }, [caloricIntake]);

  useEffect(() => {
    localStorage.setItem('useCreatine', useCreatine);
  }, [useCreatine]);

  // ---------------------------------------------------------
  // Fetch Health Metrics
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchHealthMetrics = async () => {
      if (user) {
        try {
          const metrics = await apiGetHealthMetrics();
          setHealthMetrics(metrics);
          saveData('healthMetrics', metrics);
        } catch (err) {
          console.error('Error fetching health metrics:', err);
          setError(err.message);
        }
      } else {
        // Load from local if no user
        const storedMetrics = getSavedData('healthMetrics', null);
        setHealthMetrics(storedMetrics);
      }
    };
    fetchHealthMetrics();
  }, [user]);

  // ---------------------------------------------------------
  // Fetch Plans
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchPlans = async () => {
      if (user) {
        try {
          const plansData = await apiGetPlans();
          setPlans(plansData);

          if (plansData && plansData.length > 0) {
            const rawPlan = plansData[0];
            const parsedStart = parseLocalDate(rawPlan.start || rawPlan.startDate);
            const parsedEnd = parseLocalDate(rawPlan.end || rawPlan.endDate);

            if (parsedStart && parsedEnd) {
              const plan = {
                ...rawPlan,
                start: startOfDay(parsedStart),
                end: startOfDay(parsedEnd),
              };
              saveData('planData', plan);
              setPlanData(plan);
              setPlanCreated(true);

              // Fetch entries for that plan
              try {
                const fetchedEntries = await getEntries(plan._id, plan.start, plan.end);
                setEntries(fetchedEntries);
              } catch (entryErr) {
                console.error('Error fetching entries:', entryErr);
                setError(entryErr.message);
              }
            } else {
              setError('Plan dates are invalid.');
            }
          } else {
            // No plans from server, check local plan
            const localPlanData = getSavedData('planData', null);
            if (localPlanData) {
              if (!localPlanData.start || !localPlanData.end) {
                setError('Local plan has invalid dates.');
                return;
              }
              const parsedStart = parseLocalDate(localPlanData.start);
              const parsedEnd = parseLocalDate(localPlanData.end);

              if (parsedStart && parsedEnd) {
                const plan = {
                  ...localPlanData,
                  start: startOfDay(parsedStart),
                  end: startOfDay(parsedEnd),
                };
                setPlanData(plan);
                setPlanCreated(true);

                try {
                  const fetchedEntries = await getEntries(
                    plan._id,
                    plan.start,
                    plan.end
                  );
                  setEntries(fetchedEntries);
                } catch (entryErr) {
                  console.error('Error fetching local entries:', entryErr);
                  setError(entryErr.message);
                }
              } else {
                setError('Local plan dates invalid.');
              }
            } else {
              setPlanData(null);
              setPlanCreated(false);
              setEntries([]);
            }
          }
        } catch (err) {
          console.error('Error fetching plans:', err);
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        // No user, check local
        const localPlanData = getSavedData('planData', null);
        if (localPlanData) {
          if (!localPlanData.start || !localPlanData.end) {
            setError('Local plan has invalid dates.');
            return;
          }
          const parsedStart = parseLocalDate(localPlanData.start);
          const parsedEnd = parseLocalDate(localPlanData.end);

          if (parsedStart && parsedEnd) {
            const plan = {
              ...localPlanData,
              start: startOfDay(parsedStart),
              end: startOfDay(parsedEnd),
            };
            setPlanData(plan);
            setPlanCreated(true);

            try {
              const fetchedEntries = await getEntries(plan._id, plan.start, plan.end);
              setEntries(fetchedEntries);
            } catch (entryErr) {
              console.error('Error fetching local entries:', entryErr);
              setError(entryErr.message);
            }
          } else {
            setError('Local plan dates invalid.');
          }
        }
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, [user]);

  // ---------------------------------------------------------
  // Create / Delete Plan
  // ---------------------------------------------------------
  const handleCreatePlan = useCallback(
    async (metricsParam) => {
      // Use the provided metrics or fall back to current healthMetrics
      let metrics = metricsParam || healthMetrics;
      if (
        !metrics ||
        !metrics.weight ||
        !metrics.height ||
        !metrics.age ||
        !metrics.sex ||
        !metrics.activityFactor
      ) {
        console.warn(
          'Incomplete metrics; default values will be used for plan creation.'
        );
        metrics = {
          weight: 70,
          height: 170,
          age: 30,
          sex: 'male',
          activityFactor: 1.2,
        };
      }

      if (!planData) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const endDate = new Date(today);
        endDate.setMonth(endDate.getMonth() + 1);

        const newPlan = {
          caloricIntake,
          useCreatine,
          start: today.toISOString(),
          end: endDate.toISOString(),
          healthMetrics: metrics,
        };

        try {
          let savedPlan;
          if (user) {
            savedPlan = await apiSavePlan(newPlan);
            if (!savedPlan) {
              throw new Error('Could not save plan to server.');
            }
            removeData('planData');
            removeData('entries');
            saveData('planData', savedPlan);
          } else {
            // If no user, just keep it locally
            savedPlan = newPlan;
            saveData('planData', savedPlan);
          }
          setPlanData(savedPlan);
          setPlanCreated(true);
          setEntries([]);
        } catch (err) {
          console.error('Error creating plan:', err);
          setError('Could not save plan. Please try again.');
        }
      }
    },
    [planData, caloricIntake, useCreatine, user, healthMetrics]
  );

  const handleDeletePlan = useCallback(async () => {
    if (planData && planData._id) {
      try {
        if (user) {
          await apiDeletePlan(planData._id);
        }
        setPlanData(null);
        setPlanCreated(false);
        setEntries([]);
        removeData('planData');
        removeData('entries');
        setError(null);
      } catch (err) {
        console.error('Error deleting plan:', err);
        setError('Error deleting plan. Please try again.');
      }
    }
  }, [planData, user]);

  // ---------------------------------------------------------
  // Entries Management
  // ---------------------------------------------------------
  const addEntry = useCallback(
    async (entryData) => {
      if (!planData?._id) {
        setError('No plan is selected. Cannot add entry.');
        return { success: false, message: 'No plan selected.' };
      }
      try {
        const savedEntry = await apiSaveEntry({
          ...entryData,
          planId: planData._id,
        });
        setEntries((prevEntries) => [...prevEntries, savedEntry]);
        setError(null);
        return { success: true };
      } catch (err) {
        console.error('Error adding entry:', err);
        setError('Error adding entry. Please try again.');
        return {
          success: false,
          message: 'Error adding entry. Please try again.',
        };
      }
    },
    [planData]
  );

  const deleteEntryFn = useCallback(async (entryId) => {
    try {
      const result = await apiDeleteEntry(entryId);
      if (result.success) {
        setEntries((prevEntries) =>
          prevEntries.filter((entry) => entry._id !== entryId)
        );
        setError(null);
        return { success: true };
      } else {
        setError(result.message || 'Error deleting entry.');
        return { success: false, message: result.message };
      }
    } catch (err) {
      console.error('Error deleting entry:', err);
      setError('Error deleting entry. Please try again.');
      return {
        success: false,
        message: 'Error deleting entry. Please try again.',
      };
    }
  }, []);

  // ---------------------------------------------------------
  // HealthMetrics (API) Access
  // ---------------------------------------------------------
  const getHealthMetricsFn = useCallback(async () => {
    try {
      const metrics = await apiGetHealthMetrics();
      setHealthMetrics(metrics);
      saveData('healthMetrics', metrics);
      return metrics;
    } catch (err) {
      console.error('Error getHealthMetrics:', err);
      throw err;
    }
  }, []);

  const saveHealthMetricsData = useCallback(async (data) => {
    try {
      const savedMetrics = await apiSaveHealthMetrics(data);
      setHealthMetrics(savedMetrics);
      saveData('healthMetrics', savedMetrics);
      return savedMetrics;
    } catch (err) {
      console.error('Error saveHealthMetrics:', err);
      throw err;
    }
  }, []);

  const deleteHealthMetricsData = useCallback(async () => {
    try {
      await apiDeleteHealthMetrics();
      setHealthMetrics(null);
      removeData('healthMetrics');
    } catch (err) {
      console.error('Error deleteHealthMetrics:', err);
      throw err;
    }
  }, []);

  // ---------------------------------------------------------
  // Plans (API) Access
  // ---------------------------------------------------------
  const getPlansData = useCallback(async () => {
    try {
      const plansData = await apiGetPlans();
      setPlans(plansData);
      return plansData;
    } catch (err) {
      console.error('Error getPlans:', err);
      throw err;
    }
  }, []);

  const savePlanDataFn = useCallback(async (newPlanData) => {
    try {
      const savedPlan = await apiSavePlan(newPlanData);
      setPlans((prevPlans) => [...prevPlans, savedPlan]);
      saveData('planData', savedPlan);
      setPlanData(savedPlan);
      setPlanCreated(true);
      return savedPlan;
    } catch (err) {
      console.error('Error savePlan:', err);
      throw err;
    }
  }, []);

  const updatePlanData = useCallback(async (planId, updatedData) => {
    try {
      const updatedPlan = await apiUpdatePlan(planId, updatedData);
      setPlans((prevPlans) =>
        prevPlans.map((plan) => (plan._id === planId ? updatedPlan : plan))
      );
      setPlanData(updatedPlan);
      saveData('planData', updatedPlan);
      return updatedPlan;
    } catch (err) {
      console.error('Error updatePlan:', err);
      throw err;
    }
  }, []);

  const deletePlanData = useCallback(async (planId) => {
    try {
      await apiDeletePlan(planId);
      setPlans((prevPlans) => prevPlans.filter((plan) => plan._id !== planId));
      removeData('planData');
    } catch (err) {
      console.error('Error deletePlan:', err);
      throw err;
    }
  }, []);

  // ---------------------------------------------------------
  // Calendar Helpers (optional)
  // ---------------------------------------------------------
  const getEntriesForDate = useCallback(
    (date) => {
      const dateStr = date.toISOString().split('T')[0];
      return entries.filter(
        (entry) => entry.date && entry.date.startsWith(dateStr)
      );
    },
    [entries]
  );

  const tileContent = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const entriesForDate = getEntriesForDate(date);
        return (
          <>
            {entriesForDate.map((entry) => (
              <div key={entry._id} className="entry-marker">
                {entry.summary}
              </div>
            ))}
          </>
        );
      }
      return null;
    },
    [getEntriesForDate]
  );

  const tileClassName = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const dateStr = date.toISOString().split('T')[0];
        const todayStr = todayDate.toISOString().split('T')[0];
        const selectedDateStr = selectedDate.toISOString().split('T')[0];

        let classes = '';
        if (dateStr === todayStr) classes += 'react-calendar__tile--now ';
        if (dateStr === selectedDateStr) classes += 'react-calendar__tile--active ';
        if (date < todayDate) classes += 'opacity-50 ';

        return classes.trim();
      }
      return null;
    },
    [todayDate, selectedDate]
  );

  const tileDisabled = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const isPast = date < todayDate;
        const entriesForDate = getEntriesForDate(date);
        const isPartOfPlan = entriesForDate.length > 0;
        return isPast && !isPartOfPlan;
      }
      return false;
    },
    [getEntriesForDate, todayDate]
  );

  const isPlanActive = entries.length > 0;

  // ---------------------------------------------------------
  //  Update selectedDate on new plan creation
  // ---------------------------------------------------------
  useEffect(() => {
    if (planCreated && planData) {
      let start, end;
      if (planData.start && planData.end) {
        start =
          typeof planData.start === 'string'
            ? parseLocalDate(planData.start)
            : planData.start;
        end =
          typeof planData.end === 'string'
            ? parseLocalDate(planData.end)
            : planData.end;
      } else if (planData.startDate && planData.endDate) {
        start = parseLocalDate(planData.startDate);
        end = parseLocalDate(planData.endDate);
      } else {
        console.warn('planData missing start/end:', planData);
        return;
      }
      if (start && end) {
        setSelectedDate(startOfDay(start));
      } else {
        console.warn('Invalid dates in planData:', planData);
      }
    }
  }, [planCreated, planData]);

  // Debug: watch entries
  useEffect(() => {
    console.log('Current entries:', entries);
  }, [entries]);

  // ---------------------------------------------------------
  // Return Provider
  // ---------------------------------------------------------
  return (
    <PlanContext.Provider
      value={{
        caloricIntake,
        setCaloricIntake,
        useCreatine,
        setUseCreatine,
        planData,
        setPlanData,
        planCreated,
        setPlanCreated,
        entries,
        setEntries,
        error,
        setError,
        plans,
        isLoading: isLoading || authLoading,
        healthMetrics,
        setHealthMetrics,
        handleDeletePlan,
        handleCreatePlan,
        addEntry,
        deleteEntry: deleteEntryFn,
        getHealthMetrics: getHealthMetricsFn,
        saveHealthMetrics: saveHealthMetricsData,
        deleteHealthMetrics: deleteHealthMetricsData,
        getPlans: getPlansData,
        savePlan: savePlanDataFn,
        updatePlan: updatePlanData,
        deletePlan: deletePlanData,
        selectedDate,
        setSelectedDate,
        getEntriesForDate,
        tileContent,
        tileClassName,
        tileDisabled,
        isPlanActive,
        planId,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

PlanProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};
