// index.js

// Polyfills básicos para fetch y Promises
import 'whatwg-fetch'; // Polyfill para la API fetch
import 'promise-polyfill/src/polyfill'; // Polyfill para Promises

// Polyfills adicionales para características modernas de ECMAScript
import 'regenerator-runtime/runtime'; // Polyfill para soportar async/await

// Resto de tus importaciones y código de la aplicación
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
